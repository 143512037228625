import * as Yup from "yup";
import { useFormik } from "formik";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Col, Row, Form, Button, Input, FormFeedback } from "reactstrap";

import "./ContactUsPage.css";
import { useContactUsMutation } from "../../../services";
import { ContactUsRequest } from "../../../interfaces";
import logoLight from "../../../assets/images/logo-light.png";
import LanguageDropdown from "../../../components/Common/LanguageDropdown";

export const ContactUsPage = () => {
  //#region hooks usage
  const { t, i18n } = useTranslation();
  //#endregion

  //#region internal state
  const [contact] = useState<ContactUsRequest>({
    email: "",
    message: "",
    phoneNumber: "",
    name: "",
    subject: "",
  });
  //#endregion

  //#region others
  document.title = `${t("documentTitle", {
    pageName: "contactUs",
  })}`;
  //#endregion

  //#region form
  /**
   * Formik handling
   */
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: contact.name || "",
      email: contact.email || "",
      phoneNumber: contact.phoneNumber || "",
      subject: contact.subject || "",
      message: contact.message || "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required(
        t("isNotEmpty", {
          displayName: "name",
        })
      ),
      email: Yup.string()
        .email(
          t("invalidEmail", {
            displayName: "email",
          })
        )
        .nullable(),
      phoneNumber: Yup.string()
        .required(
          t("isNotEmpty", {
            displayName: "phoneNumber",
          })
        )
        .min(8, t("minLength", { displayName: "phoneNumber", minLength: 8 }))
        .max(12, t("maxLength", { displayName: "phoneNumber", maxLength: 12 })),
      subject: Yup.string().required(
        t("isNotEmpty", {
          displayName: "subject",
        })
      ),
      message: Yup.string()
        .required(
          t("isNotEmpty", {
            displayName: "message",
          })
        )
        .max(1000, t("maxLength", { displayName: "message", maxLength: 1000 })),
    }),

    onSubmit: async (values) => {
      await contactUs(values);
      setTimeout(() => {
        alert(t("submittedSuccessfully"));
      }, 1000);
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    },
  });
  //#endregion

  //#region api
  const [contactUs] = useContactUsMutation();

  //#endregion

  return (
    <React.Fragment>
      <div className="container-fluid mt-3">
        <Row>
          <Col lg={12}>
            <div className="text-center mt-sm-5 text-white-50">
              <div>
                <Link to="/" className="d-inline-block auth-logo">
                  <img src={logoLight} alt="" height="125" />
                </Link>
              </div>
            </div>
          </Col>
        </Row>
        <section className="ftco-section">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-12">
                <div className="wrapper">
                  <div className="row no-gutters">
                    <div className="col-lg-8 col-md-7 order-md-last d-flex align-items-stretch">
                      {i18n.language === "ar" && (
                        <div style={{ position: "absolute", left: 50 }}>
                          <LanguageDropdown />
                        </div>
                      )}
                      {i18n.language === "en" && (
                        <div style={{ position: "absolute", right: 50 }}>
                          <LanguageDropdown />
                        </div>
                      )}
                      <div className="contact-wrap w-100 p-md-5 p-4">
                        <h3 className="mb-4">{t("getInTouch")}</h3>
                        <Form
                          onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                          }}
                          className="contactForm"
                        >
                          <div className="row">
                            <div className="col-md-6 mb-3">
                              <div className="form-group">
                                <label className="label" htmlFor="name">
                                  {t("fullName")}
                                  <span className="text-danger">*</span>
                                </label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  name="name"
                                  placeholder={t("name")}
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.name || ""}
                                  invalid={
                                    validation.touched.name &&
                                    validation.errors.name
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.name &&
                                validation.errors.name ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.name}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="label" htmlFor="mobile">
                                  {t("phoneNumber")}
                                  <span className="text-danger">*</span>
                                </label>
                                <Input
                                  type="tel"
                                  className="form-control"
                                  id="mobile"
                                  placeholder={t("phoneNumber")}
                                  value={validation.values.phoneNumber || ""}
                                  onChange={(e) => {
                                    e.preventDefault();
                                    const { value } = e.target;
                                    const regex =
                                      /^(0*[0-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/;
                                    if (regex.test(value.toString())) {
                                      validation.setValues({
                                        ...validation.values,
                                        phoneNumber: value,
                                      });
                                    } else {
                                      validation.setValues({
                                        ...validation.values,
                                        phoneNumber: "",
                                      });
                                    }
                                  }}
                                  onBlur={validation.handleBlur}
                                  invalid={
                                    validation.touched.phoneNumber &&
                                    validation.errors.phoneNumber
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.phoneNumber &&
                                validation.errors.phoneNumber ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.phoneNumber}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </div>
                            <div className="mb-3">
                              <label htmlFor="email" className="form-label">
                                {t("email")}
                                {" ("}
                                {t("optional")}
                                {")"}
                              </label>
                              <Input
                                name="email"
                                className="form-control"
                                placeholder={t("enterEmail")}
                                type="email"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.email || ""}
                                invalid={
                                  validation.touched.email &&
                                  validation.errors.email
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.email &&
                              validation.errors.email ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.email}
                                </FormFeedback>
                              ) : null}
                            </div>
                            <div className="col-md-12 mb-3">
                              <div className="form-group">
                                <label className="label" htmlFor="subject">
                                  {t("subject")}
                                </label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  name="subject"
                                  placeholder={t("subject")}
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.subject || ""}
                                  invalid={
                                    validation.touched.subject &&
                                    validation.errors.subject
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.subject &&
                                validation.errors.subject ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.subject}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </div>
                            <div className="col-md-12 mb-3">
                              <div className="form-group">
                                <label className="label" htmlFor="message">
                                  {t("message")}
                                </label>
                                <Input
                                  type="textarea"
                                  name="message"
                                  className="form-control"
                                  cols="30"
                                  rows="4"
                                  placeholder={t("message")}
                                  minLength={50}
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.message || ""}
                                  invalid={
                                    validation.touched.message &&
                                    validation.errors.message
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.message &&
                                validation.errors.message ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.message}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </div>
                            <div className="col-md-12">
                              <Button type="submit" className="btn btn-success">
                                {t("sendMessage")}
                              </Button>
                            </div>
                          </div>
                        </Form>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-5 d-flex align-items-stretch">
                      <div className="info-wrap bg-primary w-100 p-md-5 p-4">
                        <h3>{t(`getInTouch`)}</h3>
                        <p className="mb-4">{t("contactUsMessage1")}</p>
                        <div className="dbox w-100 d-flex align-items-center">
                          <div className="icon d-flex align-items-center justify-content-center">
                            <span className="ri-phone-line"></span>
                          </div>
                          <div className="text" style={{ paddingLeft: "10px" }}>
                            <p>
                              <span>{t("phoneNumber")}:</span>
                              <a href="tel://+966539691676">+965 97 29 4491</a>
                            </p>
                          </div>
                        </div>
                        <div className="dbox w-100 d-flex align-items-center">
                          <div className="icon d-flex align-items-center justify-content-center">
                            <span className="ri-mail-line"></span>
                          </div>
                          <div className="text" style={{ paddingLeft: "10px" }}>
                            <p>
                              <span>{t("email")}:</span>{" "}
                              <a href="support@islamgpt.com">
                                support@islamgpt.com
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </React.Fragment>
  );
};
