import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Switch, Route } from "react-router-dom";

import Layout from "../layouts/Layout";
import { NonAuthLayout } from "../layouts";
import { setUser } from "../store/account";
import { useAuth, useUser } from "../components/hooks";
import { useLazyGetUserProfileQuery } from "../services/account.api.service";
import { AccessRoute, AuthProtected, PublicRoutes, SuperAdminRoutes } from ".";

export const AppRoutes = () => {
  //#region hooks usage
  const dispatch = useDispatch();
  //#endregion

  //#region redux state
  const { auth } = useAuth();
  const { user } = useUser();

  //#endregion

  //#region routes
  const publicPaths = PublicRoutes.map((r: any) => r.path);
  const superAdminPaths = SuperAdminRoutes.map((r: any) => r.path);
  //#endregion

  //#region api
  const [
    getUserProfile,
    {
      isFetching: isFetchingGetUserProfile,
      isSuccess: isSuccessGetUserProfile,
      data: dataGetUserProfile,
    },
  ] = useLazyGetUserProfileQuery();
  //#endregion

  //#region effects
  useEffect(() => {
    const fetchData = async () => {
      await getUserProfile(null);
    };
    if (auth && auth.token) {
      fetchData();
    }
  }, [auth]);

  useEffect(() => {
    if (!isFetchingGetUserProfile && isSuccessGetUserProfile) {
      if (dataGetUserProfile) dispatch(setUser(dataGetUserProfile));
    }
  }, [isFetchingGetUserProfile, isSuccessGetUserProfile]);

  //#endregion

  return (
    <React.Fragment>
      <Switch>
        <Route path={publicPaths}>
          <NonAuthLayout>
            <Switch>
              {PublicRoutes.map((route: any, idx: number) => (
                <Route
                  path={route.path}
                  component={route.component}
                  key={idx}
                  exact={true}
                />
              ))}
            </Switch>
          </NonAuthLayout>
        </Route>

        <Route path={superAdminPaths}>
          <AuthProtected>
            <Layout>
              <Switch>
                {SuperAdminRoutes.map((route, idx) => (
                  <AccessRoute
                    path={route.path}
                    component={route.component}
                    key={idx}
                    exact={true}
                  />
                ))}
              </Switch>
            </Layout>
          </AuthProtected>
        </Route>
      </Switch>
    </React.Fragment>
  );
};
