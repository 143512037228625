import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";

import { ErrorHandlingTypeEnum, GenderEnum } from "../../../enums";
import { ActivityButton } from "../../../components/Common";
import { CreateAdminRequest, UpdateAdminRequest } from "../../../interfaces";
import {
  useAddAdminUserMutation,
  useLazyGetUserByIdQuery,
  useUpdateAdminUserMutation,
} from "../../../services/user.api.service";
import { InputType } from "reactstrap/types/lib/Input";
import { getServerError } from "../../../services";

type TProps = {
  id?: string | null;
  show: boolean;
  onCloseClick: (event: any) => void;
};
export const AddEditAdminUserModal: React.FC<TProps> = ({
  id,
  show,
  onCloseClick,
}) => {
  //#region hooks
  const { t } = useTranslation();
  //#endregion

  //#region internal states
  const [request] = useState<CreateAdminRequest | UpdateAdminRequest>();
  const [passwordType, setPasswordType] = useState<InputType>("password");
  //#endregion

  //#region api
  const [
    addAdminUser,
    {
      isLoading: isLoadingAddAdminUser,
      isError: isErrorAddAdminUser,
      isSuccess: isSuccessAddAdminUser,
      error: errorAddAdminUser,
      data: dataAddAdminUser,
    },
  ] = useAddAdminUserMutation();

  const [
    updateAdminUser,
    {
      isLoading: isLoadingUpdateAdminUser,
      isError: isErrorUpdateAdminUser,
      isSuccess: isSuccessUpdateAdminUser,
      error: errorUpdateAdminUser,
      data: dataUpdateAdminUser,
    },
  ] = useUpdateAdminUserMutation();

  const [
    getUserById,
    {
      isLoading: isLoadingAdminUserById,
      isError: isErrorAdminUserById,
      isSuccess: isSuccessAdminUserById,
      error: errorAdminUserById,
      isFetching: isFetchingAdminUserById,
      data: dataAdminUserById,
    },
  ] = useLazyGetUserByIdQuery();
  //#endregion

  //#region form
  const form = useFormik<CreateAdminRequest | UpdateAdminRequest>({
    enableReinitialize: true,
    initialValues: {
      id: null,
      name: {
        en: request?.name.en || "",
        ar: request?.name.ar || "",
      },
      email: request?.email || "",
      gender: request?.gender || GenderEnum.MALE,
      password: null,
    },
    validationSchema: Yup.object({
      id: Yup.string().nullable(),
      name: Yup.object({
        en: Yup.string()
          .max(100, t("maxLength", { maxLength: 100, displayName: "name" }))
          .required(
            t("isNotEmpty", {
              displayName: "name",
            })
          ),
      }),
      email: Yup.string()
        .email(
          t("invalidEmail", {
            displayName: "email",
          })
        )
        .required(
          t("isNotEmpty", {
            displayName: "email",
          })
        ),
      password: Yup.string()
        .min(8, t("minLength", { displayName: "password", minLength: 8 }))
        .max(50, t("maxLength", { displayName: "password", maxLength: 50 }))
        .nullable()
        .when("id", (id: any): any => {
          if (id == "" || id == null || id == undefined)
            return Yup.string()
              .min(
                8,
                t("minLength", {
                  displayName: "password",
                  minLength: 8,
                })
              )
              .max(
                50,
                t("maxLength", {
                  displayName: "password",
                  maxLength: 50,
                })
              )
              .required(
                t("isNotEmpty", {
                  displayName: "password",
                })
              );
        }),
    }),
    onSubmit: async (values) => {
      if (id) {
        await updateAdminUser({ ...values, id: id });
      } else {
        await addAdminUser(values);
      }
    },
  });
  //#endregion

  //#region effects
  useEffect(() => {
    if (
      (!isLoadingAddAdminUser && isSuccessAddAdminUser) ||
      (!isLoadingUpdateAdminUser && isSuccessUpdateAdminUser)
    ) {
      onClose();
    }
  }, [
    isLoadingAddAdminUser,
    isLoadingUpdateAdminUser,
    isSuccessAddAdminUser,
    isSuccessUpdateAdminUser,
  ]);

  useEffect(() => {
    if (
      id &&
      !isFetchingAdminUserById &&
      isSuccessAdminUserById &&
      dataAdminUserById
    ) {
      form.setValues({
        id: dataAdminUserById.id,
        name: dataAdminUserById.name,
        email: dataAdminUserById?.email?.email || "",
        gender: dataAdminUserById.gender,
        password: null,
      });
    }
  }, [isFetchingAdminUserById, isSuccessAddAdminUser]);

  useEffect(() => {
    if (!isLoadingAddAdminUser && isErrorAddAdminUser) {
      const _parsed = getServerError(errorAddAdminUser);
      if (_parsed.type === ErrorHandlingTypeEnum.FORM) {
        form.setErrors(_parsed.error);
      }
      if (_parsed.type === ErrorHandlingTypeEnum.ALERT) {
        toast.error(_parsed.error, {
          autoClose: 3000,
        });
      }
    }
  }, [isLoadingAddAdminUser]);

  useEffect(() => {
    if (!isLoadingUpdateAdminUser && isErrorUpdateAdminUser) {
      const _parsed = getServerError(errorUpdateAdminUser);
      if (_parsed.type === ErrorHandlingTypeEnum.FORM) {
        form.setErrors(_parsed.error);
      }
      if (_parsed.type === ErrorHandlingTypeEnum.ALERT) {
        toast.error(_parsed.error, {
          autoClose: 3000,
        });
      }
    }
  }, [isLoadingUpdateAdminUser]);

  useEffect(() => {
    async function initialize() {
      await getUserById(id as string);
    }

    if (id) {
      initialize();
    }
  }, [id]);

  //#endregion

  //#region handlers
  const onClose = () => {
    form.resetForm();
    onCloseClick(!show);
  };

  /**
   * Password show hide
   */
  const handleClickShowPassword = () => {
    if (passwordType === "text") setPasswordType("password");
    else setPasswordType("text");
  };
  //#endregion

  return (
    <React.Fragment>
      <ToastContainer />
      <Modal
        id="addEdit"
        isOpen={show}
        toggle={() => {
          onClose();
        }}
        centered
      >
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            form.handleSubmit();
            return false;
          }}
          action="#"
        >
          <ModalHeader
            className="p-3"
            toggle={() => {
              onClose();
            }}
          >
            {id
              ? t("editItem", { item: "adminUser" })
              : t("addItem", { item: "adminUser" })}
          </ModalHeader>
          <ModalBody>
            <div className="mb-3">
              <Label htmlFor="name.en" className="form-label">
                {t("name")}
                <span className="text-danger">*</span>
              </Label>
              <Input
                disabled={isErrorAdminUserById}
                autoComplete="off"
                id="name.en"
                name="name.en"
                value={form.values?.name.en}
                type="text"
                className="form-control"
                placeholder={t("enterName")}
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                invalid={
                  form.touched.name?.en && form.errors.name?.en ? true : false
                }
              />
              {form.touched.name?.en && form.errors.name?.en ? (
                <FormFeedback type="invalid">
                  {form.errors.name?.en as string}
                </FormFeedback>
              ) : null}
            </div>
            <div className="mb-3">
              <Label htmlFor="email" className="form-label">
                {t("email")}
                <span className="text-danger">*</span>
              </Label>
              <Input
                disabled={
                  isErrorAdminUserById || (id !== null && id !== undefined)
                }
                readOnly={id !== null && id !== undefined}
                autoComplete="off"
                id="email"
                name="email"
                value={form.values?.email}
                type="text"
                className="form-control"
                placeholder={t("enterEmail")}
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                invalid={form.touched.email && form.errors.email ? true : false}
              />
              {form.touched.email && form.errors.email ? (
                <FormFeedback type="invalid">
                  {form.errors.email as string}
                </FormFeedback>
              ) : null}
            </div>
            <div className="mb-3">
              <Label className="form-label" htmlFor="password-input">
                {t("password")}
              </Label>
              {!id && <span className="text-danger">*</span>}
              <div className="position-relative auth-pass-inputgroup mb-3">
                <Input
                  id="password-input"
                  name="password"
                  value={form.values.password || ""}
                  type={passwordType}
                  className="form-control"
                  placeholder={t("enterPassword")}
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                  invalid={
                    form.touched.password && form.errors.password ? true : false
                  }
                  maxLength={25}
                />
                {form.touched.password && form.errors.password ? (
                  <FormFeedback type="invalid">
                    {form.errors.password as string}
                  </FormFeedback>
                ) : null}
                <button
                  className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                  type="button"
                  id="password-addon"
                >
                  <i
                    className="ri-eye-fill align-middle"
                    onClick={handleClickShowPassword}
                  ></i>
                </button>
              </div>
            </div>

            <div className="mb-3">
              <label className="form-label">{t("gender")}</label>
              <div>
                <div className="form-check form-check-inline">
                  <Input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio1"
                    value={GenderEnum.MALE}
                    checked={form.values.gender === GenderEnum.MALE}
                    onChange={() => {
                      form.setFieldValue("gender", GenderEnum.MALE);
                    }}
                  />
                  <label className="form-check-label" htmlFor="inlineRadio1">
                    {t("male")}
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <Input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio2"
                    value={GenderEnum.FEMALE}
                    checked={form.values.gender === GenderEnum.FEMALE}
                    onChange={() => {
                      form.setFieldValue("gender", GenderEnum.FEMALE);
                    }}
                  />
                  <label className="form-check-label" htmlFor="inlineRadio2">
                    {t("female")}
                  </label>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Link
              to={"#"}
              onClick={onClose}
              className="btn btn-link link-success fw-medium"
            >
              <i className="ri-close-line me-1 align-middle"></i> {t("close")}
            </Link>
            <ActivityButton
              title="save"
              inProgressTitle="saving"
              color="success"
              className="btn btn-success"
              type="submit"
              inProgress={isLoadingAddAdminUser || isLoadingUpdateAdminUser}
              disabled={isLoadingAddAdminUser || isLoadingUpdateAdminUser}
            ></ActivityButton>
          </ModalFooter>
        </Form>
      </Modal>
    </React.Fragment>
  );
};
