import React from "react";
import { Content } from "../content";
import { ContentTypeEnum } from "../../../enums";

type TProps = {};
export const MuslimLifeContentPage: React.FC<TProps> = () => (
  <Content
    title={"muslimLifeContent"}
    type={ContentTypeEnum.Muslim_Life_Content}
  />
);
