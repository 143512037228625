import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Card, CardHeader, Col, Row } from "reactstrap";

type TProps = {
  title: string;
  pageTitle: string;
};

export const BreadCrumb: React.FC<TProps> = ({ title, pageTitle }) => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Row>
        <Col xs={12}>
          <div className="page-title-box d-sm-flex align-items-center justify-content-between">
            <h4 className="mb-sm-0">{title}</h4>
            <div className="page-title-right">
              <ol className="breadcrumb m-0">
                {pageTitle && (
                  <li className="breadcrumb-item">
                    <Link to="#">{t(pageTitle)}</Link>
                  </li>
                )}
                <li className="breadcrumb-item active">{t(title)}</li>
              </ol>
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export interface BreadCrumbItem {
  title: string;
  link?: string;
}

type TPropsV2 = {
  previousPages: BreadCrumbItem[];
};

export const BreadCrumbV2: React.FC<TPropsV2> = ({ previousPages }) => {
  const { t } = useTranslation();
  return (
    <Row>
      <Col className="mt-4 d-flex">
        <ol className="breadcrumb">
          {previousPages &&
            previousPages.length > 0 &&
            previousPages.map(
              ({ title, link }: BreadCrumbItem, key: number) => {
                if (key == previousPages.length - 1) {
                  return (
                    <li className="breadcrumb-item active fw-medium fs-16">
                      {title}
                    </li>
                  );
                } else {
                  return (
                    <Link
                      className="breadcrumb-item cursor-pointer fw-medium fs-16"
                      key={key}
                      to={`${link}`}
                    >
                      {t(title)}
                    </Link>
                  );
                }
              }
            )}
        </ol>
      </Col>
    </Row>
  );
};
