import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";

import { ActivityButton } from "../../../components/Common";
import { ErrorHandlingTypeEnum, ContentTypeEnum } from "../../../enums";

import {
  getServerError,
  useAddContentMutation,
  useLazyGetContentByIdQuery,
  useUpdateContentMutation,
  useUploadContentImageMutation,
} from "../../../services";
import {
  ImageUpload,
  InputLocalized,
  RichTextEditorLocalized,
} from "../../../components";
import {
  LocalizedPropertyInitialErrors,
  LocalizedPropertyInitialTouched,
  LocalizedPropertyInitialValues,
  LocalizedPropertyYupSchema,
} from "../../../constants/language.constant";
import {
  CreateContentRequest,
  LocalizedProperty,
  UpdateContentRequest,
} from "../../../interfaces";

type TProps = {
  id?: string | null;
  show: boolean;
  onCloseClick: (event: any) => void;
  type: ContentTypeEnum;
  parentId?: string | null | undefined;
  prophetId?: string | null | undefined;
};
export const AddEditContentModal: React.FC<TProps> = ({
  id,
  show,
  onCloseClick,
  type,
  parentId,
  prophetId,
}) => {
  //#region hooks
  const { t } = useTranslation();
  //#endregion

  //#region internal state
  const [descriptionInitialValue, setDescriptionInitialValue] =
    useState<LocalizedProperty>(LocalizedPropertyInitialValues);

  const [imagePath, setImagePath] = useState<string | null | undefined>();
  //#endregion

  //#region api
  const [
    addContent,
    {
      isLoading: isLoadingAddContent,
      isError: isErrorAddContent,
      isSuccess: isSuccessAddContent,
      error: errorAddContent,
    },
  ] = useAddContentMutation();

  const [
    updateContent,
    {
      isLoading: isLoadingUpdateContent,
      isError: isErrorUpdateContent,
      isSuccess: isSuccessUpdateContent,
      error: errorUpdateContent,
    },
  ] = useUpdateContentMutation();

  const [
    getContentById,
    {
      isSuccess: isSuccessContentById,
      isFetching: isFetchingContentById,
      isError: isErrorContentById,
      error: errorContentById,
      data: dataContentById,
    },
  ] = useLazyGetContentByIdQuery();

  const [
    uploadContentImage,
    {
      isLoading: isLoadingUploadContentImage,
      isError: isErrorUploadContentImage,
      isSuccess: isSuccessUploadContentImage,
      data: dataUploadContentImage,
      error: errorUploadContentImage,
    },
  ] = useUploadContentImageMutation();
  //#endregion

  //#region form
  const form = useFormik<CreateContentRequest | UpdateContentRequest>({
    enableReinitialize: true,
    initialErrors: {
      title: LocalizedPropertyInitialErrors,
      description: LocalizedPropertyInitialErrors,
      description1: LocalizedPropertyInitialErrors,
      author: LocalizedPropertyInitialErrors,
    },
    initialTouched: {
      title: LocalizedPropertyInitialTouched,
      author: LocalizedPropertyInitialTouched,
      description: LocalizedPropertyInitialTouched,
      description1: LocalizedPropertyInitialTouched,
    },
    initialValues: {
      id: null,
      sequence: 0,
      title: LocalizedPropertyInitialValues,
      author: LocalizedPropertyInitialValues,
      description: LocalizedPropertyInitialValues,
      description1: LocalizedPropertyInitialValues,
      steps: 0,
      type: type,
    },
    validationSchema: Yup.object({
      id: Yup.string().nullable(),
      title: LocalizedPropertyYupSchema("title", 255),
      author: LocalizedPropertyYupSchema(
        "author",
        255,
        [ContentTypeEnum.Reference].includes(type) ? true : false
      ),
      description: LocalizedPropertyYupSchema("description", null, false),
      description1: LocalizedPropertyYupSchema(
        "detailedDescription",
        null,
        false
      ),
      sequence: Yup.number().required(
        t("isNotEmpty", {
          displayName: "sequence",
        })
      ),
      steps: Yup.number(),
    }),
    onSubmit: async (values) => {
      if (id) {
        await updateContent({
          ...values,
          id: id,
          steps: Number(values.steps),
          sequence: Number(values.sequence),
        });
      } else {
        await addContent({
          ...values,
          steps: Number(values.steps),
          sequence: Number(values.sequence),
          parentId: parentId === "" || parentId === undefined ? null : parentId,
          prophetId:
            prophetId === "" || prophetId === undefined ? null : prophetId,
          media1Id: dataUploadContentImage?.id,
        });
      }
    },
  });
  //#endregion

  //#region effects
  useEffect(() => {
    if (
      (!isLoadingAddContent && isSuccessAddContent) ||
      (!isLoadingUpdateContent && isSuccessUpdateContent)
    ) {
      onClose();
    }
  }, [
    isLoadingAddContent,
    isLoadingUpdateContent,
    isSuccessAddContent,
    isSuccessUpdateContent,
  ]);

  useEffect(() => {
    if (
      id &&
      !isFetchingContentById &&
      isSuccessContentById &&
      dataContentById
    ) {
      form.setValues({
        id: dataContentById.id,
        sequence: dataContentById.sequence,
        title: dataContentById.title,
        steps: Number(dataContentById.steps),
        description: dataContentById.description,
        description1: dataContentById.description1,
        author: dataContentById.author,
        type: type,
      });
    }
  }, [isFetchingContentById, isSuccessAddContent]);

  useEffect(() => {
    if (!isLoadingUpdateContent && isErrorUpdateContent) {
      const _parsed = getServerError(errorUpdateContent);
      if (_parsed.type === ErrorHandlingTypeEnum.FORM) {
        form.setErrors(_parsed.error);
      }
      if (_parsed.type === ErrorHandlingTypeEnum.ALERT) {
        toast.error(_parsed.error, {
          autoClose: 3000,
        });
      }
    }
    if (!isLoadingUpdateContent && isSuccessUpdateContent) {
      toast.success(t("savedSuccessfully"), {
        autoClose: 3000,
      });
      onClose();
    }
  }, [isLoadingUpdateContent]);

  useEffect(() => {
    if (!isLoadingAddContent && isErrorAddContent) {
      const _parsed = getServerError(errorAddContent);
      if (_parsed.type === ErrorHandlingTypeEnum.FORM) {
        form.setErrors(_parsed.error);
      }
      if (_parsed.type === ErrorHandlingTypeEnum.ALERT) {
        toast.error(_parsed.error, {
          autoClose: 3000,
        });
      }
    }
    if (!isLoadingAddContent && isSuccessAddContent) {
      toast.success(t("savedSuccessfully"), {
        autoClose: 3000,
      });
      onClose();
    }
  }, [isLoadingAddContent]);

  useEffect(() => {
    if (!isFetchingContentById && isErrorContentById) {
      const _parsed = getServerError(errorContentById);
      if (_parsed.type === ErrorHandlingTypeEnum.FORM) {
        form.setErrors(_parsed.error);
      }
      if (_parsed.type === ErrorHandlingTypeEnum.ALERT) {
        toast.error(_parsed.error, {
          autoClose: 3000,
        });
      }
    }
    if (!isFetchingContentById && isSuccessContentById && dataContentById) {
      setDescriptionInitialValue(dataContentById.description);
      setImagePath(dataContentById?.media1?.path);
    }
  }, [isFetchingContentById]);

  useEffect(() => {
    async function initialize() {
      await getContentById(id as string);
    }

    if (id) {
      initialize();
    }
  }, [id]);

  useEffect(() => {
    if (!isLoadingUploadContentImage && isErrorUploadContentImage) {
      const _parsed = getServerError(errorUploadContentImage);
      if (_parsed.type === ErrorHandlingTypeEnum.FORM) {
        form.setErrors(_parsed.error);
      }
      if (_parsed.type === ErrorHandlingTypeEnum.ALERT) {
        toast.error(_parsed.error, {
          autoClose: 3000,
        });
      }
    }
    if (
      !isLoadingUploadContentImage &&
      isSuccessUploadContentImage &&
      dataUploadContentImage
    ) {
      toast.success(t("savedSuccessfully"), {
        autoClose: 3000,
      });
      setImagePath(dataUploadContentImage.path);
    }
  }, [isLoadingUploadContentImage]);

  //#endregion

  //#region handlers
  const onClose = () => {
    form.resetForm();
    setDescriptionInitialValue(LocalizedPropertyInitialValues);
    setImagePath(null);
    onCloseClick(!show);
  };

  const onChangeFile = async (file: File) => {
    await uploadContentImage({ media: file, id: id });
  };

  //#endregion

  return (
    <React.Fragment>
      <ToastContainer />
      <Modal
        id="addEditContent"
        isOpen={show}
        toggle={() => {
          onClose();
        }}
        centered
        className="modal-dialog modal-xl"
      >
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            form.handleSubmit();
            return false;
          }}
          action="#"
        >
          <ModalHeader
            className="p-3"
            toggle={() => {
              onClose();
            }}
          >
            {!id
              ? t("addItem", {
                  item: [ContentTypeEnum.Hajj, ContentTypeEnum.Umrah].includes(
                    type
                  )
                    ? "event"
                    : "content",
                })
              : t("editItem", {
                  item: [ContentTypeEnum.Hajj, ContentTypeEnum.Umrah].includes(
                    type
                  )
                    ? "event"
                    : "content",
                })}
          </ModalHeader>
          <ModalBody>
            <React.Fragment>
              {[
                ContentTypeEnum.Prophet_Event_Content,
                ContentTypeEnum.Reference,
              ].includes(type) && (
                <ImageUpload
                  path={imagePath}
                  inProgress={isLoadingUploadContentImage}
                  onChange={(file: File) => onChangeFile(file)}
                />
              )}
              <div className="mb-3">
                <Label htmlFor="sequence" className="form-label">
                  {t("sequence")}
                  <span className="text-danger">*</span>
                </Label>
                <Input
                  tabIndex={1}
                  type="text"
                  className="form-control"
                  id="sequence"
                  name="sequence"
                  placeholder={`${t("enterSequence")}`}
                  minLength={1}
                  maxLength={5}
                  value={form.values.sequence || ""}
                  onChange={(e) => {
                    e.preventDefault();
                    const { value } = e.target;
                    const regex =
                      /^(0*[0-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/;
                    if (regex.test(value.toString())) {
                      form.setFieldValue("sequence", value);
                    } else {
                      form.setFieldValue("sequence", "");
                    }
                  }}
                  onBlur={form.handleBlur}
                  invalid={
                    form.touched.sequence && form.errors.sequence ? true : false
                  }
                />
                {form.touched.sequence && form.errors.sequence ? (
                  <FormFeedback type="invalid">
                    {form.errors.sequence as string}
                  </FormFeedback>
                ) : null}
              </div>
              <InputLocalized
                tabIndex={2}
                name="title"
                form={form}
                type={"text"}
                label={
                  [ContentTypeEnum.Reference].includes(type)
                    ? "bookName"
                    : "title"
                }
              />
              {[ContentTypeEnum.Reference].includes(type) && (
                <InputLocalized
                  tabIndex={3}
                  name="author"
                  form={form}
                  type={"text"}
                  label={"author"}
                  required={
                    [ContentTypeEnum.Reference].includes(type) ? true : false
                  }
                />
              )}
              {[ContentTypeEnum.Hajj, ContentTypeEnum.Umrah].includes(type) && (
                <div className="mb-3">
                  <Label htmlFor="steps" className="form-label">
                    {t("steps")}
                  </Label>
                  <Input
                    tabIndex={
                      [ContentTypeEnum.Reference].includes(type) ? 4 : 3
                    }
                    type="text"
                    className="form-control"
                    id="steps"
                    name="steps"
                    placeholder={`${t("enterSteps")}`}
                    minLength={1}
                    maxLength={5}
                    value={form.values.steps || ""}
                    onChange={(e) => {
                      e.preventDefault();
                      const { value } = e.target;
                      const regex =
                        /^(0*[0-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/;
                      if (regex.test(value.toString())) {
                        form.setFieldValue("steps", value);
                      } else {
                        form.setFieldValue("steps", "");
                      }
                    }}
                    onBlur={form.handleBlur}
                    invalid={
                      form.touched.steps && form.errors.steps ? true : false
                    }
                  />
                  {form.touched.steps && form.errors.steps ? (
                    <FormFeedback type="invalid">
                      {form.errors.steps as string}
                    </FormFeedback>
                  ) : null}
                </div>
              )}
            </React.Fragment>

            {![ContentTypeEnum.Prophet_Event_Content].includes(type) && (
              <Row>
                <Col className="col-12">
                  <RichTextEditorLocalized
                    name="description"
                    form={form}
                    initialValue={descriptionInitialValue}
                    required={false}
                    onClose={() =>
                      setDescriptionInitialValue(form.values.description)
                    }
                    onCompletedTranslation={(
                      translation: LocalizedProperty
                    ) => {
                      setDescriptionInitialValue(translation);
                    }}
                  />
                </Col>
              </Row>
            )}

            {![ContentTypeEnum.Reference].includes(type) && (
              <Row>
                <Col className="col-12">
                  <InputLocalized
                    tabIndex={3}
                    name="description1"
                    form={form}
                    type={"textarea"}
                    label={"description1"}
                    required={false}
                  />
                </Col>
              </Row>
            )}
          </ModalBody>
          <ModalFooter>
            <Link
              to={"#"}
              onClick={onClose}
              className="btn btn-link link-success fw-medium"
            >
              <i className="ri-close-line me-1 align-middle"></i> {t("close")}
            </Link>
            <ActivityButton
              tabIndex={6}
              title="save"
              inProgressTitle="saving"
              color="success"
              className="btn btn-success"
              type="submit"
              inProgress={isLoadingAddContent || isLoadingUpdateContent}
              disabled={isLoadingAddContent || isLoadingUpdateContent}
            ></ActivityButton>
          </ModalFooter>
        </Form>
      </Modal>
    </React.Fragment>
  );
};
