import Masonry from "react-masonry-component";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from "reactstrap";

import { PaginateQuery } from "../../../interfaces";
import { getServerError } from "../../../services";
import { ErrorHandlingTypeEnum } from "../../../enums";
import { NoResultFound } from "../../../components/Common";
import { Paginator, TableSpinner } from "../../../components";
import {
  useDeleteBannerMutation,
  useLazyGetBannersQuery,
} from "../../../services/banner.api.service";
import { AddEditBannerModal } from "./AddEditBannerModal";
import DeleteModal from "../../../components/Common/DeleteModal";

export const BannersPage = () => {
  //#region hooks usage
  const { t, i18n } = useTranslation();
  //#endregion

  //#region others
  document.title = `${t("documentTitle", {
    pageName: "controlAppSlideShow",
  })}`;
  //#endregion

  //#region internal state
  const initState = new PaginateQuery(20);
  const [paginateQuery, setPaginateQuery] = useState<PaginateQuery>(initState);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [deletingItemId, setDeletingItemId] = useState<string | null>(null);
  const [editingItemId, setEditingItemId] = useState<string | null>(null);
  //#endregion

  //#region api
  const [
    getBanners,
    {
      isError: isErrorGetBanners,
      isLoading: isLoadingGetBanners,
      error: errorGetBanners,
      isSuccess: isSuccessGetBanners,
      data: dataGetBanners,
    },
  ] = useLazyGetBannersQuery();

  const [
    deleteBanner,
    {
      isError: isErrorDeleteBanner,
      isLoading: isLoadingDeleteBanner,
      error: errorDeleteBanner,
      isSuccess: isSuccessDeleteBanner,
      data: dataDeleteBanner,
    },
  ] = useDeleteBannerMutation();

  //#endregion

  //#region effects
  useEffect(() => {
    async function getData() {
      await getBanners(paginateQuery);
    }
    getData();
  }, [paginateQuery]);

  useEffect(() => {
    if (!isLoadingDeleteBanner && isErrorDeleteBanner) {
      const _parsed = getServerError(errorDeleteBanner);
      if (_parsed.type === ErrorHandlingTypeEnum.ALERT) {
        toast.error(_parsed.error, {
          autoClose: 3000,
        });
      }
    }
    if (!isLoadingDeleteBanner && isSuccessDeleteBanner) {
      setDeletingItemId(null);
      setShowDeleteModal(false);
    }
  }, [isLoadingDeleteBanner, isSuccessDeleteBanner]);

  useEffect(() => {
    if (!isLoadingGetBanners && isErrorGetBanners) {
      const _parsed = getServerError(errorGetBanners);
      if (_parsed.type === ErrorHandlingTypeEnum.ALERT) {
        toast.error(_parsed.error, {
          autoClose: 3000,
        });
      }
    }
  }, [isLoadingGetBanners]);

  //#endregion

  //#region handlers

  const onChangePage = (page: number) => {
    setPaginateQuery({ ...paginateQuery, page: page });
  };

  const onAdd = () => {
    setShowAddEditModal(true);
  };

  const onEdit = (id: string) => {
    setEditingItemId(id);
    setShowAddEditModal(true);
  };

  const onDelete = (id: string) => {
    setDeletingItemId(id);
    setShowDeleteModal(true);
  };

  const onDeleteConfirm = async () => {
    await deleteBanner(deletingItemId as string);
    setDeletingItemId(null);
  };

  //#endregion

  return (
    <React.Fragment>
      <ToastContainer />
      <Row>
        <Col>
          <Card>
            <CardHeader>
              <Button
                type="button"
                className="float-end btn btn-success"
                onClick={onAdd}
              >
                <i className="ri-add-fill me-1 align-bottom"></i>
                {t("addItem", { item: "image" })}
              </Button>
              <h6 className="card-title mt-2">{t("slideShow")}</h6>
            </CardHeader>
            <CardBody>
              {isLoadingGetBanners && <TableSpinner />}
              {!isLoadingGetBanners &&
                isSuccessGetBanners &&
                (!dataGetBanners?.data ||
                  dataGetBanners.data?.length === 0) && <NoResultFound />}

              <Masonry className="row gallery-wrapper">
                {!isLoadingGetBanners &&
                  isSuccessGetBanners &&
                  dataGetBanners?.data &&
                  dataGetBanners.data.length > 0 &&
                  dataGetBanners?.data.map(({ id, image, title }, key) => (
                    <Col
                      xxl={2}
                      xl={4}
                      sm={6}
                      className="element-item project designing development"
                      key={key}
                    >
                      <Card className="gallery-box">
                        <div className="gallery-container">
                          <img
                            className="gallery-img img-fluid mx-auto"
                            src={image.path}
                            alt=""
                          />
                          <div className="gallery-overlay">
                            <h5 className="overlay-caption">
                              {i18n.language === "ar" ? title.ar : title.en}
                            </h5>
                          </div>

                          <div
                            style={{
                              position: "absolute",
                              top: 20,
                              right: 10,
                            }}
                          >
                            <UncontrolledDropdown
                              direction="start"
                              className="col text-end"
                            >
                              <DropdownToggle
                                tag="button"
                                className="btn btn-link text-muted p-1 mt-n2 py-0 text-decoration-none fs-15"
                              >
                                <i className="ri-more-fill fs-17"></i>
                              </DropdownToggle>
                              <DropdownMenu className="dropdown-menu-end">
                                <DropdownItem
                                  onClick={() => {
                                    onEdit(id);
                                  }}
                                  style={{
                                    alignSelf: "flex-start",
                                    display: "flex",
                                  }}
                                >
                                  <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>{" "}
                                  {t("edit")}
                                </DropdownItem>
                                <DropdownItem
                                  style={{
                                    alignSelf: "flex-start",
                                    display: "flex",
                                  }}
                                  onClick={() => {
                                    onDelete(id);
                                  }}
                                >
                                  <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i>{" "}
                                  {t("delete")}
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </div>
                        </div>
                      </Card>
                    </Col>
                  ))}
              </Masonry>
              {dataGetBanners &&
                dataGetBanners.data &&
                dataGetBanners.data.length > 0 && (
                  <Paginator
                    itemsOnCurrentPage={dataGetBanners?.data?.length || 0}
                    itemsPerPage={dataGetBanners?.meta?.itemsPerPage as number}
                    totalItems={dataGetBanners?.meta?.totalItems || 0}
                    currentPage={dataGetBanners?.meta?.currentPage || 1}
                    totalPages={dataGetBanners?.meta?.totalPages || 0}
                    onChangePage={(pageToChange: number) =>
                      onChangePage(pageToChange)
                    }
                  />
                )}
            </CardBody>
          </Card>
        </Col>
      </Row>
      <DeleteModal
        loading={isLoadingDeleteBanner}
        show={showDeleteModal}
        onDeleteClick={() => onDeleteConfirm()}
        onCloseClick={() => {
          setShowDeleteModal(false);
        }}
      />
      <AddEditBannerModal
        id={editingItemId}
        show={showAddEditModal}
        onCloseClick={() => {
          setShowAddEditModal(false);
          setEditingItemId(null);
        }}
      />
    </React.Fragment>
  );
};
