import { ToastContainer, toast } from "react-toastify";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { DebounceInput } from "react-debounce-input";
import { TableColumn } from "react-data-table-component";
import { Container, Row, Col, Card, CardBody, Button } from "reactstrap";

import { ContentDto } from "../../../interfaces";
import { PaginateQuery } from "../../../models";
import { BreadCrumb } from "../../../components/Common";
import {
  ActionTypeEnum,
  ErrorHandlingTypeEnum,
  ContentTypeEnum,
} from "../../../enums";
import {
  ColumnHeader,
  TextColumn,
  ActionButton,
  AppTable,
  TableSpinner,
  DateColumn,
  ContentSelect,
  ProphetSelect,
} from "../../../components";

import {
  getServerError,
  useDeleteContentMutation,
  useLazyGetPaginatedContentQuery,
  useToggleContentStateMutation,
} from "../../../services";
import DeleteModal from "../../../components/Common/DeleteModal";
import { AddEditContentModal } from "./AddEditContentModal";
import ConfirmationModal from "../../../components/Common/ConfirmationModal";

type TProps = {
  title: string;
  type: ContentTypeEnum;
};
export const Content: React.FC<TProps> = ({ title, type }) => {
  //#region hooks usage
  const history = useHistory();
  const { t, i18n } = useTranslation();
  //#endregion

  //#region others
  document.title = `${t("documentTitle", { pageName: title })}`;
  //#endregion

  //#region api
  const [
    getPaginatedContent,
    {
      isError: isErrorGetPaginatedContent,
      error: errorGetPaginatedContent,
      isFetching: isFetchingGetPaginatedContent,
      data: dataGetPaginatedContent,
    },
  ] = useLazyGetPaginatedContentQuery();

  const [
    deleteContent,
    {
      isLoading: isLoadingDeleteContent,
      isError: isErrorDeleteContent,
      isSuccess: isSuccessDeleteContent,
      error: errorDeleteContent,
    },
  ] = useDeleteContentMutation();

  const [
    toggleContentState,
    {
      isLoading: isLoadingToggleContentState,
      isError: isErrorToggleContentState,
      isSuccess: isSuccessToggleContentState,
      error: errorToggleContentState,
    },
  ] = useToggleContentStateMutation();

  //#endregion

  //#region internal state
  const initState = new PaginateQuery(10, "sequence:ASC");
  const [paginateQuery, setPaginateQuery] = useState<PaginateQuery>({
    ...initState,
    filter: {
      ...initState.filter,
      types: [type],
    },
  });
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [deletingContentId, setDeletingContentId] = useState<string | null>(
    null
  );
  const [editingContentId, setEditingContentId] = useState<string | null>(null);
  const [defaultSortAsc, setDefaultSortAsc] = useState(true);
  const [defaultPage, setDefaultPage] = useState(1);
  const [parentId, setParentId] = useState<
    string | number | readonly string[] | undefined
  >();
  const [prophetId, setProphetId] = useState<
    string | number | readonly string[] | undefined
  >();
  const [showToggleStateModal, setShowToggleStateModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState<ContentDto | null>();

  //#endregion

  //#region Table Columns
  const columns: TableColumn<ContentDto>[] = [
    ContentTypeEnum.Hajj,
    ContentTypeEnum.Umrah,
  ].includes(type)
    ? [
        {
          name: <ColumnHeader title="sequence" />,
          cell: (row) => <TextColumn text={row.sequence} />,
          sortable: true,
          width: "120px",
        },
        {
          name: <ColumnHeader title="title" />,
          cell: (row) => (
            <TextColumn
              text={row.title[i18n.language === "ar" ? "ar" : "en"] as string}
            />
          ),
          sortable: true,
        },
        {
          name: <ColumnHeader title="steps" />,
          cell: (row) => <TextColumn text={row.steps ? row.steps : "-"} />,
          sortable: true,
        },
        {
          name: <ColumnHeader title="createdAt" />,
          cell: (row) => <DateColumn date={row.createdAt} />,
          sortable: true,
        },
        {
          name: <ColumnHeader title="lastModifiedAt" />,
          cell: (row) => <DateColumn date={row.modifiedAt} />,
          sortable: true,
        },
        {
          name: <ColumnHeader title="action" />,
          sortable: false,
          width: "120px",
          cell: (row) => (
            <React.Fragment>
              <ActionButton
                onAction={() => onToggleState(row)}
                actionType={ActionTypeEnum.ACTIVE_INACTIVE}
                state={!row.isActive}
              />
              &nbsp;
              <ActionButton
                onAction={() => onEdit(row.id)}
                actionType={ActionTypeEnum.EDIT}
              />
              &nbsp;
              <ActionButton
                onAction={() => onDelete(row.id)}
                actionType={ActionTypeEnum.DELETE}
              />
            </React.Fragment>
          ),
        },
      ]
    : [ContentTypeEnum.Muslim_Life_Content].includes(type)
    ? [
        {
          name: <ColumnHeader title="sequence" />,
          cell: (row) => <TextColumn text={row.sequence} />,
          sortable: true,
          width: "120px",
        },
        {
          name: <ColumnHeader title="title" />,
          cell: (row) => (
            <TextColumn
              text={row.title[i18n.language === "ar" ? "ar" : "en"] as string}
            />
          ),
          sortable: true,
        },
        {
          name: <ColumnHeader title="topic" />,
          cell: (row) => (
            <TextColumn
              text={
                i18n.language === "ar"
                  ? row.parentContent?.title?.ar || "-"
                  : row.parentContent?.title?.en || "-"
              }
            />
          ),
          sortable: false,
        },
        {
          name: <ColumnHeader title="createdAt" />,
          cell: (row) => <DateColumn date={row.createdAt} />,
          sortable: true,
        },
        {
          name: <ColumnHeader title="lastModifiedAt" />,
          cell: (row) => <DateColumn date={row.modifiedAt} />,
          sortable: true,
        },
        {
          name: <ColumnHeader title="action" />,
          sortable: false,
          width: "120px",
          cell: (row) => (
            <React.Fragment>
              <ActionButton
                onAction={() => onToggleState(row)}
                actionType={ActionTypeEnum.ACTIVE_INACTIVE}
                state={!row.isActive}
              />
              &nbsp;
              <ActionButton
                onAction={() => onEdit(row.id)}
                actionType={ActionTypeEnum.EDIT}
              />
              &nbsp;
              <ActionButton
                onAction={() => onDelete(row.id)}
                actionType={ActionTypeEnum.DELETE}
              />
            </React.Fragment>
          ),
        },
      ]
    : [ContentTypeEnum.Reference].includes(type)
    ? [
        {
          name: <ColumnHeader title="sequence" />,
          cell: (row) => <TextColumn text={row.sequence} />,
          sortable: true,
          width: "120px",
        },
        {
          name: <ColumnHeader title="bookName" />,
          cell: (row) => (
            <TextColumn
              text={row.title[i18n.language === "ar" ? "ar" : "en"] as string}
            />
          ),
          sortable: true,
        },
        {
          name: <ColumnHeader title="author" />,
          cell: (row) => (
            <TextColumn
              text={row.author[i18n.language === "ar" ? "ar" : "en"] as string}
            />
          ),
          sortable: true,
        },
        {
          name: <ColumnHeader title="createdAt" />,
          cell: (row) => <DateColumn date={row.createdAt} />,
          sortable: true,
        },
        {
          name: <ColumnHeader title="lastModifiedAt" />,
          cell: (row) => <DateColumn date={row.modifiedAt} />,
          sortable: true,
        },
        {
          name: <ColumnHeader title="action" />,
          sortable: false,
          width: "120px",
          cell: (row) => (
            <React.Fragment>
              <ActionButton
                onAction={() => onToggleState(row)}
                actionType={ActionTypeEnum.ACTIVE_INACTIVE}
                state={!row.isActive}
              />
              &nbsp;
              <ActionButton
                onAction={() => onEdit(row.id)}
                actionType={ActionTypeEnum.EDIT}
              />
              &nbsp;
              <ActionButton
                onAction={() => onDelete(row.id)}
                actionType={ActionTypeEnum.DELETE}
              />
            </React.Fragment>
          ),
        },
      ]
    : [ContentTypeEnum.Prophet_Event_Content].includes(type)
    ? [
        {
          name: <ColumnHeader title="sequence" />,
          cell: (row) => <TextColumn text={row.sequence} />,
          sortable: true,
          width: "120px",
        },
        {
          name: <ColumnHeader title="title" />,
          cell: (row) => (
            <TextColumn
              text={row.title[i18n.language === "ar" ? "ar" : "en"] as string}
            />
          ),
          sortable: true,
        },
        {
          name: <ColumnHeader title="createdAt" />,
          cell: (row) => <DateColumn date={row.createdAt} />,
          sortable: true,
        },
        {
          name: <ColumnHeader title="lastModifiedAt" />,
          cell: (row) => <DateColumn date={row.modifiedAt} />,
          sortable: true,
        },
        {
          name: <ColumnHeader title="action" />,
          sortable: false,
          width: "120px",
          cell: (row) => (
            <React.Fragment>
              <ActionButton
                onAction={() => onToggleState(row)}
                actionType={ActionTypeEnum.ACTIVE_INACTIVE}
                state={!row.isActive}
              />
              &nbsp;
              <ActionButton
                onAction={() => onEdit(row.id)}
                actionType={ActionTypeEnum.EDIT}
              />
              &nbsp;
              <ActionButton
                onAction={() => onDelete(row.id)}
                actionType={ActionTypeEnum.DELETE}
              />
            </React.Fragment>
          ),
        },
      ]
    : [
        {
          name: <ColumnHeader title="sequence" />,
          cell: (row) => <TextColumn text={row.sequence} />,
          sortable: true,
          width: "120px",
        },
        {
          name: <ColumnHeader title="title" />,
          cell: (row) => (
            <TextColumn
              text={row.title[i18n.language === "ar" ? "ar" : "en"] as string}
            />
          ),
          sortable: true,
        },
        {
          name: <span className="font-weight-bold fs-13">{t("Content")}</span>,
          cell: (row) => (
            <div className="d-flex gap-2 align-items-center">
              <div className="flex-grow-1">
                <Link
                  to={`content?page=1&limit=10&sortBy=sequence:ASC&filter[types]=Muslim_Life_Content&filter[topic]=${row.id}`}
                  style={{ cursor: "pointer" }}
                  className="link"
                  title={t("viewContent")}
                >
                  {t("viewContent")}
                </Link>
              </div>
            </div>
          ),
          sortable: false,
        },
        {
          name: <ColumnHeader title="createdAt" />,
          cell: (row) => <DateColumn date={row.createdAt} />,
          sortable: true,
        },
        {
          name: <ColumnHeader title="lastModifiedAt" />,
          cell: (row) => <DateColumn date={row.modifiedAt} />,
          sortable: true,
        },
        {
          name: <ColumnHeader title="action" />,
          sortable: false,
          width: "120px",
          cell: (row) => (
            <React.Fragment>
              <ActionButton
                onAction={() => onToggleState(row)}
                actionType={ActionTypeEnum.ACTIVE_INACTIVE}
                state={!row.isActive}
              />
              &nbsp;
              <ActionButton
                onAction={() => onEdit(row.id)}
                actionType={ActionTypeEnum.EDIT}
              />
              &nbsp;
              <ActionButton
                onAction={() => onDelete(row.id)}
                actionType={ActionTypeEnum.DELETE}
              />
            </React.Fragment>
          ),
        },
      ];

  //#endregion

  //#region effects

  useEffect(() => {
    const queryParams = new URLSearchParams(history.location.search);
    let query: PaginateQuery = {
      limit: queryParams.has("limit")
        ? Number(queryParams.get("limit"))
        : paginateQuery.limit,
      page: queryParams.has("page")
        ? Number(queryParams.get("page"))
        : paginateQuery.page,
      sortBy: queryParams.has("sortBy")
        ? (queryParams.get("sortBy") as string)
        : paginateQuery.sortBy,
      search: queryParams.has("search")
        ? (queryParams.get("search") as string)
        : paginateQuery.search,
      filter: getFilterParams(queryParams),
    };
    if (
      queryParams.get("sortBy") &&
      queryParams.get("sortBy")?.split(":").length &&
      queryParams.get("sortBy")?.split(":").length == 2
    ) {
      setDefaultSortAsc(
        (queryParams.get("sortBy")?.split(":")[1] as string) === "ASC"
      );
      setDefaultSortFieldId(
        getSortByColumnName(queryParams.get("sortBy")?.split(":")[0] as string)
      );
    } else {
      setDefaultSortAsc(true);
      setDefaultSortFieldId(1);
    }
    setDefaultPage(query.page);
    setPaginateQuery(query);
  }, []);

  useEffect(() => {
    if (paginateQuery !== initState) {
      const { limit, page, search, sortBy, filter } = paginateQuery;
      let query;
      if (page) query = `?page=${page}`;
      if (limit) query += `&limit=${limit}`;
      if (search && search !== "") {
        history.replace({
          search: `&search=${search}`,
        });
      }
      if (sortBy) query += `&sortBy=${sortBy}`;
      if (search) query += `&search=${search}`;
      if (
        filter &&
        Object.keys(filter).length &&
        Object.keys(filter).length > 0
      ) {
        let filters: string = "";
        Object.keys(filter).map((key: string) => {
          if (filter[key]) filters += `&filter[${key}]=${filter[key]}`;
        });
        query += filters;
      }
      history.replace({ search: query });
      getPaginatedContent(paginateQuery);
    }
  }, [paginateQuery]);

  useEffect(() => {
    if (!isFetchingGetPaginatedContent && isErrorGetPaginatedContent) {
      const _parsed = getServerError(errorGetPaginatedContent);
      if (_parsed.type === ErrorHandlingTypeEnum.ALERT) {
        toast.error(_parsed.error, {
          autoClose: 3000,
        });
      }
    }
  }, [isFetchingGetPaginatedContent, isErrorGetPaginatedContent]);

  useEffect(() => {
    if (!isLoadingDeleteContent && isErrorDeleteContent) {
      const _parsed = getServerError(errorDeleteContent);
      if (_parsed.type === ErrorHandlingTypeEnum.ALERT) {
        toast.error(_parsed.error, {
          autoClose: 3000,
        });
      }
    }
    if (!isLoadingDeleteContent && isSuccessDeleteContent) {
      setShowDeleteModal(false);
      toast.success(t("deletedSuccessfully"), { autoClose: 3000 });
    }
  }, [isLoadingDeleteContent, isErrorDeleteContent, isSuccessDeleteContent]);

  useEffect(() => {
    if (!isLoadingToggleContentState && isErrorToggleContentState) {
      const _parsed = getServerError(errorToggleContentState);
      if (_parsed.type === ErrorHandlingTypeEnum.ALERT) {
        toast.error(_parsed.error, {
          autoClose: 3000,
        });
      }
    }
    if (!isLoadingToggleContentState && isSuccessToggleContentState) {
      setShowToggleStateModal(false);
      toast.info(t("savedSuccessfully"), { autoClose: 3000 });
    }
  }, [
    isLoadingToggleContentState,
    isErrorToggleContentState,
    isSuccessToggleContentState,
  ]);

  //#endregion

  //#region handlers

  const getSortByColumnName = (name: string) => {
    if ([ContentTypeEnum.Hajj, ContentTypeEnum.Umrah].includes(type)) {
      let sortBy;
      switch (name) {
        case "sequence":
          sortBy = 1;
          break;
        case "title.en":
        case "title.ar":
          sortBy = 2;
          break;
        case "created_at":
          sortBy = 3;
          break;
        case "modified_at":
          sortBy = 4;
          break;
        default:
          sortBy = 1;
          break;
      }
      return sortBy;
    } else if ([ContentTypeEnum.Muslim_Life_Topic].includes(type)) {
      let sortBy;
      switch (name) {
        case "sequence":
          sortBy = 1;
          break;
        case "title.en":
        case "title.ar":
          sortBy = 2;
          break;
        case "created_at":
          sortBy = 3;
          break;
        case "modified_at":
          sortBy = 4;
          break;
        default:
          sortBy = 1;
          break;
      }
      return sortBy;
    } else if ([ContentTypeEnum.Muslim_Life_Content].includes(type)) {
      let sortBy;
      switch (name) {
        case "sequence":
          sortBy = 1;
          break;
        case "title.en":
        case "title.ar":
          sortBy = 2;
          break;
        case "created_at":
          sortBy = 4;
          break;
        case "modified_at":
          sortBy = 5;
          break;
        default:
          sortBy = 1;
          break;
      }
      return sortBy;
    } else if ([ContentTypeEnum.Reference].includes(type)) {
      let sortBy;
      switch (name) {
        case "sequence":
          sortBy = 1;
          break;
        case "title.en":
        case "title.ar":
          sortBy = 2;
          break;
        case "author.en":
        case "author.ar":
          sortBy = 3;
          break;
        case "created_at":
          sortBy = 4;
          break;
        case "modified_at":
          sortBy = 5;
          break;
        default:
          sortBy = 1;
          break;
      }
      return sortBy;
    } else if ([ContentTypeEnum.Prophet_Event_Content].includes(type)) {
      let sortBy;
      switch (name) {
        case "sequence":
          sortBy = 1;
          break;
        case "title.en":
        case "title.ar":
          sortBy = 2;
          break;
        case "created_at":
          sortBy = 3;
          break;
        case "modified_at":
          sortBy = 4;
          break;
        default:
          sortBy = 1;
          break;
      }
      return sortBy;
    }
  };

  const getFilterParams = (queryParams: URLSearchParams): any => {
    let filter = {};
    if (queryParams.has("filter[types]")) {
      filter = { ["types"]: queryParams.get("filter[types]") || "" };
    } else {
      filter = { ...paginateQuery.filter };
    }
    if (queryParams.has("filter[topic]")) {
      filter = { ...filter, ["topic"]: queryParams.get("filter[topic]") || "" };
      setParentId(queryParams.get("filter[topic]") || "");
    }
    if (queryParams.has("filter[prophet]")) {
      filter = {
        ...filter,
        ["prophet"]: queryParams.get("filter[prophet]") || "",
      };
      setProphetId(queryParams.get("filter[prophet]") || "");
    }
    return filter;
  };

  const onToggleState = (_item: ContentDto) => {
    setSelectedItem(_item);
    setShowToggleStateModal(true);
  };

  const onToggleStateConfirm = async () => {
    await toggleContentState(selectedItem?.id as string);
    setSelectedItem(null);
  };

  const onAdd = () => {
    setShowAddEditModal(true);
  };

  const onEdit = (id: string) => {
    setEditingContentId(id);
    setShowAddEditModal(true);
  };

  const onDelete = (id: string) => {
    setDeletingContentId(id);
    setShowDeleteModal(true);
  };

  const onDeleteConfirm = async () => {
    await deleteContent(deletingContentId as string);
    setDeletingContentId(null);
  };

  const onSearch = (searchText: string) => {
    setPaginateQuery({ ...paginateQuery, search: searchText });
  };

  const getSortByColumnId = (columnId: number, sortDirection: string) => {
    let sortBy;
    if ([ContentTypeEnum.Hajj, ContentTypeEnum.Umrah].includes(type)) {
      switch (columnId) {
        case 1:
          sortBy = "sequence";
          break;
        case 2:
          sortBy = "title" + "." + i18n.language;
          break;
        case 3:
          sortBy = "steps";
          break;
        case 4:
          sortBy = "created_at";
          break;
        case 5:
          sortBy = "modified_at";
          break;
        default:
          sortBy = "sequence";
          break;
      }
    } else if ([ContentTypeEnum.Muslim_Life_Topic].includes(type)) {
      switch (columnId) {
        case 1:
          sortBy = "sequence";
          break;
        case 2:
          sortBy = "title" + "." + i18n.language;
          break;
        case 4:
          sortBy = "created_at";
          break;
        case 5:
          sortBy = "modified_at";
          break;
        default:
          sortBy = "sequence";
          break;
      }
    } else if ([ContentTypeEnum.Muslim_Life_Content].includes(type)) {
      switch (columnId) {
        case 1:
          sortBy = "sequence";
          break;
        case 2:
          sortBy = "title" + "." + i18n.language;
          break;
        case 4:
          sortBy = "created_at";
          break;
        case 5:
          sortBy = "modified_at";
          break;
        default:
          sortBy = "sequence";
          break;
      }
    } else if ([ContentTypeEnum.Reference].includes(type)) {
      switch (columnId) {
        case 1:
          sortBy = "sequence";
          break;
        case 2:
          sortBy = "title" + "." + i18n.language;
          break;
        case 3:
          sortBy = "author" + "." + i18n.language;
          break;
        case 4:
          sortBy = "created_at";
          break;
        case 5:
          sortBy = "modified_at";
          break;
        default:
          sortBy = "sequence";
          break;
      }
    } else if ([ContentTypeEnum.Prophet_Event_Content].includes(type)) {
      switch (columnId) {
        case 1:
          sortBy = "sequence";
          break;
        case 2:
          sortBy = "title" + "." + i18n.language;
          break;
        case 3:
          sortBy = "created_at";
          break;
        case 4:
          sortBy = "modified_at";
          break;
        default:
          sortBy = "sequence";
          break;
      }
    }
    return `${sortBy}:${sortDirection.toUpperCase()}`;
  };

  const onChangePage = (page: number) => {
    setPaginateQuery({ ...paginateQuery, page: page });
  };

  const onChangeRowsPerPage = (
    currentRowsPerPage: number,
    currentPage: number
  ) => {
    setPaginateQuery({
      ...paginateQuery,
      limit: currentRowsPerPage,
      page: currentPage,
    });
  };

  const onSort = (column: TableColumn<ContentDto>, sortDirection: string) => {
    const sortBy = getSortByColumnId(column.id as number, sortDirection);
    setPaginateQuery({
      ...paginateQuery,
      sortBy: sortBy as string,
    });
  };

  const onChangeContentTopic = (value: string) => {
    setParentId(value);
    setPaginateQuery({
      ...paginateQuery,
      page: 1,
      filter: {
        ...paginateQuery.filter,
        topic: value,
      },
    });
  };

  const onChangeContentProphet = (value: string) => {
    setProphetId(value);
    setPaginateQuery({
      ...paginateQuery,
      page: 1,
      filter: {
        ...paginateQuery.filter,
        prophet: value,
      },
    });
  };

  //#endregion

  const [defaultSortFieldId, setDefaultSortFieldId] = useState(
    getSortByColumnName("sequence:ASC")
  );

  return (
    <React.Fragment>
      <ToastContainer newestOnTop={true} />
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t(title)} pageTitle={t("menu")} />
          <Row>
            <Col xxl={12}>
              <Card>
                <CardBody>
                  <Row className="g-2 mb-3">
                    <Col sm={4} className="mb-2">
                      <div className="search-box">
                        <DebounceInput
                          className="form-control"
                          placeholder={`${t("searchPlaceholder")}...`}
                          minLength={2}
                          debounceTimeout={300}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => onSearch(event.target.value)}
                          value={paginateQuery.search}
                        />
                        <i className="ri-search-line search-icon"></i>
                      </div>
                    </Col>
                    {[ContentTypeEnum.Muslim_Life_Content].includes(type) && (
                      <Col sm={4} className="mb-2">
                        <div className="search-box">
                          <ContentSelect
                            placeholder={t("filterByContentTopic")}
                            contentType={ContentTypeEnum.Muslim_Life_Topic}
                            value={parentId}
                            onChange={(value: string) => {
                              onChangeContentTopic(value);
                            }}
                            prophetId={null}
                          ></ContentSelect>
                        </div>
                      </Col>
                    )}
                    {[ContentTypeEnum.Prophet_Event_Content].includes(type) && (
                      <>
                        <Col sm={4} className="mb-2">
                          <div className="search-box">
                            <ProphetSelect
                              placeholder={t("filterByProphet")}
                              value={prophetId}
                              onChange={(value: string) => {
                                onChangeContentProphet(value);
                              }}
                            ></ProphetSelect>
                          </div>
                        </Col>
                      </>
                    )}
                    <Col className="col-sm-auto ms-auto">
                      <div className="list-grid-nav hstack gap-1">
                        <Button color="success" onClick={() => onAdd()}>
                          <i className="ri-add-fill me-1 align-bottom"></i>
                          {t("addItem", {
                            item: [
                              ContentTypeEnum.Umrah,
                              ContentTypeEnum.Hajj,
                            ].includes(type)
                              ? "event"
                              : [ContentTypeEnum.Reference].includes(type)
                              ? "reference"
                              : "content",
                          })}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12}>
                      <AppTable
                        theme={"dark"}
                        columns={columns}
                        data={dataGetPaginatedContent?.data || []}
                        progressPending={isFetchingGetPaginatedContent}
                        progressComponent={<TableSpinner />}
                        pagination
                        paginationServer
                        sortServer
                        onSort={onSort}
                        onChangeRowsPerPage={onChangeRowsPerPage}
                        onChangePage={onChangePage}
                        defaultSortFieldId={defaultSortFieldId}
                        paginationDefaultPage={defaultPage}
                        defaultSortAsc={defaultSortAsc}
                        persistTableHead
                        paginationTotalRows={
                          dataGetPaginatedContent?.meta?.totalItems
                        }
                        paginationComponentOptions={{
                          rowsPerPageText: t("rowsPerPage"),
                          rangeSeparatorText: t("of"),
                        }}
                        fixedHeader={true}
                        fixedHeaderScrollHeight="60vh"
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <DeleteModal
        loading={isLoadingDeleteContent}
        show={showDeleteModal}
        onDeleteClick={() => onDeleteConfirm()}
        onCloseClick={() => {
          setShowDeleteModal(false);
        }}
      />
      <AddEditContentModal
        id={editingContentId}
        show={showAddEditModal}
        parentId={(paginateQuery?.filter?.topic as string) || ""}
        prophetId={(paginateQuery?.filter?.prophet as string) || ""}
        onCloseClick={async () => {
          setShowAddEditModal(false);
          setEditingContentId(null);
        }}
        type={type}
      />
      <ConfirmationModal
        confirmationButtonInProgressTitle={t("saving")}
        confirmationButtonTitle={
          (selectedItem?.isActive as boolean) ? t("inactivate") : t("activate")
        }
        description={
          (selectedItem?.isActive as boolean)
            ? t("activateConfirmationMessage", {
                item: selectedItem?.title.en,
              })
            : t("inactivateConfirmationMessage", {
                item: selectedItem?.title.en,
              })
        }
        icon={<></>}
        title={t("areYouSure")}
        loading={isLoadingToggleContentState}
        show={showToggleStateModal}
        onConfirmClick={() => onToggleStateConfirm()}
        onCloseClick={() => {
          setShowToggleStateModal(false);
        }}
      />
    </React.Fragment>
  );
};
