import { createApi } from "@reduxjs/toolkit/query/react";
import {
  ChangePasswordRequest,
  UpdateUserProfileRequest,
  UserProfileDto,
} from "../interfaces";
import { MediaDto } from "../interfaces/media";
import { baseQueryWithInterceptor } from "./base.api";

export const accountApi = createApi({
  reducerPath: "accountApi",
  baseQuery: baseQueryWithInterceptor,
  endpoints: (builder) => ({
    getUserProfile: builder.query<UserProfileDto, null>({
      query: () => ({
        url: `/user/profile/me`,
        method: "GET",
      }),
      providesTags: ["user_profile"],
    }),
    updateUserProfile: builder.mutation<
      UserProfileDto,
      UpdateUserProfileRequest
    >({
      query: (body) => ({
        url: `/user/profile/me`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["user_profile"],
    }),
    uploadUserProfilePhoto: builder.mutation<MediaDto, File>({
      query: (file: File) => {
        var bodyFormData = new FormData();
        bodyFormData.append("file", file);
        return {
          url: `/user/profile/photo/me`,
          method: "POST",
          body: bodyFormData,
          formData: true,
        };
      },
      invalidatesTags: ["user_profile"],
    }),
    changePassword: builder.mutation<void, ChangePasswordRequest>({
      query: (body) => ({
        url: `/user/profile/change-password`,
        method: "PATCH",
        body,
      }),
    }),
  }),
  tagTypes: ["user_profile"],
});

export const {
  useLazyGetUserProfileQuery,
  useUpdateUserProfileMutation,
  useUploadUserProfilePhotoMutation,
  useChangePasswordMutation,
} = accountApi;
