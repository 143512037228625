import React from "react";
import { useTranslation } from "react-i18next";
import { Button, ButtonProps, Spinner } from "reactstrap";

export const ActivityButton: React.FC<
  { inProgress: boolean; inProgressTitle?: string } & ButtonProps
> = (props) => {
  const { t } = useTranslation();

  const renderProgress = () =>
    props.inProgress ? (
      <span className="d-flex align-items-center">
        <span className="flex-grow-1 me-2">
          {t(props.inProgressTitle || "inProgress")}...
        </span>
        <Spinner size="sm" className="flex-shrink-0" role="status">
          {t(props.inProgressTitle || "inProgress")}...
        </Spinner>
      </span>
    ) : (
      <>{t(props.title || "Button")}</>
    );
  //@ts-ignore
  return <Button {...props}>{renderProgress()}</Button>;
};
