import { createApi } from "@reduxjs/toolkit/query/react";

import { PaginateQuery, UserDto } from "../interfaces";
import { baseQueryWithInterceptor } from "./base.api";
import { Paginated } from "../models";
import { convertObjectToQueryParams } from "./helper.service";

export const complaintApi = createApi({
  reducerPath: "complaintApi",
  baseQuery: baseQueryWithInterceptor,
  endpoints: (builder) => ({
    getPaginatedComplaint: builder.query<Paginated<UserDto>, PaginateQuery>({
      query: (query) => ({
        url: `/complaint/paginate?${convertObjectToQueryParams(query)}`,
        method: "GET",
      }),
      providesTags: ["complaint"],
    }),
  }),
  tagTypes: ["complaint"],
});

export const { useLazyGetPaginatedComplaintQuery } = complaintApi;
