import React from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import { Form, Row, Col, Input, FormFeedback } from "reactstrap";

import { UpdateUserProfileRequest } from "../../interfaces";
import { ErrorHandlingTypeEnum, GenderEnum } from "../../enums";
import { ActivityButton } from "../../components/Common";
import {
  getServerError,
  useLazyGetUserProfileQuery,
  useUpdateUserProfileMutation,
} from "../../services";

export const PersonalDetails = () => {
  //#region hooks
  const { t } = useTranslation();
  //#endregion

  //#region internal state
  const [request] = useState<UpdateUserProfileRequest>();
  //#endregion

  //#region api
  const [
    updateUserProfile,
    {
      isError: isErrorUpdateUserProfile,
      isLoading: isLoadingUpdateUserProfile,
      error: errorUpdateUserProfile,
      isSuccess: isSuccessUpdateUserProfile,
      data: dataUpdateUserProfile,
    },
  ] = useUpdateUserProfileMutation();

  const [
    getUserProfile,
    { isLoading: isLoadingGetUserProfile, data: dataGetUserProfile },
  ] = useLazyGetUserProfileQuery();
  //#endregion

  //#region form
  const form = useFormik<UpdateUserProfileRequest>({
    enableReinitialize: true,
    initialValues: {
      name: {
        en: request?.name.en || dataGetUserProfile?.name?.en || "",
        ar: "",
      },
      email: request?.email || dataGetUserProfile?.email?.email || "",
      gender: request?.gender || dataGetUserProfile?.gender || GenderEnum.MALE,
    },
    validationSchema: Yup.object({
      name: Yup.object({
        en: Yup.string()
          .max(100, t("maxLength", { maxLength: 100, displayName: "name" }))
          .required(
            t("isNotEmpty", {
              displayName: "name",
            })
          ),
      }),
      email: Yup.string()
        .email(
          t("invalidEmail", {
            displayName: "email",
          })
        )
        .required(
          t("isNotEmpty", {
            displayName: "email",
          })
        ),
    }),
    onSubmit: async (values) => {
      await updateUserProfile({ ...values });
    },
  });
  //#endregion

  //#region effects

  useEffect(() => {
    async function initialize() {
      await getUserProfile(null);
    }
    initialize();
  }, []);

  useEffect(() => {
    if (!isLoadingUpdateUserProfile && isErrorUpdateUserProfile) {
      const _parsed = getServerError(errorUpdateUserProfile);
      if (_parsed.type === ErrorHandlingTypeEnum.FORM) {
        form.setErrors(_parsed.error);
      }
      if (_parsed.type === ErrorHandlingTypeEnum.ALERT) {
        toast.error(_parsed.error, {
          autoClose: 3000,
        });
      }
    }
    if (
      !isLoadingUpdateUserProfile &&
      isSuccessUpdateUserProfile &&
      dataUpdateUserProfile
    ) {
      toast.success(t("savedSuccessfully"), {
        autoClose: 3000,
      });
    }
  }, [isLoadingUpdateUserProfile]);

  //#endregion

  return (
    <React.Fragment>
      <ToastContainer />
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          form.handleSubmit();
          return false;
        }}
        action="#"
      >
        <Row>
          <Col lg={6}>
            <div className="mb-3">
              <label htmlFor="name.en" className="form-label">
                {t("name")}
                <span className="text-danger">*</span>
              </label>
              <Input
                autoComplete="off"
                id="name.en"
                name="name.en"
                value={form.values?.name.en}
                type="text"
                className="form-control"
                placeholder={t("enterName")}
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                invalid={
                  form.touched.name?.en && form.errors.name?.en ? true : false
                }
              />
              {form.touched.name?.en && form.errors.name?.en ? (
                <FormFeedback type="invalid">
                  {form.errors.name?.en as string}
                </FormFeedback>
              ) : null}
            </div>
          </Col>
          <Col lg={6}>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                {t("email")}
                <span className="text-danger">*</span>
              </label>
              <Input
                disabled
                readOnly
                autoComplete="off"
                id="email"
                name="email"
                value={form.values?.email}
                type="text"
                className="form-control"
                placeholder={t("enterEmail")}
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                invalid={form.touched.email && form.errors.email ? true : false}
              />
              {form.touched.email && form.errors.email ? (
                <FormFeedback type="invalid">
                  {form.errors.email as string}
                </FormFeedback>
              ) : null}
            </div>
          </Col>
          <Col lg={6} className="mt-1">
            <label className="form-label">{t("gender")}</label>
            <div>
              <div className="form-check form-check-inline">
                <Input
                  className="form-check-input"
                  type="radio"
                  name="inlineRadioOptions"
                  id="inlineRadio1"
                  value={GenderEnum.MALE}
                  checked={form.values.gender === GenderEnum.MALE}
                  onChange={() => {
                    form.setFieldValue("gender", GenderEnum.MALE);
                  }}
                />
                <label className="form-check-label" htmlFor="inlineRadio1">
                  {t("male")}
                </label>
              </div>
              <div className="form-check form-check-inline">
                <Input
                  className="form-check-input"
                  type="radio"
                  name="inlineRadioOptions"
                  id="inlineRadio2"
                  value={GenderEnum.FEMALE}
                  checked={form.values.gender === GenderEnum.FEMALE}
                  onChange={() => {
                    form.setFieldValue("gender", GenderEnum.FEMALE);
                  }}
                />
                <label className="form-check-label" htmlFor="inlineRadio2">
                  {t("female")}
                </label>
              </div>
            </div>
          </Col>
          <Col lg={12}>
            <div className="hstack gap-2 justify-content-end">
              <ActivityButton
                title="save"
                inProgressTitle="saving"
                color="success"
                className="btn btn-success"
                type="submit"
                inProgress={isLoadingGetUserProfile || isLoadingGetUserProfile}
                disabled={isLoadingGetUserProfile || isLoadingGetUserProfile}
              ></ActivityButton>
            </div>
          </Col>
        </Row>
      </Form>
    </React.Fragment>
  );
};
