import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { persistReducer, persistStore, Storage } from "redux-persist";
import { MMKV } from "react-native-mmkv";

import auth from "./auth";
import theme from "./theme";
import account from "./account";
import {
  accountApi,
  appSettingsApi,
  authApi,
  calendarApi,
  bannerApi,
  userApi,
  notificationApi,
  itemApi,
  contentApi,
  complaintApi,
  menuApi,
  prophetApi,
} from "../services";

const reducers = combineReducers({
  theme,
  auth,
  account,
  // Add API's Here
  [accountApi.reducerPath]: accountApi.reducer,
  [authApi.reducerPath]: authApi.reducer,
  [appSettingsApi.reducerPath]: appSettingsApi.reducer,
  [calendarApi.reducerPath]: calendarApi.reducer,
  [bannerApi.reducerPath]: bannerApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [notificationApi.reducerPath]: notificationApi.reducer,
  [itemApi.reducerPath]: itemApi.reducer,
  [contentApi.reducerPath]: contentApi.reducer,
  [complaintApi.reducerPath]: complaintApi.reducer,
  [menuApi.reducerPath]: menuApi.reducer,
  [prophetApi.reducerPath]: prophetApi.reducer,
});

const storage = new MMKV();
export const reduxStorage: Storage = {
  setItem: (key, value) => {
    storage.set(key, value);
    return Promise.resolve(true);
  },
  getItem: (key) => {
    const value = storage.getString(key);
    return Promise.resolve(value);
  },
  removeItem: (key) => {
    storage.delete(key);
    return Promise.resolve();
  },
};

const persistConfig = {
  key: "root",
  storage: reduxStorage,
  whitelist: ["theme", "auth"],
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
    const middlewares = getDefaultMiddleware({
      serializableCheck: false,
    })
      // Add API's Here
      .concat([
        authApi.middleware,
        accountApi.middleware,
        appSettingsApi.middleware,
        calendarApi.middleware,
        bannerApi.middleware,
        userApi.middleware,
        notificationApi.middleware,
        itemApi.middleware,
        contentApi.middleware,
        complaintApi.middleware,
        menuApi.middleware,
        prophetApi.middleware,
      ]);

    return middlewares;
  },
});

const persistor = persistStore(store);

setupListeners(store.dispatch);

export { store, persistor, storage };
export type RootState = ReturnType<typeof store.getState>;
