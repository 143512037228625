import { useSelector } from "react-redux";
import { useEffect, useMemo } from "react";
import { Redirect, withRouter } from "react-router-dom";

import { useAuth } from "../../components/hooks";

const NonAuthLayoutComponent = ({ children }: any) => {
  const {
    auth: { token },
  } = useAuth();

  const layoutModeType = useSelector(
    (state: any) => state.theme.layoutModeType
  );

  const layoutMode = useMemo(() => {
    return layoutModeType === "dark" ? "dark" : "light";
  }, [layoutModeType]);

  useEffect(() => {
    document.body.setAttribute("data-layout-mode", layoutMode);
    return () => {
      document.body.removeAttribute("data-layout-mode");
    };
  }, [layoutMode]);

  if (token) {
    return <Redirect to={{ pathname: "/" }} />;
  } else return <div>{children}</div>;
};

export const NonAuthLayout = withRouter(NonAuthLayoutComponent);
