import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";

//import Components
import { Footer, Header } from ".";

//redux
import { useDispatch, useSelector } from "react-redux";
import Sidebar from "./Sidebar";
import RightSidebar from "../components/Common/RightSidebar";
import {
  changeLeftsidebarViewType,
  changeLeftsidebarSizeType,
  changeSidebarTheme,
  changeLayoutMode,
  changeLayoutWidth,
  changeLayoutPosition,
  changeTopbarTheme,
  changeLayout,
  changeSidebarImageType,
} from "../store/theme";

const Layout = (props: any) => {
  const [headerClass, setHeaderClass] = useState("");
  const dispatch = useDispatch();
  const {
    layoutType,
    leftSidebarType,
    layoutModeType,
    layoutWidthType,
    layoutPositionType,
    topbarThemeType,
    leftsidbarSizeType,
    leftSidebarViewType,
    leftSidebarImageType,
  } = useSelector((state: any) => ({
    layoutType: state.theme.layoutType,
    leftSidebarType: state.theme.leftSidebarType,
    layoutModeType: state.theme.layoutModeType,
    layoutWidthType: state.theme.layoutWidthType,
    layoutPositionType: state.theme.layoutPositionType,
    topbarThemeType: state.theme.topbarThemeType,
    leftsidbarSizeType: state.theme.leftsidbarSizeType,
    leftSidebarViewType: state.theme.leftSidebarViewType,
    leftSidebarImageType: state.theme.leftSidebarImageType,
  }));

  /*
    layout settings
    */
  useEffect(() => {
    if (
      layoutType ||
      leftSidebarType ||
      layoutModeType ||
      layoutWidthType ||
      layoutPositionType ||
      topbarThemeType ||
      leftsidbarSizeType ||
      leftSidebarViewType ||
      leftSidebarImageType
    ) {
      dispatch(changeLeftsidebarViewType({ leftSidebarViewType }));
      dispatch(changeLeftsidebarSizeType({ leftsidbarSizeType }));
      dispatch(changeSidebarTheme({ leftSidebarType }));
      dispatch(changeLayoutMode({ layoutModeType }));
      dispatch(changeLayoutWidth({ layoutWidthType }));
      dispatch(changeLayoutPosition({ layoutPositionType }));
      dispatch(changeTopbarTheme({ topbarThemeType }));
      dispatch(changeLayout({ layoutType }));
      dispatch(changeSidebarImageType({ leftSidebarImageType }));
    }
  }, [
    layoutType,
    leftSidebarType,
    layoutModeType,
    layoutWidthType,
    layoutPositionType,
    topbarThemeType,
    leftsidbarSizeType,
    leftSidebarViewType,
    leftSidebarImageType,
    dispatch,
  ]);
  /*
    call dark/light mode
    */
  const onChangeLayoutMode = (value: any) => {
    if (changeLayoutMode) {
      dispatch(changeLayoutMode({ layoutModeType: value }));
    }
  };

  // class add remove in header
  useEffect(() => {
    window.addEventListener("scroll", scrollNavigation, true);
  });

  function scrollNavigation() {
    var scrollup = document.documentElement.scrollTop;
    if (scrollup > 50) {
      setHeaderClass("topbar-shadow");
    } else {
      setHeaderClass("");
    }
  }

  return (
    <React.Fragment>
      <div id="layout-wrapper">
        <Header
          headerClass={headerClass}
          layoutModeType={layoutModeType}
          onChangeLayoutMode={onChangeLayoutMode}
        />
        <Sidebar layoutType={layoutType} />
        <div className="main-content">
          {props.children}
          <Footer />
        </div>
      </div>
      <RightSidebar />
    </React.Fragment>
  );
};

export default withRouter(Layout);
