import React from "react";
import { Button } from "reactstrap";

type TProps = {
  currentPage: number;
  forPage: number;
  onClick: () => void;
};
export const PaginationButton: React.FC<TProps> = ({
  currentPage,
  forPage,
  onClick,
}) => {
  return (
    <li className={`page-item ${currentPage == forPage ? "active" : ""}`}>
      <Button
        onClick={onClick}
        className={`page-link btn-success ${
          currentPage == forPage ? "active" : ""
        } ${currentPage == forPage ? "cursor-none" : "cursor-pointer"}`}
      >
        {forPage}
      </Button>
    </li>
  );
};
