import { createApi } from "@reduxjs/toolkit/query/react";

import { Paginated } from "../models";
import { baseQueryWithInterceptor } from "./base.api";
import { convertObjectToQueryParams } from "./helper.service";
import { PaginateQuery, ItemDto, CreateItemRequest } from "../interfaces";
import { UpdateItemRequest } from "../interfaces/item/update-item-request";

export const itemApi = createApi({
  reducerPath: "itemApi",
  baseQuery: baseQueryWithInterceptor,
  endpoints: (builder) => ({
    getPaginatedItems: builder.query<Paginated<ItemDto>, PaginateQuery>({
      query: (query) => ({
        url: `/item/paginate?${convertObjectToQueryParams(query)}`,
        method: "GET",
      }),
      providesTags: ["items"],
    }),
    getItemById: builder.query<ItemDto, string>({
      query: (id) => ({
        url: `/item/${id}`,
        method: "GET",
      }),
    }),
    deleteItem: builder.mutation<void, string>({
      query: (id) => ({
        url: `/item/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["items"],
    }),
    addItem: builder.mutation<void, CreateItemRequest>({
      query: (body) => ({
        url: `/item`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["items"],
    }),
    updateItem: builder.mutation<void, UpdateItemRequest>({
      query: (body) => ({
        url: `/item/${body.id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["items"],
    }),
  }),
  tagTypes: ["items"],
});

export const {
  useLazyGetPaginatedItemsQuery,
  useLazyGetItemByIdQuery,
  useDeleteItemMutation,
  useAddItemMutation,
  useUpdateItemMutation,
} = itemApi;
