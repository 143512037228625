import {
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Languages } from "../../constants/language.constant";
import { InputType } from "reactstrap/types/lib/Input";
import { InputLabelButton } from "./InputLabelButton";
import {
  getByKey,
  getServerError,
  useGenerateTranslationsMutation,
} from "../../services";
import { ErrorHandlingTypeEnum, LanguageEnum } from "../../enums";
import { toast } from "react-toastify";
import i18n from "../../i18n";

type TProps = {
  name: string;
  form: any;
  type: InputType;
  label: string;
  required?: boolean;
  tabIndex: number;
};
/**
 * Localized input
 * @param param0
 * @returns
 */
export const InputLocalized: React.FC<TProps> = ({
  name,
  form,
  type,
  label,
  required = true,
  tabIndex,
}) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);

  //#region api
  const [
    generateTranslations,
    {
      isLoading: isLoadingGenerateTranslations,
      isError: isErrorGenerateTranslations,
      isSuccess: isSuccessGenerateTranslations,
      error: errorGenerateTranslations,
      data: dataGenerateTranslations,
    },
  ] = useGenerateTranslationsMutation();

  //#endregion

  //#region handlers
  const onGenerateTranslation = async () => {
    await generateTranslations({
      text: form.values[name]["ar"],
      textLanguage: LanguageEnum.AR,
    });
  };
  //#endregion

  //#region effects
  useEffect(() => {
    if (!isLoadingGenerateTranslations && isErrorGenerateTranslations) {
      const _parsed = getServerError(errorGenerateTranslations);
      if (_parsed.type === ErrorHandlingTypeEnum.FORM) {
        form.setErrors(_parsed.error);
      }
      if (_parsed.type === ErrorHandlingTypeEnum.ALERT) {
        toast.error(_parsed.error, {
          autoClose: 3000,
        });
      }
    }
    if (
      !isLoadingGenerateTranslations &&
      isSuccessGenerateTranslations &&
      dataGenerateTranslations
    ) {
      toast.success(t("translatedSuccessfully"), {
        autoClose: 3000,
      });
      Object.keys(dataGenerateTranslations).forEach((_key: string) => {
        const _translation = getByKey(dataGenerateTranslations, _key);
        form.setFieldValue(`${name}.${_key}`, _translation);
      });
    }
  }, [isLoadingGenerateTranslations]);
  //#endregion

  return (
    <React.Fragment>
      {Object.keys(Languages)
        .filter((key) => key === "ar")
        .map((key: string) => {
          return (
            <div className="mb-3" key={key}>
              <div className="d-flex justify-content-between align-items-end">
                <Label
                  htmlFor={`${name}.${key}`}
                  className="form-label"
                  style={{
                    float:
                      Languages[key].writingDirection === "ltr"
                        ? "left"
                        : "right",
                  }}
                >
                  {t(label || name)}
                  {required && <span className="text-danger">*</span>}
                </Label>
                <InputLabelButton
                  onClick={() => setShow(true)}
                  title={"addTranslation"}
                  riIconName={"ri-translate-2"}
                  disabled={false}
                  isInProgress={false}
                />
              </div>
              <Input
                autoComplete="false"
                tabIndex={tabIndex}
                id={`${name}.${key}`}
                name={`${name}.${key}`}
                type={type}
                className="form-control"
                placeholder={Languages[key].nameInLanguage[key]}
                value={form.values[name][key]}
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                dir={Languages[key].writingDirection}
                invalid={
                  form.touched &&
                  form.touched[name] &&
                  form.touched[name][key] &&
                  form.errors &&
                  form.errors[name] &&
                  form.errors[name][key]
                    ? true
                    : false
                }
              />
              {form.touched &&
              form.touched[name] &&
              form.touched[name][key] &&
              form.errors &&
              form.errors[name] &&
              form.errors[name][key] &&
              form.errors[name][key] ? (
                <FormFeedback type="invalid">
                  {form.errors[name][key]}
                </FormFeedback>
              ) : null}
            </div>
          );
        })}
      <Modal
        id={name}
        isOpen={show}
        toggle={() => {
          setShow(false);
        }}
        centered
        scrollable={true}
      >
        <ModalHeader className="p-3">
          {t(label)} {t("localization")}
        </ModalHeader>
        <ModalBody>
          <div
            className={`d-flex ${
              i18n.language === "ar"
                ? "justify-content-start"
                : "justify-content-end"
            }`}
          >
            <InputLabelButton
              onClick={() => onGenerateTranslation()}
              title={"generateTranslation"}
              riIconName={"ri-translate-2"}
              disabled={
                isLoadingGenerateTranslations ||
                (form.errors &&
                  form.errors[name] &&
                  form.errors[name]["ar"] &&
                  form.errors[name]["ar"])
              }
              isInProgress={isLoadingGenerateTranslations}
            />
          </div>
          {Object.keys(Languages)
            .filter((key) => key !== "ar")
            .map((key: string) => {
              return (
                <div className="mb-3" key={key}>
                  <Label
                    htmlFor={`${name}.${key}`}
                    className="form-label"
                    style={{
                      float:
                        Languages[key].writingDirection === "ltr"
                          ? "left"
                          : "right",
                    }}
                  >
                    {Languages[key].nameInLanguage[key]}{" "}
                    {required && <span className="text-danger">*</span>}
                  </Label>
                  <Input
                    autoComplete="off"
                    id={`${name}.${key}`}
                    name={`${name}.${key}`}
                    type={type}
                    dir={Languages[key].writingDirection}
                    className="form-control"
                    placeholder={Languages[key].nameInLanguage[key]}
                    value={form.values[name][key]}
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                    invalid={
                      form.touched &&
                      form.touched[name] &&
                      form.touched[name][key] &&
                      form.errors &&
                      form.errors[name] &&
                      form.errors[name][key]
                        ? true
                        : false
                    }
                  />
                  {form.touched &&
                  form.touched[name] &&
                  form.touched[name][key] &&
                  form.errors &&
                  form.errors[name] &&
                  form.errors[name][key] &&
                  form.errors[name][key] ? (
                    <FormFeedback type="invalid">
                      {form.errors[name][key]}
                    </FormFeedback>
                  ) : null}
                </div>
              );
            })}
        </ModalBody>
        <ModalFooter>
          <Link
            to={"#"}
            onClick={() => setShow(false)}
            className="btn btn-success fw-medium"
          >
            {t("close")}
          </Link>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};
