import * as Yup from "yup";
import { t } from "i18next";

import us from "../assets/images/flags/us.svg";
import kw from "../assets/images/flags/kw.svg";

export class Language {
  name: string;
  code: string;
  shortName: string;
  nameInLanguage: {
    [key: string]: string;
  };
  writingDirection: string;
  required: boolean;
  flag: string;
  constructor(
    name: string,
    code: string,
    shortName: string,
    nameInLanguage: any,
    writingDirection: string,
    required: boolean = false,
    flag: string
  ) {
    this.name = name;
    this.code = code;
    this.shortName = shortName;
    this.nameInLanguage = nameInLanguage;
    this.writingDirection = writingDirection;
    this.required = required;
    this.flag = flag;
  }
}

interface IObjectKeys {
  [key: string]: any;
}

export const Languages: IObjectKeys = {
  en: new Language(
    "English",
    "en",
    "Eng",
    {
      en: "English",
      ar: "الإنجليزية",
      id: "Inggris",
      ur: "انگریزی",
      ru: "английский",
      zh: "英语",
      tr: "İngilizce",
      de: "Englisch",
      es: "Inglés",
      fr: "Anglais",
    },
    "ltr",
    true,
    us
  ),
  ar: new Language(
    "Arabic",
    "ar",
    "Ara",
    {
      en: "Arabic",
      ar: "العربية",
      id: "Arab",
      ur: "عربی",
      ru: "арабский",
      zh: "阿拉伯语",
      tr: "Arapça",
      de: "Arabisch",
      es: "Árabe",
      fr: "Arabe",
    },
    "rtl",
    false,
    kw
  ),
  id: new Language(
    "Indonesian",
    "id",
    "Ind",
    {
      en: "Indonesian",
      ar: "الإندونيسية",
      id: "Indonesia",
      ur: "انڈونیشیا",
      ru: "индонезийский",
      zh: "印尼语",
      tr: "Endonezya",
      de: "Indonesisch",
      es: "Indonesio",
      fr: "Indonésien",
    },
    "ltr",
    false,
    ""
  ),
  ur: new Language(
    "Urdu",
    "ur",
    "Urd",
    {
      en: "Urdu",
      ar: "الأردية",
      id: "Urdu",
      ur: "اردو",
      ru: "урду",
      zh: "乌尔都语",
      tr: "Urduca",
      de: "Urdu",
      es: "Urdu",
      fr: "Ourdou",
    },
    "rtl",
    false,
    ""
  ),
  ru: new Language(
    "Russian",
    "ru",
    "Rus",
    {
      en: "Russian",
      ar: "الروسية",
      id: "Rusia",
      ur: "روسی",
      ru: "русский",
      zh: "俄语",
      tr: "Rusça",
      de: "Russisch",
      es: "Ruso",
      fr: "Russe",
    },
    "ltr",
    false,
    ""
  ),
  zh: new Language(
    "Mandarin",
    "zh",
    "Man",
    {
      en: "Mandarin",
      ar: "الصينية المندرينية",
      id: "Mandarin",
      ur: "مانڈرن",
      ru: "мандарин",
      zh: "普通话",
      tr: "Mandarin",
      de: "Mandarin",
      es: "Mandarín",
      fr: "Mandarin",
    },
    "ltr",
    false,
    ""
  ),
  tr: new Language(
    "Turkish",
    "tr",
    "Tur",
    {
      en: "Turkish",
      ar: "التركية",
      id: "Turki",
      ur: "ترکی",
      ru: "турецкий",
      zh: "土耳其语",
      tr: "Türkçe",
      de: "Türkisch",
      es: "Turco",
      fr: "Turc",
    },
    "ltr",
    false,
    ""
  ),
  de: new Language(
    "German",
    "de",
    "Ger",
    {
      en: "German",
      ar: "الألمانية",
      id: "Jerman",
      ur: "جرمن",
      ru: "немецкий",
      zh: "德语",
      tr: "Almanca",
      de: "Deutsch",
      es: "Alemán",
      fr: "Allemand",
    },
    "ltr",
    false,
    ""
  ),
  es: new Language(
    "Spanish",
    "es",
    "Spa",
    {
      en: "Spanish",
      ar: "الإسبانية",
      id: "Spanyol",
      ur: "ہسپانوی",
      ru: "испанский",
      zh: "西班牙语",
      tr: "İspanyolca",
      de: "Spanisch",
      es: "Español",
      fr: "Espagnol",
    },
    "ltr",
    false,
    ""
  ),
  fr: new Language(
    "French",
    "fr",
    "Fre",
    {
      en: "French",
      ar: "الفرنسية",
      id: "Perancis",
      ur: "فرانسیسی",
      ru: "французский",
      zh: "法语",
      tr: "Fransızca",
      de: "Französisch",
      es: "Francés",
      fr: "Français",
    },
    "ltr",
    false,
    ""
  ),
};

export const LocalizedPropertyYupSchema = (
  displayName: string,
  maxLength?: number | null,
  required: boolean = true
) => {
  if (maxLength) {
    if (required === true) {
      return Yup.object({
        en: Yup.string()
          .required(t("isNotEmpty", { displayName }))
          .max(maxLength, t("maxLength", { maxLength, displayName })),
        ar: Yup.string()
          .required(t("isNotEmpty", { displayName }))
          .max(maxLength, t("maxLength", { maxLength, displayName }))
          .required(t("isNotEmpty", { displayName })),
        id: Yup.string()
          .max(maxLength, t("maxLength", { maxLength, displayName }))
          .required(t("isNotEmpty", { displayName })),
        ur: Yup.string()
          .max(maxLength, t("maxLength", { maxLength, displayName }))
          .required(t("isNotEmpty", { displayName })),
        ru: Yup.string()
          .max(maxLength, t("maxLength", { maxLength, displayName }))
          .required(t("isNotEmpty", { displayName })),
        zh: Yup.string()
          .max(maxLength, t("maxLength", { maxLength, displayName }))
          .required(t("isNotEmpty", { displayName })),
        tr: Yup.string()
          .max(maxLength, t("maxLength", { maxLength, displayName }))
          .required(t("isNotEmpty", { displayName })),
        de: Yup.string()
          .max(maxLength, t("maxLength", { maxLength, displayName }))
          .required(t("isNotEmpty", { displayName })),
        es: Yup.string()
          .max(maxLength, t("maxLength", { maxLength, displayName }))
          .required(t("isNotEmpty", { displayName })),
        fr: Yup.string()
          .max(maxLength, t("maxLength", { maxLength, displayName }))
          .required(t("isNotEmpty", { displayName })),
      });
    } else {
      return Yup.object({
        en: Yup.string()
          .max(maxLength, t("maxLength", { maxLength, displayName }))
          .nullable(),
        ar: Yup.string()
          .max(maxLength, t("maxLength", { maxLength, displayName }))
          .nullable(),
        id: Yup.string()
          .max(maxLength, t("maxLength", { maxLength, displayName }))
          .nullable(),
        ur: Yup.string()
          .max(maxLength, t("maxLength", { maxLength, displayName }))
          .nullable(),
        ru: Yup.string()
          .max(maxLength, t("maxLength", { maxLength, displayName }))
          .nullable(),
        zh: Yup.string()
          .max(maxLength, t("maxLength", { maxLength, displayName }))
          .nullable(),
        tr: Yup.string()
          .max(maxLength, t("maxLength", { maxLength, displayName }))
          .nullable(),
        de: Yup.string()
          .max(maxLength, t("maxLength", { maxLength, displayName }))
          .nullable(),
        es: Yup.string()
          .max(maxLength, t("maxLength", { maxLength, displayName }))
          .nullable(),
        fr: Yup.string()
          .max(maxLength, t("maxLength", { maxLength, displayName }))
          .nullable(),
      });
    }
  } else {
    if (required === true) {
      return Yup.object({
        en: Yup.string().required(t("isNotEmpty", { displayName })),
        ar: Yup.string().required(t("isNotEmpty", { displayName })),
        id: Yup.string().required(t("isNotEmpty", { displayName })),
        ur: Yup.string().required(t("isNotEmpty", { displayName })),
        ru: Yup.string().required(t("isNotEmpty", { displayName })),
        zh: Yup.string().required(t("isNotEmpty", { displayName })),
        tr: Yup.string().required(t("isNotEmpty", { displayName })),
        de: Yup.string().required(t("isNotEmpty", { displayName })),
        es: Yup.string().required(t("isNotEmpty", { displayName })),
        fr: Yup.string().required(t("isNotEmpty", { displayName })),
      });
    } else {
      return Yup.object({
        en: Yup.string().nullable(),
        ar: Yup.string().nullable(),
        id: Yup.string().nullable(),
        ur: Yup.string().nullable(),
        ru: Yup.string().nullable(),
        zh: Yup.string().nullable(),
        tr: Yup.string().nullable(),
        de: Yup.string().nullable(),
        es: Yup.string().nullable(),
        fr: Yup.string().nullable(),
      });
    }
  }
};

export const LocalizedPropertyInitialErrors = {
  en: "",
  ar: "",
  de: "",
  es: "",
  fr: "",
  id: "",
  ru: "",
  tr: "",
  ur: "",
  zh: "",
};

export const LocalizedPropertyInitialValues = {
  en: "",
  ar: "",
  de: "",
  es: "",
  fr: "",
  id: "",
  ru: "",
  tr: "",
  ur: "",
  zh: "",
};

export const LocalizedPropertyInitialTouched = {
  en: false,
  ar: false,
  de: false,
  es: false,
  fr: false,
  id: false,
  ru: false,
  tr: false,
  ur: false,
  zh: false,
};
