import React from "react";
import { useTranslation } from "react-i18next";

import { useGetPrivacyPolicyPublicQuery } from "../../services";
import LanguageDropdown from "../../components/Common/LanguageDropdown";

export const PrivacyPolicyPublicPage = () => {
  //#region hooks usage
  const { t, i18n } = useTranslation();
  //#endregion

  //#region others
  document.title = `${t("documentTitle", {
    pageName: "privacyPolicy",
  })}`;
  //#endregion

  //#region api
  const { isSuccess: isSuccessGetPrivacyPolicy, data: dataGetPrivacyPolicy } =
    useGetPrivacyPolicyPublicQuery(null);

  //#endregion

  //#region handlers
  function createMarkup() {
    if (i18n.language === "ar")
      return { __html: dataGetPrivacyPolicy?.value.ar };
    else return { __html: dataGetPrivacyPolicy?.value?.en };
  }
  //#endregion

  return (
    <React.Fragment>
      <div className="container-fluid mt-3">
        {isSuccessGetPrivacyPolicy &&
          dataGetPrivacyPolicy &&
          dataGetPrivacyPolicy.value && (
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <div className="card">
                  <div className="bg-soft-warning position-relative">
                    <div className="card-body p-5">
                      <div className="text-center">
                        <h3>IslamGPT</h3>
                      </div>
                      <div
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                        }}
                      >
                        <LanguageDropdown />
                      </div>
                    </div>
                    <div className="shape"></div>
                  </div>
                  <div className="card-body p-4">
                    <div className="d-flex">
                      <p dangerouslySetInnerHTML={createMarkup() as any}></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
      </div>
    </React.Fragment>
  );
};
