import { createApi } from "@reduxjs/toolkit/query/react";

import { baseQueryWithInterceptor } from "./base.api";
import { CreateNotificationRequest } from "../interfaces";

export const notificationApi = createApi({
  reducerPath: "notificationApi",
  baseQuery: baseQueryWithInterceptor,
  endpoints: (builder) => ({
    createNotification: builder.mutation<void, CreateNotificationRequest>({
      query: (body) => ({
        url: `/notification`,
        method: "POST",
        body,
      }),
    }),
  }),
  tagTypes: [],
});

export const { useCreateNotificationMutation } = notificationApi;
