import { createApi } from "@reduxjs/toolkit/query/react";

import { Paginated, SelectListDto } from "../models";
import { baseQueryWithInterceptor } from "./base.api";
import { convertObjectToQueryParams } from "./helper.service";
import {
  PaginateQuery,
  ProphetDto,
  CreateProphetRequest,
  UpdateProphetRequest,
} from "../interfaces";

export const prophetApi = createApi({
  reducerPath: "prophetApi",
  baseQuery: baseQueryWithInterceptor,
  endpoints: (builder) => ({
    getPaginatedProphet: builder.query<Paginated<ProphetDto>, PaginateQuery>({
      query: (query) => ({
        url: `/prophet/paginate?${convertObjectToQueryParams(query)}`,
        method: "GET",
      }),
      providesTags: ["prophets"],
    }),
    getProphetById: builder.query<ProphetDto, string>({
      query: (id) => ({
        url: `/prophet/${id}`,
        method: "GET",
      }),
    }),
    deleteProphet: builder.mutation<void, string>({
      query: (id) => ({
        url: `/prophet/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["prophets"],
    }),
    addProphet: builder.mutation<void, CreateProphetRequest>({
      query: (body) => ({
        url: `/prophet`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["prophets"],
    }),
    updateProphet: builder.mutation<void, UpdateProphetRequest>({
      query: (body) => ({
        url: `/prophet/${body.id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["prophets"],
    }),
    getProphetSelectList: builder.query<SelectListDto[], void>({
      query: () => ({
        url: `/prophet/select-list/all`,
        method: "GET",
      }),
      providesTags: ["prophets-select-list"],
    }),
  }),
  tagTypes: ["prophets", "prophets-select-list"],
});

export const {
  useLazyGetPaginatedProphetQuery,
  useLazyGetProphetByIdQuery,
  useDeleteProphetMutation,
  useAddProphetMutation,
  useUpdateProphetMutation,
  useLazyGetProphetSelectListQuery,
} = prophetApi;
