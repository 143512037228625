import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  FormFeedback,
  Input,
  Label,
  Form,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { Link, useHistory, useParams } from "react-router-dom";

import logo from "../../assets/images/logo.png";
import { ParticlesAuth } from "../authInner";
import { ActivityButton } from "../../components/Common";
import { ResetPasswordRequest } from "../../interfaces";
import LanguageDropdown from "../../components/Common/LanguageDropdown";
import {
  EncryptionService,
  getServerError,
  useResetPasswordMutation,
} from "../../services";
import { ToastContainer, toast } from "react-toastify";
import { ErrorHandlingTypeEnum, VerificationMethodEnum } from "../../enums";

export const ResetPasswordPage = () => {
  //#region hooks usage
  const history = useHistory();
  const { t } = useTranslation();
  //#endregion

  //#region others
  document.title = `${t("documentTitle", { pageName: "resetPassword" })}`;
  //#endregion

  //#region internal states
  const { token: encToken } = useParams<any>();
  const [resetPasswordRequest, setResetPasswordRequest] =
    useState<ResetPasswordRequest>({
      password: "",
      email: "",
      method: VerificationMethodEnum.EMAIL,
    });

  //#endregion

  //#region api
  const [
    resetPassword,
    {
      isError: isErrorResetPassword,
      isLoading: isLoadingResetPassword,
      error: errorResetPassword,
      isSuccess: isSuccessResetPassword,
    },
  ] = useResetPasswordMutation();

  //#endregion

  //#region form
  const form = useFormik({
    enableReinitialize: true,
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .min(8, t("minLength", { displayName: "password", minLength: 8 }))
        .max(50, t("maxLength", { displayName: "password", maxLength: 50 }))
        .required(
          t("isNotEmpty", {
            displayName: "password",
          })
        ),
      confirmPassword: Yup.string()
        .min(
          8,
          t("minLength", { displayName: "confirmPassword", minLength: 8 })
        )
        .max(
          50,
          t("maxLength", { displayName: "confirmPassword", maxLength: 50 })
        )
        .oneOf([Yup.ref("password")], t("passwordConfirmPasswordMustMatch"))
        .required(
          t("isNotEmpty", {
            displayName: "confirmPassword",
          })
        ),
    }),

    onSubmit: async (values) => {
      await resetPassword({
        ...resetPasswordRequest,
        password: values.password,
      });
    },
  });
  //#endregion

  //#region effects
  useEffect(() => {
    if (encToken) {
      try {
        const token: string = EncryptionService.decrypt(encToken);
        const tokenArray: string[] = token.split(":");
        if (tokenArray && tokenArray.length === 2) {
          setResetPasswordRequest({
            ...resetPasswordRequest,
            email: tokenArray[1],
            token: encToken,
            password: "",
          });
        }
      } catch (error) {
        history.goBack();
      }
    }
  }, [encToken]);

  useEffect(() => {
    if (!isLoadingResetPassword && isErrorResetPassword) {
      const _parsed = getServerError(errorResetPassword);
      if (_parsed.type === ErrorHandlingTypeEnum.FORM) {
        form.setErrors(_parsed.error);
      }
      if (_parsed.type === ErrorHandlingTypeEnum.ALERT) {
        toast.error(_parsed.error, {
          autoClose: 3000,
        });
      }
    }
    if (!isLoadingResetPassword && isSuccessResetPassword) {
      history.replace(`/login`);
    }
  }, [isLoadingResetPassword]);

  //#endregion

  return (
    <React.Fragment>
      <ToastContainer newestOnTop={true} />
      <ParticlesAuth>
        <div className="auth-page-content">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="mt-4">
                  <CardBody className="p-4">
                    <div className="text-center mt-1">
                      <img src={logo} alt="" height="100" width="100" />
                      <h5 className="text-primary mt-2">
                        {t("resetYourPassword")}
                      </h5>
                      <p className="text-muted">
                        {t("resetYourPasswordSubHeading")}
                      </p>
                    </div>
                    <div className="mt-4">
                      <div className="">
                        <div className="justify-content-center align-items-center d-flex">
                          <LanguageDropdown />
                        </div>
                        <Form
                          onSubmit={(e) => {
                            e.preventDefault();
                            form.handleSubmit();
                            return false;
                          }}
                        >
                          <div className="mb-3">
                            <Label className="form-label" htmlFor="email">
                              {t("email")}
                            </Label>
                            <div className="position-relative auth-pass-inputgroup mb-3">
                              <Input
                                autoComplete="off"
                                id="email"
                                name="email"
                                type="email"
                                value={resetPasswordRequest.email}
                                className="form-control"
                                disabled
                                readOnly
                              />
                            </div>
                          </div>
                          <div className="mb-3">
                            <Label
                              className="form-label"
                              htmlFor="password-input"
                            >
                              {t("password")}
                            </Label>
                            <span className="text-danger">*</span>
                            <div className="position-relative auth-pass-inputgroup mb-3">
                              <Input
                                autoComplete="off"
                                id="password-input"
                                name="password"
                                type="password"
                                value={form.values.password || ""}
                                className="form-control"
                                placeholder={t("enterPassword")}
                                onChange={form.handleChange}
                                onBlur={form.handleBlur}
                                invalid={
                                  form.touched.password && form.errors.password
                                    ? true
                                    : false
                                }
                              />
                              {form.touched.password && form.errors.password ? (
                                <FormFeedback type="invalid">
                                  {form.errors.password as string}
                                </FormFeedback>
                              ) : null}
                              <button
                                className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                                type="button"
                                id="password-addon"
                              ></button>
                            </div>
                          </div>
                          <div className="mb-3">
                            <Label
                              className="form-label"
                              htmlFor="password-input"
                            >
                              {t("confirmPassword")}
                            </Label>
                            <span className="text-danger">*</span>
                            <div className="position-relative auth-pass-inputgroup mb-3">
                              <Input
                                autoComplete="off"
                                id="password-input"
                                name="confirmPassword"
                                type="password"
                                value={form.values.confirmPassword || ""}
                                className="form-control"
                                placeholder={t("enterConfirmPassword")}
                                onChange={form.handleChange}
                                onBlur={form.handleBlur}
                                invalid={
                                  form.touched.confirmPassword &&
                                  form.errors.confirmPassword
                                    ? true
                                    : false
                                }
                              />
                              {form.touched.confirmPassword &&
                              form.errors.confirmPassword ? (
                                <FormFeedback type="invalid">
                                  {form.errors.confirmPassword as string}
                                </FormFeedback>
                              ) : null}
                              <button
                                className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                                type="button"
                                id="password-addon"
                              ></button>
                            </div>
                          </div>
                          <div className="mt-4">
                            <ActivityButton
                              title="changePassword"
                              inProgressTitle="sending"
                              color="success"
                              className="btn btn-success w-100"
                              type="submit"
                              inProgress={isLoadingResetPassword}
                              disabled={isLoadingResetPassword}
                            ></ActivityButton>
                          </div>
                        </Form>
                      </div>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-4 text-center">
                  <p className="mb-0 text-white">
                    {t("backToLogin")}...{" "}
                    <Link
                      to="/login"
                      className="fw-semibold text-white text-decoration-underline"
                    >
                      {" "}
                      {t("clickHere")}{" "}
                    </Link>{" "}
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </ParticlesAuth>
    </React.Fragment>
  );
};
