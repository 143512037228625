import { Redirect, Route } from "react-router-dom";

import { useAuth } from "../components/hooks";

export const AuthProtected = (props: any) => {
  const {
    auth: { token },
  } = useAuth();

  if (!token) {
    return (
      <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
    );
  }

  return <>{props.children}</>;
};

export const AccessRoute = ({ component: Component, ...rest }: any) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        return <Component {...props} />;
      }}
    />
  );
};
