import { ToastContainer, toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { DebounceInput } from "react-debounce-input";
import { TableColumn } from "react-data-table-component";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Input,
  Label,
} from "reactstrap";

import { ItemDto } from "../../../interfaces";
import { PaginateQuery } from "../../../models";
import { BreadCrumb } from "../../../components/Common";
import {
  ActionTypeEnum,
  ErrorHandlingTypeEnum,
  ItemTypeEnum,
  MeasuringUnitEnum,
} from "../../../enums";
import {
  ColumnHeader,
  TextColumn,
  DateColumn,
  ActionButton,
  AppTable,
  TableSpinner,
} from "../../../components";

import {
  getServerError,
  useDeleteItemMutation,
  useLazyGetPaginatedItemsQuery,
} from "../../../services";
// import { AddEditAdminUserModal } from "./AddEditAdminUserModal";
import DeleteModal from "../../../components/Common/DeleteModal";
import { AddEditZakatModal } from "./AddEditZakatModal";

type TProps = {};
export const ZakatPage: React.FC<TProps> = () => {
  //#region hooks usage
  const history = useHistory();
  const { t, i18n } = useTranslation();
  //#endregion

  //#region others
  document.title = `${t("documentTitle", { pageName: "zakat" })}`;
  //#endregion

  //#region api
  const [
    getPaginatedItems,
    {
      isError: isErrorGetPaginatedItems,
      error: errorGetPaginatedItems,
      isFetching: isFetchingGetPaginatedItems,
      data: dataGetPaginatedItems,
    },
  ] = useLazyGetPaginatedItemsQuery();

  const [
    deleteItem,
    {
      isLoading: isLoadingDeleteItem,
      isError: isErrorDeleteItem,
      isSuccess: isSuccessDeleteItem,
      error: errorDeleteItem,
    },
  ] = useDeleteItemMutation();

  //#endregion

  //#region internal state
  const initState = new PaginateQuery();
  const [paginateQuery, setPaginateQuery] = useState<PaginateQuery>(initState);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [deletingItemId, setDeletingItemId] = useState<string | null>(null);
  const [editingItemId, setEditingItemId] = useState<string | null>(null);
  const [defaultSortAsc, setDefaultSortAsc] = useState(false);
  const [defaultPage, setDefaultPage] = useState(1);
  const [filterAnnualZakat, setFilterAnnualZakat] = useState<boolean>(false);
  const [filterFitrZakat, setFilterFitrZakat] = useState<boolean>(false);

  //#endregion

  //#region Table Columns
  const columns: TableColumn<ItemDto>[] = [
    {
      name: <ColumnHeader title="title" />,
      cell: (row) => (
        <TextColumn
          text={row.title[i18n.language === "ar" ? "ar" : "en"] as string}
        />
      ),
      sortable: true,
    },
    {
      name: <ColumnHeader title="percentage" />,
      cell: (row) => <TextColumn text={row.percentage + ""} />,
      sortable: true,
    },
    {
      name: <ColumnHeader title="type" />,
      cell: (row) => (
        <TextColumn text={row.types.map((v) => t(v)).join(", ")} />
      ),
      sortable: true,
    },
    {
      name: <ColumnHeader title="measuringUnit" />,
      cell: (row) => (
        <TextColumn
          text={MeasuringUnitEnum[row.measuringUnit]}
          capitalize={false}
        />
      ),
      sortable: true,
    },
    {
      name: <ColumnHeader title="createdAt" />,
      cell: (row) => <DateColumn date={row.createdAt} />,
      sortable: true,
    },
    {
      name: <ColumnHeader title="action" />,
      sortable: false,
      width: "120px",
      cell: (row) => (
        <React.Fragment>
          <ActionButton
            onAction={() => onEdit(row.id)}
            actionType={ActionTypeEnum.EDIT}
          />
          &nbsp;
          <ActionButton
            onAction={() => onDelete(row.id)}
            actionType={ActionTypeEnum.DELETE}
          />
        </React.Fragment>
      ),
    },
  ];

  //#endregion

  //#region effects

  useEffect(() => {
    const queryParams = new URLSearchParams(history.location.search);
    let query: PaginateQuery = {
      limit: queryParams.has("limit")
        ? Number(queryParams.get("limit"))
        : paginateQuery.limit,
      page: queryParams.has("page")
        ? Number(queryParams.get("page"))
        : paginateQuery.page,
      sortBy: queryParams.has("sortBy")
        ? (queryParams.get("sortBy") as string)
        : paginateQuery.sortBy,
      search: queryParams.has("search")
        ? (queryParams.get("search") as string)
        : paginateQuery.search,
      filter: queryParams.has("filter[types]")
        ? {
            ["types"]: queryParams.get("filter[types]") || "",
          }
        : paginateQuery.filter,
    };

    if (
      query.filter &&
      query.filter["types"] &&
      (query.filter["types"] as string).includes(ItemTypeEnum.Annual_Zakat)
    ) {
      setFilterAnnualZakat(true);
    }
    if (
      query.filter &&
      query.filter["types"] &&
      (query.filter["types"] as string).includes(ItemTypeEnum.Fitr_Zakat)
    ) {
      setFilterFitrZakat(true);
    }
    if (
      queryParams.get("sortBy") &&
      queryParams.get("sortBy")?.split(":").length &&
      queryParams.get("sortBy")?.split(":").length == 2
    ) {
      setDefaultSortAsc(
        (queryParams.get("sortBy")?.split(":")[1] as string) === "ASC"
      );
      setDefaultSortFieldId(
        getSortByColumnName(queryParams.get("sortBy")?.split(":")[0] as string)
      );
    } else {
      setDefaultSortAsc(false);
      setDefaultSortFieldId(2);
    }
    setDefaultPage(query.page);
    setPaginateQuery(query);
  }, []);

  useEffect(() => {
    if (paginateQuery !== initState) {
      const { limit, page, search, sortBy, filter } = paginateQuery;
      let query;
      if (page) query = `?page=${page}`;
      if (limit) query += `&limit=${limit}`;
      if (search && search !== "") {
        history.replace({
          search: `&search=${search}`,
        });
      }
      if (sortBy) query += `&sortBy=${sortBy}`;
      if (search) query += `&search=${search}`;
      if (
        filter &&
        Object.keys(filter).length &&
        Object.keys(filter).length > 0
      ) {
        let filters: string = "";
        Object.keys(filter).map((key: string) => {
          if (filter[key]) filters += `&filter[${key}]=${filter[key]}`;
        });
        query += filters;
      }
      history.replace({ search: query });
      getPaginatedItems(paginateQuery);
    }
  }, [paginateQuery]);

  useEffect(() => {
    if (!isFetchingGetPaginatedItems && isErrorGetPaginatedItems) {
      const _parsed = getServerError(errorGetPaginatedItems);
      if (_parsed.type === ErrorHandlingTypeEnum.ALERT) {
        toast.error(_parsed.error, {
          autoClose: 3000,
        });
      }
    }
  }, [isFetchingGetPaginatedItems, isErrorGetPaginatedItems]);

  useEffect(() => {
    if (!isLoadingDeleteItem && isErrorDeleteItem) {
      const _parsed = getServerError(errorDeleteItem);
      if (_parsed.type === ErrorHandlingTypeEnum.ALERT) {
        toast.error(_parsed.error, {
          autoClose: 3000,
        });
      }
    }
    if (!isLoadingDeleteItem && isSuccessDeleteItem) {
      setShowDeleteModal(false);
      toast.success(t("deletedSuccessfully"), { autoClose: 3000 });
    }
  }, [isLoadingDeleteItem, isErrorDeleteItem, isSuccessDeleteItem]);

  //#endregion

  //#region handlers

  const getSortByColumnName = (name: string) => {
    let sortBy;
    switch (name) {
      case "title.en":
      case "title.ar":
        sortBy = 1;
        break;
      case "percentage":
        sortBy = 2;
        break;
      case "types":
        sortBy = 3;
        break;
      case "measuring_unit":
        sortBy = 4;
        break;
      case "created_at":
        sortBy = 5;
        break;
      default:
        sortBy = 5;
        break;
    }
    return sortBy;
  };

  const onAdd = () => {
    setShowAddEditModal(true);
  };

  const onEdit = (id: string) => {
    setEditingItemId(id);
    setShowAddEditModal(true);
  };

  const onDelete = (id: string) => {
    setDeletingItemId(id);
    setShowDeleteModal(true);
  };

  const onDeleteConfirm = async () => {
    await deleteItem(deletingItemId as string);
    setDeletingItemId(null);
  };

  const onSearch = (searchText: string) => {
    setPaginateQuery({ ...paginateQuery, search: searchText });
  };

  const getSortByColumnId = (columnId: number, sortDirection: string) => {
    let sortBy;
    switch (columnId) {
      case 1:
        sortBy = "title" + "." + i18n.language;
        break;
      case 2:
        sortBy = "percentage";
        break;
      case 3:
        sortBy = "types";
        break;
      case 4:
        sortBy = "measuring_unit";
        break;
      case 5:
        sortBy = "created_at";
        break;
      default:
        sortBy = "created_at";
        break;
    }
    return `${sortBy}:${sortDirection.toUpperCase()}`;
  };

  const onChangePage = (page: number) => {
    setPaginateQuery({ ...paginateQuery, page: page });
  };

  const onChangeRowsPerPage = (
    currentRowsPerPage: number,
    currentPage: number
  ) => {
    setPaginateQuery({
      ...paginateQuery,
      limit: currentRowsPerPage,
      page: currentPage,
    });
  };

  const onSort = (column: TableColumn<ItemDto>, sortDirection: string) => {
    const sortBy = getSortByColumnId(column.id as number, sortDirection);
    setPaginateQuery({
      ...paginateQuery,
      sortBy: sortBy as string,
    });
  };

  const onChangeAnnualZakat = (event: any) => {
    const {
      target: { checked },
    } = event;
    setFilterAnnualZakat(checked);
    if (checked) {
      let typeFilter;
      if (filterFitrZakat === true) {
        typeFilter = [ItemTypeEnum.Annual_Zakat, ItemTypeEnum.Fitr_Zakat].join(
          ","
        );
      } else {
        typeFilter = ItemTypeEnum.Annual_Zakat;
      }
      setPaginateQuery({
        ...paginateQuery,
        page: 1,
        filter: {
          types: typeFilter,
        },
      });
    } else {
      let typeFilter = "";
      if (filterFitrZakat === true) {
        typeFilter = ItemTypeEnum.Fitr_Zakat;
      }
      setPaginateQuery({
        ...paginateQuery,
        page: 1,
        filter: {
          types: typeFilter,
        },
      });
    }
  };

  const onChangeFitrZakat = (event: any) => {
    const {
      target: { checked },
    } = event;
    setFilterFitrZakat(checked);
    if (checked) {
      let typeFilter;
      if (filterAnnualZakat === true) {
        typeFilter = [ItemTypeEnum.Annual_Zakat, ItemTypeEnum.Fitr_Zakat].join(
          ","
        );
      } else {
        typeFilter = ItemTypeEnum.Fitr_Zakat;
      }
      setPaginateQuery({
        ...paginateQuery,
        page: 1,
        filter: {
          types: typeFilter,
        },
      });
    } else {
      let typeFilter = "";
      if (filterAnnualZakat === true) {
        typeFilter = ItemTypeEnum.Annual_Zakat;
      }
      setPaginateQuery({
        ...paginateQuery,
        page: 1,
        filter: {
          types: typeFilter,
        },
      });
    }
  };

  //#endregion

  const [defaultSortFieldId, setDefaultSortFieldId] = useState(
    getSortByColumnName("created_at:DESC")
  );

  return (
    <React.Fragment>
      <ToastContainer newestOnTop={true} />
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t("zakat")} pageTitle={t("menu")} />
          <Row>
            <Col xxl={12}>
              <Card>
                <CardBody>
                  <Row className="g-2 mb-3">
                    <Col sm={4} className="mb-2">
                      <div className="search-box">
                        <DebounceInput
                          className="form-control"
                          placeholder={`${t("searchPlaceholder")}...`}
                          minLength={2}
                          debounceTimeout={300}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => onSearch(event.target.value)}
                          value={paginateQuery.search}
                        />
                        <i className="ri-search-line search-icon"></i>
                      </div>
                    </Col>
                    <Col className="col-sm-auto d-flex justify-content-center align-items-center">
                      <div className="search-box ms-2 mb-1">
                        <div className="form-check">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            id={ItemTypeEnum.Annual_Zakat}
                            onChange={onChangeAnnualZakat}
                            checked={filterAnnualZakat}
                          />
                          <Label
                            className="form-check-label"
                            htmlFor={ItemTypeEnum.Annual_Zakat}
                          >
                            {t(ItemTypeEnum.Annual_Zakat)}
                          </Label>
                        </div>
                      </div>
                    </Col>
                    <Col className="col-sm-auto d-flex justify-content-center align-items-center">
                      <div className="search-box ms-2 mb-1">
                        <div className="form-check">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            id={ItemTypeEnum.Fitr_Zakat}
                            onChange={onChangeFitrZakat}
                            checked={filterFitrZakat}
                          />
                          <Label
                            className="form-check-label"
                            htmlFor={ItemTypeEnum.Fitr_Zakat}
                          >
                            {t(ItemTypeEnum.Fitr_Zakat)}
                          </Label>
                        </div>
                      </div>
                    </Col>
                    <Col className="col-sm-auto ms-auto">
                      <div className="list-grid-nav hstack gap-1">
                        <Button color="success" onClick={() => onAdd()}>
                          <i className="ri-add-fill me-1 align-bottom"></i>
                          {t("addItem", { item: "item" })}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12}>
                      <AppTable
                        theme={"dark"}
                        columns={columns}
                        data={dataGetPaginatedItems?.data || []}
                        progressPending={isFetchingGetPaginatedItems}
                        progressComponent={<TableSpinner />}
                        pagination
                        paginationServer
                        sortServer
                        onSort={onSort}
                        onChangeRowsPerPage={onChangeRowsPerPage}
                        onChangePage={onChangePage}
                        defaultSortFieldId={defaultSortFieldId}
                        paginationDefaultPage={defaultPage}
                        defaultSortAsc={defaultSortAsc}
                        persistTableHead
                        paginationTotalRows={
                          dataGetPaginatedItems?.meta?.totalItems
                        }
                        paginationComponentOptions={{
                          rowsPerPageText: t("rowsPerPage"),
                          rangeSeparatorText: t("of"),
                        }}
                        fixedHeader={true}
                        fixedHeaderScrollHeight="60vh"
                      />
                    </Col>
                  </Row>
                  <DeleteModal
                    loading={isLoadingDeleteItem}
                    show={showDeleteModal}
                    onDeleteClick={() => onDeleteConfirm()}
                    onCloseClick={() => {
                      setShowDeleteModal(false);
                    }}
                  />
                  <AddEditZakatModal
                    id={editingItemId}
                    show={showAddEditModal}
                    onCloseClick={async () => {
                      setShowAddEditModal(false);
                      setEditingItemId(null);
                    }}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
