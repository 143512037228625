import React, { ChangeEvent, useEffect } from "react";
import { Input } from "reactstrap";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";

import { SelectListDto } from "../../models";
import { ErrorHandlingTypeEnum } from "../../enums";
import {
  getServerError,
  useLazyGetProphetSelectListQuery,
} from "../../services";

type TProps = {
  placeholder: string;
  value: string | number | readonly string[] | undefined;
  onChange: (value: string) => void;
};

export const ProphetSelect: React.FC<TProps> = ({
  placeholder,
  value,
  onChange,
}) => {
  //#region hooks usage
  const { t, i18n } = useTranslation();
  //#endregion

  //#region api
  const [
    getProphetSelectList,
    {
      isError: isErrorGetProphetSelectList,
      error: errorGetProphetSelectList,
      isFetching: isFetchingGetProphetSelectList,
      isSuccess: isSuccessGetProphetSelectList,
      data: dataGetProphetSelectList,
    },
  ] = useLazyGetProphetSelectListQuery();
  //#endregion

  //#region effects

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (!isFetchingGetProphetSelectList && isErrorGetProphetSelectList) {
      const _parsed = getServerError(errorGetProphetSelectList);
      if (_parsed.type === ErrorHandlingTypeEnum.ALERT) {
        toast.error(_parsed.error, {
          autoClose: 3000,
        });
      }
    }
    if (!isFetchingGetProphetSelectList && isSuccessGetProphetSelectList) {
      if (
        dataGetProphetSelectList &&
        (dataGetProphetSelectList as SelectListDto[]).length &&
        (dataGetProphetSelectList as SelectListDto[]).length > 0 &&
        !value
      ) {
        onChange((dataGetProphetSelectList as SelectListDto[])[0].id);
      }
    }
  }, [isFetchingGetProphetSelectList]);

  //#endregion

  const getData = async () => {
    await getProphetSelectList();
  };

  return (
    <React.Fragment>
      <ToastContainer newestOnTop={true} />
      <div className="input-group">
        <Input
          type={"select"}
          placeholder={placeholder}
          id={"contentTopic"}
          name="contentTopic"
          className="form-select"
          aria-label={placeholder}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            onChange(event.target.value)
          }
          value={value}
        >
          {dataGetProphetSelectList &&
            dataGetProphetSelectList.length &&
            dataGetProphetSelectList.map(({ id, value }: SelectListDto) => (
              <option value={id} key={id} className="text-capitalize">
                {i18n.language === "ar" ? value.ar : value.en}
              </option>
            ))}
        </Input>
      </div>
    </React.Fragment>
  );
};
