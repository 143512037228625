import React from "react";

type TProps = {
  date: Date;
  isOnlyDate?: boolean;
};
export const DateColumn: React.FC<TProps> = ({ date, isOnlyDate = false }) => {
  return (
    <div className="d-flex gap-2 align-items-center">
      <div className="flex-grow-1">
        {new Date(date).toLocaleDateString()}{" "}
        {!isOnlyDate && new Date(date).toLocaleTimeString()}
      </div>
    </div>
  );
};
