import React from "react";
import { Content } from "../content";
import { ContentTypeEnum } from "../../../enums";

type TProps = {};
export const MuslimLifeTopicPage: React.FC<TProps> = () => (
  <Content
    title={"muslimLifeTopics"}
    type={ContentTypeEnum.Muslim_Life_Topic}
  />
);
