import { Redirect } from "react-router-dom";

import {
  AcademicCalendarPage,
  AccountDeleteRequestPage,
  AdminUsersPage,
  AppUsersPage,
  ComplaintPage,
  ContactUsPage,
  ControlAppTabsPage,
  DashboardAnalyticsPage,
  EmailVerificationPage,
  ForgetPasswordPage,
  LoginPage,
  ManageMenuPage,
  MuslimLifeContentPage,
  MuslimLifeTopicPage,
  NotifyUsersPage,
  PrivacyPolicyPublicPage,
  ProphetEventContentPage,
  ProphetPage,
  ReferencesPage,
  ResetPasswordPage,
  SettingsTabPage,
  ZakatPage,
} from "../pages";
import Alt404 from "../pages/authInner/Errors/Alt404";

export const SuperAdminRoutes: any[] = [
  { path: "/dashboard", component: DashboardAnalyticsPage },
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/dashboard" />,
  },
  { path: "/settings", component: SettingsTabPage },
  { path: "/control-app", component: ControlAppTabsPage },
  { path: "/calendar", component: AcademicCalendarPage },
  { path: "/users/app", component: AppUsersPage },
  { path: "/users/admins", component: AdminUsersPage },
  { path: "/users/notify-users", component: NotifyUsersPage },
  { path: "/menu/zakat", component: ZakatPage },
  { path: "/menu/muslim-life/topics", component: MuslimLifeTopicPage },
  { path: "/menu/muslim-life/content", component: MuslimLifeContentPage },
  { path: "/menu/prophet/prophets", component: ProphetPage },
  { path: "/menu/prophet/event/content", component: ProphetEventContentPage },
  { path: "/menu/references", component: ReferencesPage },
  { path: "/menu/manage", component: ManageMenuPage },
  { path: "/complaints", component: ComplaintPage },
  { path: "not-found", component: Alt404 },
  { path: "*", exact: true, component: Alt404 },
];

export const PublicRoutes: any = [
  { path: "/login", component: LoginPage },
  { path: "/forget-password", component: ForgetPasswordPage },
  {
    path: "/reset-password/:phone/:code",
    component: ResetPasswordPage,
  },
  {
    path: "/reset-password/:token",
    component: ResetPasswordPage,
  },
  {
    path: "/verify-email/:token",
    component: EmailVerificationPage,
  },
  { path: "/support", component: ContactUsPage },
  {
    path: "/privacy-policy",
    component: PrivacyPolicyPublicPage,
  },
  { path: "/delete-data-request", component: AccountDeleteRequestPage },
];
