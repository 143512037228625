import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, useHistory } from "react-router-dom";
import classnames from "classnames";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  NavItem,
  TabContent,
  TabPane,
  NavLink,
} from "reactstrap";

import { BreadCrumb } from "../../components/Common";
import {
  AppVersionPage,
  BannersPage,
  PrivacyPolicyPage,
  TermsOfServicePage,
} from ".";

type TProps = RouteComponentProps & {};

export const ControlAppTabsPage: React.FC<TProps> = () => {
  //#region hooks
  const { t } = useTranslation();
  const history = useHistory();
  //#endregion

  //#region internal state
  const [activeTab, setActiveTab] = useState(history.location.hash || "1");
  //#endregion

  //#region effects

  useEffect(() => {
    const hash = history.location.hash;
    if (hash) {
      switch (hash) {
        case "#slide-show":
          setActiveTab("1");
          break;
        case "#application-version":
          setActiveTab("2");
          break;
        case "#privacy-policy":
          setActiveTab("3");
          break;
        case "#terms-of-service":
          setActiveTab("4");
          break;
        default:
          setActiveTab("1");
          break;
      }
    }
  }, []);

  //#endregion

  //#region handlers
  const pillsToggle = (changingTab: any) => {
    if (activeTab !== changingTab) {
      setActiveTab(changingTab);
    }
  };
  //#endregion

  document.title = `${t("documentTitle", { pageName: "controlApp" })}`;

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t("controlApp")} pageTitle={t("")} />
          <Row>
            <Col xxl={12}>
              <Card>
                <CardBody>
                  <Nav pills className="nav-success">
                    <NavItem>
                      <NavLink
                        href="#slide-show"
                        style={{ cursor: "pointer" }}
                        className={classnames(
                          { active: activeTab === "1" },
                          "d-flex"
                        )}
                        onClick={() => {
                          pillsToggle("1");
                        }}
                      >
                        <i className="ri-gallery-line fs-14"></i>&nbsp;
                        {t("slideShow")}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        href="#application-version"
                        style={{ cursor: "pointer" }}
                        className={classnames(
                          { active: activeTab === "2" },
                          "d-flex"
                        )}
                        onClick={() => {
                          pillsToggle("2");
                        }}
                      >
                        <i className="ri-smartphone-line fs-14"></i>&nbsp;
                        {t("applicationVersion")}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        href="#privacy-policy"
                        style={{ cursor: "pointer" }}
                        className={classnames(
                          { active: activeTab === "3" },
                          "d-flex"
                        )}
                        onClick={() => {
                          pillsToggle("3");
                        }}
                      >
                        <i className="ri-file-shield-line fs-14"></i>
                        &nbsp;
                        {t("privacyPolicy")}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        href="#terms-of-service"
                        style={{ cursor: "pointer" }}
                        className={classnames(
                          { active: activeTab === "4" },
                          "d-flex"
                        )}
                        onClick={() => {
                          pillsToggle("4");
                        }}
                      >
                        <i className="ri-file-list-line fs-14"></i>&nbsp;
                        {t("termsOfService")}
                      </NavLink>
                    </NavItem>
                  </Nav>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1" id="slide-show">
              {activeTab === "1" && <BannersPage />}
            </TabPane>
            <TabPane tabId="2" id="application-version">
              {activeTab === "2" && <AppVersionPage />}
            </TabPane>
            <TabPane tabId="3" id="privacy-policy">
              {activeTab === "3" && <PrivacyPolicyPage />}
            </TabPane>
            <TabPane tabId="4" id="terms-of-service">
              {activeTab === "4" && <TermsOfServicePage />}
            </TabPane>
          </TabContent>
        </Container>
      </div>
    </React.Fragment>
  );
};
