export enum MeasuringUnitEnum {
  Gram = <any>"Gram",
  Kilogram = <any>"Kilogram",
  Milligram = <any>"Milligram",
  Pound = <any>"Pound",
  Ounce = <any>"Ounce",
  USD = <any>"USD",
  EUR = <any>"EUR",
  GBP = <any>"GBP",
  KWD = <any>"KWD",
  SAR = <any>"SAR",
  AED = <any>"AED",
  PKR = <any>"PKR",
  AUD = <any>"AUD",
  CAD = <any>"CAD",
}
