import React, { ChangeEvent, useEffect } from "react";
import { Input } from "reactstrap";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";

import { SelectListDto } from "../../models";
import { ContentTypeEnum, ErrorHandlingTypeEnum } from "../../enums";
import {
  getServerError,
  useLazyGetContentSelectListQuery,
} from "../../services";

type TProps = {
  contentType: ContentTypeEnum;
  placeholder: string;
  value: string | number | readonly string[] | undefined;
  onChange: (value: string) => void;
  prophetId: string | null;
};

export const ContentSelect: React.FC<TProps> = ({
  contentType,
  placeholder,
  value,
  onChange,
  prophetId = null,
}) => {
  //#region hooks usage
  const { t, i18n } = useTranslation();
  //#endregion

  //#region api
  const [
    getContentSelectList,
    {
      isError: isErrorGetContentSelectList,
      error: errorGetContentSelectList,
      isFetching: isFetchingGetContentSelectList,
      isSuccess: isSuccessGetContentSelectList,
      data: dataGetContentSelectList,
    },
  ] = useLazyGetContentSelectListQuery();
  //#endregion

  //#region effects

  useEffect(() => {
    getData();
  }, [contentType, prophetId]);

  useEffect(() => {
    if (!isFetchingGetContentSelectList && isErrorGetContentSelectList) {
      const _parsed = getServerError(errorGetContentSelectList);
      if (_parsed.type === ErrorHandlingTypeEnum.ALERT) {
        toast.error(_parsed.error, {
          autoClose: 3000,
        });
      }
    }
    if (!isFetchingGetContentSelectList && isSuccessGetContentSelectList) {
      if (
        dataGetContentSelectList &&
        (dataGetContentSelectList as SelectListDto[]).length &&
        (dataGetContentSelectList as SelectListDto[]).length > 0 &&
        !value
      ) {
        onChange((dataGetContentSelectList as SelectListDto[])[0].id);
      } else {
        onChange(value as string);
      }
    }
  }, [isFetchingGetContentSelectList]);

  //#endregion

  const getData = async () => {
    if (contentType) {
      await getContentSelectList({
        type: contentType,
        prophetId: prophetId,
        parentId: null,
      });
    }
  };

  return (
    <React.Fragment>
      <ToastContainer newestOnTop={true} />
      <div className="input-group">
        <Input
          type={"select"}
          placeholder={placeholder}
          id={"contentTopic"}
          name="contentTopic"
          className="form-select"
          aria-label={placeholder}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            onChange(event.target.value)
          }
          value={value}
        >
          {dataGetContentSelectList &&
            dataGetContentSelectList.length &&
            dataGetContentSelectList.map(({ id, value }: SelectListDto) => (
              <option value={id} key={id} className="text-capitalize">
                {i18n.language === "ar" ? value.ar : value.en}
              </option>
            ))}
        </Input>
      </div>
    </React.Fragment>
  );
};
