import { useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { useTranslation } from "react-i18next";

type TProps = {
  onChange: (data: any) => void;
  initialValue: string;
  onKeyUp?: (data: any) => void;
  onFocus: (e: any) => void;
  directionality?: "ltr" | "rtl";
};
export const AppRichEditor: React.FC<TProps> = ({
  onChange,
  initialValue,
  onKeyUp,
  onFocus,
  directionality = "ltr",
}) => {
  //#region hooks
  const { i18n } = useTranslation();
  //#endregion

  //#region internal state
  const editorRef: any = useRef(null);
  //#endregion

  return (
    <Editor
      initialValue={initialValue}
      onInit={(evt, editor) => (editorRef.current = editor)}
      apiKey={process.env.REACT_APP_T_M_C_E_API_KEY}
      init={{
        help_accessibility: true,
        directionality: directionality,
        contextmenu: "copy paste",
        plugins:
          "anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount checklist mediaembed casechange export formatpainter pageembed linkchecker a11ychecker permanentpen powerpaste advtable advcode editimage advtemplate mentions tinycomments tableofcontents footnotes mergetags autocorrect typography inlinecss",
        toolbar:
          "undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat",
        tinycomments_mode: "embedded",
        language: i18n.language,
        paste_data_images: true,
        powerpaste_word_import: "prompt",
      }}
      onChange={() => {
        onChange(editorRef.current.getContent());
      }}
      onKeyUp={() => {
        if (onKeyUp) {
          onKeyUp(editorRef.current.getContent());
        }
      }}
      onFocus={onFocus}
    />
  );
};
