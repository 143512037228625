import { ToastContainer, toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import React, { ChangeEvent, useEffect, useState } from "react";
import { DebounceInput } from "react-debounce-input";
import { TableColumn } from "react-data-table-component";
import { Container, Row, Col, Card, CardBody, Input } from "reactstrap";

import { ComplaintDto } from "../../interfaces";
import { PaginateQuery } from "../../models";
import { BreadCrumb } from "../../components/Common";
import { ComplaintTypeEnum, ErrorHandlingTypeEnum } from "../../enums";
import {
  ColumnHeader,
  TextColumn,
  DateColumn,
  AppTable,
  TableSpinner,
} from "../../components";
import {
  getServerError,
  useLazyGetPaginatedComplaintQuery,
} from "../../services";

type TProps = {};
export const ComplaintPage: React.FC<TProps> = () => {
  //#region hooks usage
  const history = useHistory();
  const { t } = useTranslation();
  //#endregion

  //#region others
  document.title = `${t("documentTitle", {
    pageName: "complaintsAndSuggestions",
  })}`;
  //#endregion

  //#region api
  const [
    getPaginatedComplaint,
    {
      isError: isErrorGetPaginatedComplaint,
      error: errorGetPaginatedComplaint,
      isFetching: isFetchingGetPaginatedComplaint,
      data: dataGetPaginatedComplaint,
    },
  ] = useLazyGetPaginatedComplaintQuery();

  //#endregion

  //#region internal state
  const initState = new PaginateQuery();
  const [paginateQuery, setPaginateQuery] = useState<PaginateQuery>(initState);
  const [defaultSortAsc, setDefaultSortAsc] = useState(false);
  const [defaultPage, setDefaultPage] = useState(1);
  const [typeId, setTypeId] = useState<string>();
  //#endregion

  //#region Table Columns
  const columns: TableColumn<ComplaintDto>[] = [
    {
      name: <ColumnHeader title="type" />,
      cell: (row) => <TextColumn text={row.type} />,
      sortable: true,
      width: "120px",
    },
    {
      name: <ColumnHeader title="topic" />,
      cell: (row) => <TextColumn text={row.topic} />,
      sortable: false,
      width: "250px",
    },
    {
      name: <ColumnHeader title="message" />,
      cell: (row) => <TextColumn text={row.message} />,
      sortable: false,
      width: "350px",
    },
    {
      name: <ColumnHeader title="contactInformation" />,
      cell: (row) => (
        <div>
          <div className="my-2">
            <strong>{t("name")}: </strong>
            <span>{row.name || "n/a"}</span>
          </div>
          <div className="my-2">
            <strong>{t("email")}: </strong>
            {row.email ? (
              <a style={{ cursor: "pointer" }} href={`mailto:${row.email}`}>
                <u>{row.email}</u>
              </a>
            ) : (
              "n/a"
            )}
          </div>
          <div className="my-2">
            <strong>{t("phoneNumber")}: </strong>
            {row.phoneNumber ? (
              <a style={{ cursor: "pointer" }} href={`tel:${row.phoneNumber}`}>
                <u>{row.phoneNumber}</u>
              </a>
            ) : (
              "n/a"
            )}
          </div>
        </div>
      ),
      sortable: false,
      width: "250px",
    },
    {
      name: <ColumnHeader title="registeredUser" />,
      cell: (row) => (
        <React.Fragment>
          {row.user ? (
            <div>
              <div className="my-2">
                <strong>{t("name")}: </strong>
                <span>{row.user?.name?.en || "n/a"}</span>
              </div>
              <div className="my-2">
                <strong>{t("email")}: </strong>
                {row.user?.email?.email ? (
                  <a
                    style={{ cursor: "pointer" }}
                    href={`mailto:${row.user?.email?.email}`}
                  >
                    <u>{row.user?.email?.email}</u>
                  </a>
                ) : (
                  "n/a"
                )}
              </div>
              <div className="my-2">
                <strong>{t("phoneNumber")}: </strong>
                {row.user.phone?.countryCode && row.user.phone?.phoneNumber ? (
                  <a
                    style={{ cursor: "pointer" }}
                    href={`tel:+${row.user?.phone?.countryCode} ${row.user?.phone?.phoneNumber}`}
                  >
                    <u>
                      +{row.user?.phone?.countryCode}{" "}
                      {row.user?.phone?.phoneNumber}
                    </u>
                  </a>
                ) : (
                  "n/a"
                )}
              </div>
            </div>
          ) : (
            "-"
          )}
        </React.Fragment>
      ),
      sortable: false,
      width: "250px",
    },
    {
      name: <ColumnHeader title="createdAt" />,
      cell: (row) => <DateColumn date={row.createdAt} />,
      sortable: true,
      width: "180px",
    },
  ];

  //#endregion

  //#region effects

  useEffect(() => {
    const queryParams = new URLSearchParams(history.location.search);
    let query: PaginateQuery = {
      limit: queryParams.has("limit")
        ? Number(queryParams.get("limit"))
        : paginateQuery.limit,
      page: queryParams.has("page")
        ? Number(queryParams.get("page"))
        : paginateQuery.page,
      sortBy: queryParams.has("sortBy")
        ? (queryParams.get("sortBy") as string)
        : paginateQuery.sortBy,
      search: queryParams.has("search")
        ? (queryParams.get("search") as string)
        : paginateQuery.search,
      filter: queryParams.has("filter[type]")
        ? {
            ["type"]: queryParams.get("filter[type]") || "",
          }
        : paginateQuery.filter,
    };
    if (
      queryParams.get("sortBy") &&
      queryParams.get("sortBy")?.split(":").length &&
      queryParams.get("sortBy")?.split(":").length == 2
    ) {
      setDefaultSortAsc(
        (queryParams.get("sortBy")?.split(":")[1] as string) === "ASC"
      );
      setDefaultSortFieldId(
        getSortByColumnName(queryParams.get("sortBy")?.split(":")[0] as string)
      );
    } else {
      setDefaultSortAsc(false);
      setDefaultSortFieldId(2);
    }
    setDefaultPage(query.page);
    setPaginateQuery(query);
  }, []);

  useEffect(() => {
    if (paginateQuery !== initState) {
      const { limit, page, search, sortBy, filter } = paginateQuery;
      let query;
      if (page) query = `?page=${page}`;
      if (limit) query += `&limit=${limit}`;
      if (search && search !== "") {
        history.replace({
          search: `&search=${search}`,
        });
      }
      if (sortBy) query += `&sortBy=${sortBy}`;
      if (search) query += `&search=${search}`;
      if (
        filter &&
        Object.keys(filter).length &&
        Object.keys(filter).length > 0
      ) {
        let filters: string = "";
        Object.keys(filter).map((key: string) => {
          if (filter[key]) filters += `&filter[${key}]=${filter[key]}`;
        });
        query += filters;
      }
      history.replace({ search: query });
      getPaginatedComplaint(paginateQuery);
    }
  }, [paginateQuery]);

  useEffect(() => {
    if (!isFetchingGetPaginatedComplaint && isErrorGetPaginatedComplaint) {
      const _parsed = getServerError(errorGetPaginatedComplaint);
      if (_parsed.type === ErrorHandlingTypeEnum.ALERT) {
        toast.error(_parsed.error, {
          autoClose: 3000,
        });
      }
    }
  }, [isFetchingGetPaginatedComplaint, isErrorGetPaginatedComplaint]);

  //#endregion

  //#region handlers

  const getSortByColumnName = (name: string) => {
    let sortBy;
    switch (name) {
      case "type":
        sortBy = 1;
        break;
      case "created_at":
        sortBy = 6;
        break;
      default:
        sortBy = 6;
        break;
    }
    return sortBy;
  };

  const onSearch = (searchText: string) => {
    setPaginateQuery({ ...paginateQuery, search: searchText });
  };

  const getSortByColumnId = (columnId: number, sortDirection: string) => {
    let sortBy;
    switch (columnId) {
      case 1:
        sortBy = "type";
        break;
      case 6:
        sortBy = "created_at";
        break;
      default:
        sortBy = "created_at";
        break;
    }
    return `${sortBy}:${sortDirection.toUpperCase()}`;
  };

  const onChangePage = (page: number) => {
    setPaginateQuery({ ...paginateQuery, page: page });
  };

  const onChangeRowsPerPage = (
    currentRowsPerPage: number,
    currentPage: number
  ) => {
    setPaginateQuery({
      ...paginateQuery,
      limit: currentRowsPerPage,
      page: currentPage,
    });
  };

  const onSort = (column: TableColumn<ComplaintDto>, sortDirection: string) => {
    const sortBy = getSortByColumnId(column.id as number, sortDirection);
    setPaginateQuery({
      ...paginateQuery,
      sortBy: sortBy as string,
    });
  };

  const onChangeType = (value: string) => {
    setTypeId(value);
    setPaginateQuery({
      ...paginateQuery,
      page: 1,
      filter: {
        type: value,
      },
    });
  };

  //#endregion

  const [defaultSortFieldId, setDefaultSortFieldId] = useState(
    getSortByColumnName("created_at:DESC")
  );

  return (
    <React.Fragment>
      <ToastContainer newestOnTop={true} />
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title={t("complaintsAndSuggestions")}
            pageTitle={t("complaintsAndSuggestions")}
          />
          <Row>
            <Col xxl={12}>
              <Card>
                <CardBody>
                  <Row className="g-2 mb-3">
                    <Col sm={4} className="mb-2">
                      <div className="search-box">
                        <DebounceInput
                          className="form-control"
                          placeholder={`${t("searchPlaceholder")}...`}
                          minLength={2}
                          debounceTimeout={300}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => onSearch(event.target.value)}
                          value={paginateQuery.search}
                        />
                        <i className="ri-search-line search-icon"></i>
                      </div>
                    </Col>
                    <Col sm={4} className="mb-2">
                      <Input
                        type="select"
                        id="type"
                        value={typeId}
                        placeholder={t("chooseType")}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          onChangeType(event.target.value);
                        }}
                      >
                        <option value={""}>{t("all")}</option>
                        <option
                          id={ComplaintTypeEnum.COMPLAIN}
                          value={ComplaintTypeEnum.COMPLAIN}
                        >
                          {t("complain")}
                        </option>
                        <option
                          id={ComplaintTypeEnum.CONTACT_US}
                          value={ComplaintTypeEnum.CONTACT_US}
                        >
                          {t("contactUs")}
                        </option>
                        <option
                          id={ComplaintTypeEnum.DELETE_ACCOUNT_REQUEST}
                          value={ComplaintTypeEnum.DELETE_ACCOUNT_REQUEST}
                        >
                          {t("deleteAccountRequest")}
                        </option>
                        <option
                          id={ComplaintTypeEnum.SUGGESTION}
                          value={ComplaintTypeEnum.SUGGESTION}
                        >
                          {t("suggestion")}
                        </option>
                      </Input>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12}>
                      <AppTable
                        theme={"dark"}
                        columns={columns}
                        data={dataGetPaginatedComplaint?.data || []}
                        progressPending={isFetchingGetPaginatedComplaint}
                        progressComponent={<TableSpinner />}
                        pagination
                        paginationServer
                        sortServer
                        onSort={onSort}
                        onChangeRowsPerPage={onChangeRowsPerPage}
                        onChangePage={onChangePage}
                        defaultSortFieldId={defaultSortFieldId}
                        paginationDefaultPage={defaultPage}
                        defaultSortAsc={defaultSortAsc}
                        persistTableHead
                        paginationTotalRows={
                          dataGetPaginatedComplaint?.meta?.totalItems
                        }
                        paginationComponentOptions={{
                          rowsPerPageText: t("rowsPerPage"),
                          rangeSeparatorText: t("of"),
                        }}
                        fixedHeader={true}
                        fixedHeaderScrollHeight="60vh"
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
