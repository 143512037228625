import { toast } from "react-toastify";
import React, { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import { Input, Label, Spinner } from "reactstrap";

import { IMAGE_ACCEPT } from "../../constants";
import logo from "../../assets/images/logo.png";

type TProps = {
  path: string | undefined | null;
  inProgress: boolean;
  onChange: (file: File) => void;
};
export const ImageUpload: React.FC<TProps> = ({
  path,
  inProgress = false,
  onChange,
}) => {
  const { t } = useTranslation();

  const onChangeImage = (event: ChangeEvent<HTMLInputElement>) => {
    const { target } = event;
    const { files } = target;
    if (files && files.length > 0) {
      const imageFile = files[0];
      if (!imageFile.name.match(/\.(jpg|jpeg|jfif|pjpeg|pjp|png|webp|gif)$/)) {
        toast.error(t("selectValidImage"), {
          autoClose: 3000,
        });
        return false;
      } else {
        onChange(imageFile);
      }
    }
  };

  return (
    <div className="col-auto text-center">
      <div className="profile-user position-relative d-inline-block mx-auto mb-4">
        <img
          src={path || logo}
          className=" avatar-xl img-thumbnail user-profile-image"
          alt="image"
        />
        <div className="avatar-xl p-0 profile-photo-edit">
          <Input
            id="question_display_image"
            type="file"
            className="profile-img-file-input"
            accept={IMAGE_ACCEPT}
            onChange={onChangeImage}
            disabled={inProgress}
          />
          <Label
            htmlFor="question_display_image"
            className="profile-photo-edit avatar-xs"
          >
            <span className="avatar-title rounded-circle bg-light text-body">
              {inProgress && <Spinner />}
              {!inProgress && <i className="ri-camera-fill"></i>}
            </span>
          </Label>
        </div>
      </div>
    </div>
  );
};
