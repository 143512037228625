import React from "react";
import { useTranslation } from "react-i18next";
import { Spinner } from "reactstrap";

type TProps = {
  onClick: () => void;
  title: string;
  riIconName: string;
  disabled: boolean;
  isInProgress: boolean;
};
export const InputLabelButton: React.FC<TProps> = ({
  onClick,
  title,
  riIconName,
  disabled = false,
  isInProgress = false,
}) => {
  const { t } = useTranslation();
  return (
    <button
      disabled={disabled}
      type="button"
      onClick={onClick}
      className="btn btn-link btn-label translation-button"
    >
      <i
        className={`${riIconName} label-icon fs-16`}
        style={{ backgroundColor: "transparent", width: 60 }}
      ></i>
      {isInProgress ? (
        <div className="d-flex">
          <div className="flex-grow-1">{t("translating")}...</div>
          <Spinner size={"sm"} />
        </div>
      ) : (
        <div className="flex-grow-1">{t(title)}</div>
      )}
    </button>
  );
};
