export class PaginateQuery {
  page: number;
  limit: number;
  sortBy: string;
  search: string;
  filter?: {
    [column: string]: string | string[];
  };
  constructor(limit?: number, sortBy?: string) {
    this.page = 1;
    this.limit = limit || 10;
    if (sortBy) this.sortBy = sortBy;
    else this.sortBy = "created_at:DESC";
    this.search = "";
    this.filter = {};
  }
}

export class DateRangeQuery {
  from: Date;
  to: Date;
  /**
   *
   */
  constructor() {
    var date = new Date(),
      y = date.getFullYear(),
      m = date.getMonth();
    this.from = new Date(y, m, 1);
    this.to = new Date(y, m + 1, 0);
  }
}
