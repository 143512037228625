import { useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";

import { RoleEnum } from "../enums";
import { useUser } from "../components/hooks";

export const Navdata = () => {
  //#region hooks usage
  const history = useHistory();
  //#region

  //#region redux state
  const { user } = useUser();

  //#endregion

  const [isDashboard, setIsDashboard] = useState(false);
  const [isUsers, setIsUsers] = useState(false);
  const [isMenu, setIsMenu] = useState(false);
  const [isControl, setIsControl] = useState(false);
  const [isCalendar, setIsCalendar] = useState(false);
  const [iscurrentState, setIscurrentState] = useState("dashboard");
  const [isLevel1, setIsLevel1] = useState(false);
  const [isLevelProphets, setIsLevelProphets] = useState(false);
  const [isComplaint, setIsComplaint] = useState(false);
  //#endregion

  function updateIconSidebar(e: any) {
    if (e && e.target && e.target.getAttribute("subitems")) {
      const ul: any = document.getElementById("two-column-menu");
      const iconItems: any = ul.querySelectorAll(".nav-icon.active");
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove("active");
        var id = item.getAttribute("subitems");
        if (document.getElementById(id))
          document.getElementById(id)?.classList.remove("show");
      });
    }
  }

  useEffect(() => {
    document.body.classList.remove("twocolumn-panel");
    if (iscurrentState !== "dashboard") {
      setIsDashboard(false);
    }
    if (iscurrentState !== "users") {
      setIsUsers(false);
    }
    if (iscurrentState !== "menu") {
      setIsMenu(false);
    }
    if (iscurrentState !== "control") {
      setIsControl(false);
    }
    if (iscurrentState !== "calendar") {
      setIsCalendar(false);
    }
    if (iscurrentState !== "complaint") {
      setIsComplaint(false);
    }
  }, [
    history,
    iscurrentState,
    isDashboard,
    isUsers,
    isMenu,
    isControl,
    isCalendar,
    isComplaint,
  ]);

  const superAdminMenuItems: any = () => [
    {
      label: "menu",
      isHeader: true,
    },
    {
      id: "dashboard",
      label: "dashboard",
      icon: "ri-apps-2-line",
      link: "/dashboard",
      stateVariables: isDashboard,
      click: function (e: any) {
        e.preventDefault();
        setIsDashboard(!isDashboard);
        setIscurrentState("dashboard");
        updateIconSidebar(e);
      },
    },
    {
      id: "users",
      label: "users",
      icon: "ri-user-fill",
      link: "/#",
      click: function (e: any) {
        e.preventDefault();
        setIsUsers(!isUsers);
        setIscurrentState("users");
        updateIconSidebar(e);
      },
      stateVariables: isUsers,
      subItems: [
        {
          id: "appUsers",
          label: "appUsers",
          link: "/users/app",
          parentId: "users",
        },
        {
          id: "adminUsers",
          label: "adminUsers",
          link: "/users/admins",
          parentId: "users",
        },
        {
          id: "notifyUsers",
          label: "notifyUsers",
          link: "/users/notify-users",
          parentId: "users",
        },
      ],
    },
    {
      id: "menu",
      label: "menu",
      icon: "ri-menu-line",
      link: "/#",
      click: function (e: any) {
        e.preventDefault();
        setIsMenu(!isMenu);
        setIscurrentState("menu");
        updateIconSidebar(e);
      },
      stateVariables: isMenu,
      subItems: [
        {
          id: "zakat",
          label: "zakat",
          link: "/menu/zakat",
          parentId: "menu",
        },
        {
          id: "muslimLife",
          label: "muslimLife",
          link: "/#",
          parentId: "menu",
          isChildItem: true,
          click: function (e: any) {
            e.preventDefault();
            setIsLevel1(!isLevel1);
          },
          stateVariables: isLevel1,
          childItems: [
            {
              id: 1,
              label: "muslimLifeTopics",
              link: "/menu/muslim-life/topics",
            },
            {
              id: 2,
              label: "muslimLifeContent",
              link: "/menu/muslim-life/content",
            },
          ],
        },
        {
          id: "references",
          label: "references",
          link: "/menu/references",
          parentId: "menu",
        },
        {
          id: "prophets",
          label: "prophet",
          link: "/#",
          parentId: "menu",
          isChildItem: true,
          click: function (e: any) {
            e.preventDefault();
            setIsLevelProphets(!isLevelProphets);
          },
          stateVariables: isLevelProphets,
          childItems: [
            {
              id: 1,
              label: "prophets",
              link: "/menu/prophet/prophets",
            },
            {
              id: 2,
              label: "prophetEventContent",
              link: "/menu/prophet/event/content",
            },
          ],
        },
        {
          id: "manageMenu",
          label: "manageMenu",
          link: "/menu/manage",
          parentId: "menu",
        },
      ],
    },
    {
      id: "control",
      label: "controlApp",
      icon: "ri-equalizer-fill",
      link: "/control-app",
      stateVariables: isControl,
      click: function (e: any) {
        e.preventDefault();
        setIsControl(!isControl);
        setIscurrentState("control");
        updateIconSidebar(e);
      },
    },
    {
      id: "calendar",
      label: "Calendar",
      icon: "ri-calendar-line",
      link: "/calendar",
      stateVariables: isCalendar,
      click: function (e: any) {
        e.preventDefault();
        setIsCalendar(!isCalendar);
        setIscurrentState("calendar");
        updateIconSidebar(e);
      },
    },
    {
      id: "complaintsAndSuggestions",
      label: "complaintsAndSuggestions",
      icon: "ri-mail-open-line",
      link: "/complaints",
      stateVariables: isComplaint,
      click: function (e: any) {
        e.preventDefault();
        setIsComplaint(!isComplaint);
        setIscurrentState("complaint");
        updateIconSidebar(e);
      },
    },
  ];

  const adminMenuItems: any = () => [
    {
      label: "menu",
      isHeader: true,
    },
    {
      id: "dashboard",
      label: "dashboard",
      icon: "ri-apps-2-line",
      link: "/dashboard",
      stateVariables: isDashboard,
      click: function (e: any) {
        e.preventDefault();
        setIsDashboard(!isDashboard);
        setIscurrentState("dashboard");
        updateIconSidebar(e);
      },
    },
    {
      id: "users",
      label: "users",
      icon: "ri-user-fill",
      link: "/#",
      click: function (e: any) {
        e.preventDefault();
        setIsUsers(!isUsers);
        setIscurrentState("users");
        updateIconSidebar(e);
      },
      stateVariables: isUsers,
      subItems: [
        {
          id: "appUsers",
          label: "appUsers",
          link: "/users/app",
          parentId: "users",
        },
        {
          id: "notifyUsers",
          label: "notifyUsers",
          link: "/users/notify-users",
          parentId: "users",
        },
      ],
    },
    {
      id: "menu",
      label: "menu",
      icon: "ri-menu-line",
      link: "/#",
      click: function (e: any) {
        e.preventDefault();
        setIsMenu(!isMenu);
        setIscurrentState("menu");
        updateIconSidebar(e);
      },
      stateVariables: isMenu,
      subItems: [
        {
          id: "hajj",
          label: "hajj",
          link: "/menu/hajj",
          parentId: "menu",
        },
        {
          id: "umrah",
          label: "umrah",
          link: "/menu/umrah",
          parentId: "menu",
        },
        {
          id: "zakat",
          label: "zakat",
          link: "/menu/zakat",
          parentId: "menu",
        },
        {
          id: "muslimLife",
          label: "muslimLife",
          link: "/#",
          parentId: "menu",
          isChildItem: true,
          click: function (e: any) {
            e.preventDefault();
            setIsLevel1(!isLevel1);
          },
          stateVariables: isLevel1,
          childItems: [
            {
              id: 1,
              label: "muslimLifeTopics",
              link: "/menu/muslim-life/topics",
            },
            {
              id: 2,
              label: "muslimLifeContent",
              link: "/menu/muslim-life/content",
            },
          ],
        },
        {
          id: "references",
          label: "references",
          link: "/menu/references",
          parentId: "menu",
        },
        {
          id: "prophets",
          label: "prophet",
          link: "/#",
          parentId: "menu",
          isChildItem: true,
          click: function (e: any) {
            e.preventDefault();
            setIsLevelProphets(!isLevelProphets);
          },
          stateVariables: isLevelProphets,
          childItems: [
            {
              id: 1,
              label: "prophets",
              link: "/menu/prophet/prophets",
            },
            {
              id: 2,
              label: "prophetEventContent",
              link: "/menu/prophet/event/content",
            },
          ],
        },
        {
          id: "manageMenu",
          label: "manageMenu",
          link: "/menu/manage",
          parentId: "menu",
        },
      ],
    },
    {
      id: "control",
      label: "controlApp",
      icon: "ri-equalizer-fill",
      link: "/control-app",
      stateVariables: isControl,
      click: function (e: any) {
        e.preventDefault();
        setIsControl(!isControl);
        setIscurrentState("control");
        updateIconSidebar(e);
      },
    },
    {
      id: "calendar",
      label: "Calendar",
      icon: "ri-calendar-line",
      link: "/calendar",
      stateVariables: isCalendar,
      click: function (e: any) {
        e.preventDefault();
        setIsCalendar(!isCalendar);
        setIscurrentState("calendar");
        updateIconSidebar(e);
      },
    },
    {
      id: "complaintsAndSuggestions",
      label: "complaintsAndSuggestions",
      icon: "ri-mail-open-line",
      link: "/complaints",
      stateVariables: isComplaint,
      click: function (e: any) {
        e.preventDefault();
        setIsComplaint(!isComplaint);
        setIscurrentState("complaint");
        updateIconSidebar(e);
      },
    },
  ];

  const renderMenu = () => {
    if (user?.role?.key === RoleEnum.SUPER_ADMIN)
      return <React.Fragment>{superAdminMenuItems()}</React.Fragment>;
    else if (user?.role?.key === RoleEnum.ADMIN)
      return <React.Fragment>{adminMenuItems()}</React.Fragment>;
    else return <React.Fragment></React.Fragment>;
  };

  return renderMenu();
};

export default Navdata;
