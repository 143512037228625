import { ToastContainer, toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { DebounceInput } from "react-debounce-input";
import { TableColumn } from "react-data-table-component";
import { Container, Row, Col, Card, CardBody } from "reactstrap";

import { MenuDto } from "../../../interfaces";
import { PaginateQuery } from "../../../models";
import { BreadCrumb } from "../../../components/Common";
import { ActionTypeEnum, ErrorHandlingTypeEnum } from "../../../enums";
import {
  ColumnHeader,
  DateColumn,
  AppTable,
  TableSpinner,
  TextColumn,
  ActionButton,
} from "../../../components";
import {
  getServerError,
  useLazyGetPaginatedMenuQuery,
  useToggleMenuStateMutation,
} from "../../../services";
import i18n from "../../../i18n";
import ConfirmationModal from "../../../components/Common/ConfirmationModal";
import { AddEditMenuModal } from "./AddEditMenuModal";

type TProps = {};
export const ManageMenuPage: React.FC<TProps> = () => {
  //#region hooks usage
  const history = useHistory();
  const { t } = useTranslation();
  //#endregion

  //#region others
  document.title = `${t("documentTitle", {
    pageName: "manageMenu",
  })}`;
  //#endregion

  //#region api
  const [
    getPaginatedMenu,
    {
      isError: isErrorGetPaginatedMenu,
      error: errorGetPaginatedMenu,
      isFetching: isFetchingGetPaginatedMenu,
      data: dataGetPaginatedMenu,
    },
  ] = useLazyGetPaginatedMenuQuery();

  const [
    toggleMenuState,
    {
      isLoading: isLoadingToggleMenuState,
      isError: isErrorToggleMenuState,
      isSuccess: isSuccessToggleMenuState,
      error: errorToggleMenuState,
    },
  ] = useToggleMenuStateMutation();

  //#endregion

  //#region internal state
  const initState = new PaginateQuery(10, "sequence:ASC");
  const [paginateQuery, setPaginateQuery] = useState<PaginateQuery>(initState);
  const [defaultSortAsc, setDefaultSortAsc] = useState(true);
  const [defaultPage, setDefaultPage] = useState(1);
  const [showToggleStateModal, setShowToggleStateModal] = useState(false);
  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState<MenuDto | null>();
  const [editingItemId, setEditingItemId] = useState<string | null>(null);
  //#endregion

  //#region Table Columns
  const columns: TableColumn<MenuDto>[] = [
    {
      name: <ColumnHeader title="sequence" />,
      cell: (row) => <TextColumn text={row.sequence} />,
      sortable: true,
    },
    {
      name: <ColumnHeader title="title" />,
      cell: (row) => (
        <TextColumn
          text={row.title[i18n.language === "ar" ? "ar" : "en"] as string}
        />
      ),
      sortable: true,
    },
    {
      name: <ColumnHeader title="createdAt" />,
      cell: (row) => <DateColumn date={row.createdAt} />,
      sortable: true,
    },
    {
      name: <ColumnHeader title="action" />,
      sortable: false,
      width: "120px",
      cell: (row) => (
        <React.Fragment>
          <ActionButton
            onAction={() => onToggleState(row)}
            actionType={ActionTypeEnum.ACTIVE_INACTIVE}
            state={!row.isActive}
          />
          &nbsp;
          <ActionButton
            onAction={() => onEdit(row.id)}
            actionType={ActionTypeEnum.EDIT}
          />
        </React.Fragment>
      ),
    },
  ];

  //#endregion

  //#region effects

  useEffect(() => {
    const queryParams = new URLSearchParams(history.location.search);
    let query: PaginateQuery = {
      limit: queryParams.has("limit")
        ? Number(queryParams.get("limit"))
        : paginateQuery.limit,
      page: queryParams.has("page")
        ? Number(queryParams.get("page"))
        : paginateQuery.page,
      sortBy: queryParams.has("sortBy")
        ? (queryParams.get("sortBy") as string)
        : paginateQuery.sortBy,
      search: queryParams.has("search")
        ? (queryParams.get("search") as string)
        : paginateQuery.search,
      filter: queryParams.has("filter[type]")
        ? {
            ["type"]: queryParams.get("filter[type]") || "",
          }
        : paginateQuery.filter,
    };
    if (
      queryParams.get("sortBy") &&
      queryParams.get("sortBy")?.split(":").length &&
      queryParams.get("sortBy")?.split(":").length == 2
    ) {
      setDefaultSortAsc(
        (queryParams.get("sortBy")?.split(":")[1] as string) === "ASC"
      );
      setDefaultSortFieldId(
        getSortByColumnName(queryParams.get("sortBy")?.split(":")[0] as string)
      );
    } else {
      setDefaultSortAsc(false);
      setDefaultSortFieldId(2);
    }
    setDefaultPage(query.page);
    setPaginateQuery(query);
  }, []);

  useEffect(() => {
    if (paginateQuery !== initState) {
      const { limit, page, search, sortBy, filter } = paginateQuery;
      let query;
      if (page) query = `?page=${page}`;
      if (limit) query += `&limit=${limit}`;
      if (search && search !== "") {
        history.replace({
          search: `&search=${search}`,
        });
      }
      if (sortBy) query += `&sortBy=${sortBy}`;
      if (search) query += `&search=${search}`;
      if (
        filter &&
        Object.keys(filter).length &&
        Object.keys(filter).length > 0
      ) {
        let filters: string = "";
        Object.keys(filter).map((key: string) => {
          if (filter[key]) filters += `&filter[${key}]=${filter[key]}`;
        });
        query += filters;
      }
      history.replace({ search: query });
      getPaginatedMenu(paginateQuery);
    }
  }, [paginateQuery]);

  useEffect(() => {
    if (!isFetchingGetPaginatedMenu && isErrorGetPaginatedMenu) {
      const _parsed = getServerError(errorGetPaginatedMenu);
      if (_parsed.type === ErrorHandlingTypeEnum.ALERT) {
        toast.error(_parsed.error, {
          autoClose: 3000,
        });
      }
    }
  }, [isFetchingGetPaginatedMenu, isErrorGetPaginatedMenu]);

  useEffect(() => {
    if (!isLoadingToggleMenuState && isErrorToggleMenuState) {
      const _parsed = getServerError(errorToggleMenuState);
      if (_parsed.type === ErrorHandlingTypeEnum.ALERT) {
        toast.error(_parsed.error, {
          autoClose: 3000,
        });
      }
    }
    if (!isLoadingToggleMenuState && isSuccessToggleMenuState) {
      setShowToggleStateModal(false);
      toast.success(t("savedSuccessfully"), { autoClose: 3000 });
    }
  }, [
    isLoadingToggleMenuState,
    isErrorToggleMenuState,
    isSuccessToggleMenuState,
  ]);

  //#endregion

  //#region handlers

  const getSortByColumnName = (name: string) => {
    let sortBy;
    switch (name) {
      case "sequence":
        sortBy = 1;
        break;
      case "title.en":
      case "title.ar":
        sortBy = 2;
        break;
      case "created_at":
        sortBy = 3;
        break;
      default:
        sortBy = 1;
        break;
    }
    return sortBy;
  };

  const onToggleState = (_menu: MenuDto) => {
    setSelectedMenu(_menu);
    setShowToggleStateModal(true);
  };

  const onToggleStateConfirm = async () => {
    await toggleMenuState(selectedMenu?.id as string);
    setSelectedMenu(null);
  };

  const onEdit = (id: string) => {
    setEditingItemId(id);
    setShowAddEditModal(true);
  };

  const onSearch = (searchText: string) => {
    setPaginateQuery({ ...paginateQuery, search: searchText });
  };

  const getSortByColumnId = (columnId: number, sortDirection: string) => {
    let sortBy;
    switch (columnId) {
      case 1:
        sortBy = "sequence";
        break;
      case 2:
        sortBy = "title" + "." + i18n.language;
        break;
      case 3:
        sortBy = "created_at";
        break;
      default:
        sortBy = "sequence";
        break;
    }
    return `${sortBy}:${sortDirection.toUpperCase()}`;
  };

  const onChangePage = (page: number) => {
    setPaginateQuery({ ...paginateQuery, page: page });
  };

  const onChangeRowsPerPage = (
    currentRowsPerPage: number,
    currentPage: number
  ) => {
    setPaginateQuery({
      ...paginateQuery,
      limit: currentRowsPerPage,
      page: currentPage,
    });
  };

  const onSort = (column: TableColumn<MenuDto>, sortDirection: string) => {
    const sortBy = getSortByColumnId(column.id as number, sortDirection);
    setPaginateQuery({
      ...paginateQuery,
      sortBy: sortBy as string,
    });
  };

  //#endregion

  const [defaultSortFieldId, setDefaultSortFieldId] = useState(
    getSortByColumnName("sequence:DESC")
  );

  return (
    <React.Fragment>
      <ToastContainer newestOnTop={true} />
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t("menu")} pageTitle={t("manageMenu")} />
          <Row>
            <Col xxl={12}>
              <Card>
                <CardBody>
                  <Row className="g-2 mb-3">
                    <Col sm={4} className="mb-2">
                      <div className="search-box">
                        <DebounceInput
                          className="form-control"
                          placeholder={`${t("searchPlaceholder")}...`}
                          minLength={2}
                          debounceTimeout={300}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => onSearch(event.target.value)}
                          value={paginateQuery.search}
                        />
                        <i className="ri-search-line search-icon"></i>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12}>
                      <AppTable
                        theme={"dark"}
                        columns={columns}
                        data={dataGetPaginatedMenu?.data || []}
                        progressPending={isFetchingGetPaginatedMenu}
                        progressComponent={<TableSpinner />}
                        pagination
                        paginationServer
                        sortServer
                        onSort={onSort}
                        onChangeRowsPerPage={onChangeRowsPerPage}
                        onChangePage={onChangePage}
                        defaultSortFieldId={defaultSortFieldId}
                        paginationDefaultPage={defaultPage}
                        defaultSortAsc={defaultSortAsc}
                        persistTableHead
                        paginationTotalRows={
                          dataGetPaginatedMenu?.meta?.totalItems
                        }
                        paginationComponentOptions={{
                          rowsPerPageText: t("rowsPerPage"),
                          rangeSeparatorText: t("of"),
                        }}
                        fixedHeader={true}
                        fixedHeaderScrollHeight="60vh"
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <AddEditMenuModal
        id={editingItemId}
        show={showAddEditModal}
        onCloseClick={async () => {
          setShowAddEditModal(false);
          setEditingItemId(null);
        }}
      />
      <ConfirmationModal
        confirmationButtonInProgressTitle={t("saving")}
        confirmationButtonTitle={
          (selectedMenu?.isActive as boolean) ? t("inactivate") : t("activate")
        }
        description={
          (selectedMenu?.isActive as boolean)
            ? t("inactivateConfirmationMessage", {
                item: selectedMenu?.title.en,
              })
            : t("activateConfirmationMessage", {
                item: selectedMenu?.title.en,
              })
        }
        icon={<></>}
        title={t("areYouSure")}
        loading={isLoadingToggleMenuState}
        show={showToggleStateModal}
        onConfirmClick={() => onToggleStateConfirm()}
        onCloseClick={() => {
          setShowToggleStateModal(false);
        }}
      />
    </React.Fragment>
  );
};
