import React from "react";
import { Col, Container, Row } from "reactstrap";

//import Components
import { BreadCrumb } from "../../components/Common";

type TProps = {};

export const DashboardAnalyticsPage: React.FC<TProps> = (props: TProps) => {
  document.title = "Dashboard | IslamGPT";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Analytics" pageTitle="Dashboards" />
          <Row>
            <Col xxl={12}>
              <h3>Coming Soon</h3>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
