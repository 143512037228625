export enum LanguageEnum {
  EN = "en",
  AR = "ar",
  ID = "id",
  UR = "ur",
  RU = "ru",
  ZH = "zh",
  TR = "tr",
  DE = "de",
  ES = "es",
  FR = "fr",
}
