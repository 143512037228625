import React from "react";
import { useTranslation } from "react-i18next";
import { Alert, Modal, ModalBody } from "reactstrap";

import { ActivityButton } from ".";
import { LordIcon } from "./LordIcon";

type TProps = {
  onCloseClick: (event: any) => void;
  onDeleteClick: (event: any) => void;
  show: boolean;
  loading: boolean;
  error?: string | null;
};

const DeleteModal: React.FC<TProps> = ({
  show,
  onDeleteClick,
  onCloseClick,
  loading,
}) => {
  const { t } = useTranslation();
  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <ModalBody className="py-3 px-5">
        <div className="mt-2 text-center">
          <LordIcon
            src="https://cdn.lordicon.com/gsqxdxog.json"
            trigger="loop"
            colors="primary:#f7b84b,secondary:#f06548"
            style={{ width: "100px", height: "100px" }}
          ></LordIcon>
          <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
            <h4>{t("areYouSure")}</h4>
            <p className="text-muted mx-4 mb-0">{t("deleteConfirmation")}</p>
          </div>
        </div>
        <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
          <button
            type="button"
            className="btn w-sm btn-light"
            data-bs-dismiss="modal"
            onClick={onCloseClick}
          >
            {t("close")}
          </button>
          <ActivityButton
            onClick={onDeleteClick}
            title="yesDeleteIt"
            inProgressTitle="deleting"
            className="btn w-sm btn-danger"
            inProgress={loading}
            disabled={loading}
          ></ActivityButton>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default DeleteModal;
