import React, { useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import { useTranslation } from "react-i18next";

import { ProfilePicture, PersonalDetails, ChangePassword } from "./";

export const SettingsTabPage = () => {
  //#region internal state
  const [tab, setTab] = useState("1");
  const { t } = useTranslation();
  //#endregion

  document.title = `${t("documentTitle", { pageName: "settings" })}`;

  //#region handler
  const toggleTab = (changingTab: any) => {
    if (changingTab !== tab) {
      setTab(changingTab);
    }
  };
  //#endregion

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xxl={3}>
              <ProfilePicture />
            </Col>

            <Col xxl={9}>
              <Card>
                <CardHeader>
                  <Nav
                    className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
                    role="tablist"
                  >
                    <NavItem>
                      <NavLink
                        className={classnames({ active: tab === "1" })}
                        onClick={() => {
                          toggleTab("1");
                        }}
                      >
                        <i className="fas fa-home"></i>
                        {t("personalDetails")}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        to="#"
                        className={classnames({ active: tab === "2" })}
                        onClick={() => {
                          toggleTab("2");
                        }}
                        type="button"
                      >
                        <i className="far fa-user"></i>
                        {t("changePassword")}
                      </NavLink>
                    </NavItem>
                  </Nav>
                </CardHeader>
                <CardBody className="p-4">
                  <TabContent activeTab={tab}>
                    {tab === "1" && (
                      <TabPane tabId="1">
                        <PersonalDetails />
                      </TabPane>
                    )}
                    {tab === "2" && (
                      <TabPane tabId="2">
                        <ChangePassword />
                      </TabPane>
                    )}
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
