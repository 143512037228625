import { ToastContainer, toast } from "react-toastify";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { DebounceInput } from "react-debounce-input";
import { TableColumn } from "react-data-table-component";
import { Container, Row, Col, Card, CardBody, Button } from "reactstrap";

import { ProphetDto } from "../../../interfaces";
import { PaginateQuery } from "../../../models";
import { BreadCrumb } from "../../../components/Common";
import { ActionTypeEnum, ErrorHandlingTypeEnum } from "../../../enums";
import {
  ColumnHeader,
  TextColumn,
  DateColumn,
  ActionButton,
  AppTable,
  TableSpinner,
} from "../../../components";

import {
  getServerError,
  useDeleteProphetMutation,
  useLazyGetPaginatedProphetQuery,
} from "../../../services";
import DeleteModal from "../../../components/Common/DeleteModal";
import { AddEditProphetModal } from ".";

type TProps = {};
export const ProphetPage: React.FC<TProps> = () => {
  //#region hooks usage
  const history = useHistory();
  const { t, i18n } = useTranslation();
  //#endregion

  //#region others
  document.title = `${t("documentTitle", { pageName: "prophets" })}`;
  //#endregion

  //#region api
  const [
    getPaginatedItems,
    {
      isError: isErrorGetPaginatedProphets,
      error: errorGetPaginatedProphets,
      isFetching: isFetchingGetPaginatedProphets,
      data: dataGetPaginatedProphets,
    },
  ] = useLazyGetPaginatedProphetQuery();

  const [
    deleteItem,
    {
      isLoading: isLoadingDeleteProphet,
      isError: isErrorDeleteProphet,
      isSuccess: isSuccessDeleteProphet,
      error: errorDeleteProphet,
    },
  ] = useDeleteProphetMutation();

  //#endregion

  //#region internal state
  const initState = new PaginateQuery(10, "sequence:ASC");
  const [paginateQuery, setPaginateQuery] = useState<PaginateQuery>(initState);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [deletingItemId, setDeletingItemId] = useState<string | null>(null);
  const [editingItemId, setEditingItemId] = useState<string | null>(null);
  const [defaultSortAsc, setDefaultSortAsc] = useState(true);
  const [defaultPage, setDefaultPage] = useState(1);

  //#endregion

  //#region Table Columns
  const columns: TableColumn<ProphetDto>[] = [
    {
      name: <ColumnHeader title="sequence" />,
      cell: (row) => <TextColumn text={row.sequence} />,
      sortable: true,
      width: "120px",
    },
    {
      name: <ColumnHeader title="name" />,
      cell: (row) => (
        <TextColumn
          text={row.name[i18n.language === "ar" ? "ar" : "en"] as string}
        />
      ),
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">{t("Content")}</span>,
      cell: (row) => (
        <div className="d-flex gap-2 align-items-center">
          <div className="flex-grow-1">
            <Link
              to={`/menu/prophet/event/content?page=1&limit=10&sortBy=sequence:ASC&filter[types]=Prophet_Event_Content&filter[prophet]=${row.id}`}
              style={{ cursor: "pointer" }}
              className="link"
              title={t("viewEvents")}
            >
              {t("viewEvents")}
            </Link>
          </div>
        </div>
      ),
      sortable: false,
    },
    {
      name: <ColumnHeader title="createdAt" />,
      cell: (row) => <DateColumn date={row.createdAt} />,
      sortable: true,
    },
    {
      name: <ColumnHeader title="action" />,
      sortable: false,
      width: "120px",
      cell: (row) => (
        <React.Fragment>
          <ActionButton
            onAction={() => onEdit(row.id)}
            actionType={ActionTypeEnum.EDIT}
          />
          &nbsp;
          <ActionButton
            onAction={() => onDelete(row.id)}
            actionType={ActionTypeEnum.DELETE}
          />
        </React.Fragment>
      ),
    },
  ];

  //#endregion

  //#region effects

  useEffect(() => {
    const queryParams = new URLSearchParams(history.location.search);
    let query: PaginateQuery = {
      limit: queryParams.has("limit")
        ? Number(queryParams.get("limit"))
        : paginateQuery.limit,
      page: queryParams.has("page")
        ? Number(queryParams.get("page"))
        : paginateQuery.page,
      sortBy: queryParams.has("sortBy")
        ? (queryParams.get("sortBy") as string)
        : paginateQuery.sortBy,
      search: queryParams.has("search")
        ? (queryParams.get("search") as string)
        : paginateQuery.search,
      filter: queryParams.has("filter[types]")
        ? {
            ["types"]: queryParams.get("filter[types]") || "",
          }
        : paginateQuery.filter,
    };
    if (
      queryParams.get("sortBy") &&
      queryParams.get("sortBy")?.split(":").length &&
      queryParams.get("sortBy")?.split(":").length == 2
    ) {
      setDefaultSortAsc(
        (queryParams.get("sortBy")?.split(":")[1] as string) === "ASC"
      );
      setDefaultSortFieldId(
        getSortByColumnName(queryParams.get("sortBy")?.split(":")[0] as string)
      );
    } else {
      setDefaultSortAsc(false);
      setDefaultSortFieldId(2);
    }
    setDefaultPage(query.page);
    setPaginateQuery(query);
  }, []);

  useEffect(() => {
    if (paginateQuery !== initState) {
      const { limit, page, search, sortBy, filter } = paginateQuery;
      let query;
      if (page) query = `?page=${page}`;
      if (limit) query += `&limit=${limit}`;
      if (search && search !== "") {
        history.replace({
          search: `&search=${search}`,
        });
      }
      if (sortBy) query += `&sortBy=${sortBy}`;
      if (search) query += `&search=${search}`;
      if (
        filter &&
        Object.keys(filter).length &&
        Object.keys(filter).length > 0
      ) {
        let filters: string = "";
        Object.keys(filter).map((key: string) => {
          if (filter[key]) filters += `&filter[${key}]=${filter[key]}`;
        });
        query += filters;
      }
      history.replace({ search: query });
      getPaginatedItems(paginateQuery);
    }
  }, [paginateQuery]);

  useEffect(() => {
    if (!isFetchingGetPaginatedProphets && isErrorGetPaginatedProphets) {
      const _parsed = getServerError(errorGetPaginatedProphets);
      if (_parsed.type === ErrorHandlingTypeEnum.ALERT) {
        toast.error(_parsed.error, {
          autoClose: 3000,
        });
      }
    }
  }, [isFetchingGetPaginatedProphets, isErrorGetPaginatedProphets]);

  useEffect(() => {
    if (!isLoadingDeleteProphet && isErrorDeleteProphet) {
      const _parsed = getServerError(errorDeleteProphet);
      if (_parsed.type === ErrorHandlingTypeEnum.ALERT) {
        toast.error(_parsed.error, {
          autoClose: 3000,
        });
      }
    }
    if (!isLoadingDeleteProphet && isSuccessDeleteProphet) {
      setShowDeleteModal(false);
      toast.success(t("deletedSuccessfully"), { autoClose: 3000 });
    }
  }, [isLoadingDeleteProphet, isErrorDeleteProphet, isSuccessDeleteProphet]);

  //#endregion

  //#region handlers
  const getSortByColumnName = (name: string) => {
    let sortBy;
    switch (name) {
      case "sequence":
        sortBy = 1;
        break;
      case "name.en":
      case "name.ar":
        sortBy = 2;
        break;
      case "created_at":
        sortBy = 4;
        break;
      default:
        sortBy = 1;
        break;
    }
    return sortBy;
  };

  const onAdd = () => {
    setShowAddEditModal(true);
  };

  const onEdit = (id: string) => {
    setEditingItemId(id);
    setShowAddEditModal(true);
  };

  const onDelete = (id: string) => {
    setDeletingItemId(id);
    setShowDeleteModal(true);
  };

  const onDeleteConfirm = async () => {
    await deleteItem(deletingItemId as string);
    setDeletingItemId(null);
  };

  const onSearch = (searchText: string) => {
    setPaginateQuery({ ...paginateQuery, search: searchText });
  };

  const getSortByColumnId = (columnId: number, sortDirection: string) => {
    let sortBy;
    switch (columnId) {
      case 1:
        sortBy = "sequence";
        break;
      case 2:
        sortBy = "name" + "." + i18n.language;
        break;
      case 4:
        sortBy = "created_at";
        break;
      default:
        sortBy = "sequence";
        break;
    }
    return `${sortBy}:${sortDirection.toUpperCase()}`;
  };

  const onChangePage = (page: number) => {
    setPaginateQuery({ ...paginateQuery, page: page });
  };

  const onChangeRowsPerPage = (
    currentRowsPerPage: number,
    currentPage: number
  ) => {
    setPaginateQuery({
      ...paginateQuery,
      limit: currentRowsPerPage,
      page: currentPage,
    });
  };

  const onSort = (column: TableColumn<ProphetDto>, sortDirection: string) => {
    const sortBy = getSortByColumnId(column.id as number, sortDirection);
    setPaginateQuery({
      ...paginateQuery,
      sortBy: sortBy as string,
    });
  };

  //#endregion

  const [defaultSortFieldId, setDefaultSortFieldId] = useState(
    getSortByColumnName("sequence:DESC")
  );

  return (
    <React.Fragment>
      <ToastContainer newestOnTop={true} />
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t("prophets")} pageTitle={t("menu")} />
          <Row>
            <Col xxl={12}>
              <Card>
                <CardBody>
                  <Row className="g-2 mb-3">
                    <Col sm={4} className="mb-2">
                      <div className="search-box">
                        <DebounceInput
                          className="form-control"
                          placeholder={`${t("searchPlaceholder")}...`}
                          minLength={2}
                          debounceTimeout={300}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => onSearch(event.target.value)}
                          value={paginateQuery.search}
                        />
                        <i className="ri-search-line search-icon"></i>
                      </div>
                    </Col>
                    <Col className="col-sm-auto ms-auto">
                      <div className="list-grid-nav hstack gap-1">
                        <Button color="success" onClick={() => onAdd()}>
                          <i className="ri-add-fill me-1 align-bottom"></i>
                          {t("addItem", { item: "prophet" })}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12}>
                      <AppTable
                        theme={"dark"}
                        columns={columns}
                        data={dataGetPaginatedProphets?.data || []}
                        progressPending={isFetchingGetPaginatedProphets}
                        progressComponent={<TableSpinner />}
                        pagination
                        paginationServer
                        sortServer
                        onSort={onSort}
                        onChangeRowsPerPage={onChangeRowsPerPage}
                        onChangePage={onChangePage}
                        defaultSortFieldId={defaultSortFieldId}
                        paginationDefaultPage={defaultPage}
                        defaultSortAsc={defaultSortAsc}
                        persistTableHead
                        paginationTotalRows={
                          dataGetPaginatedProphets?.meta?.totalItems
                        }
                        paginationComponentOptions={{
                          rowsPerPageText: t("rowsPerPage"),
                          rangeSeparatorText: t("of"),
                        }}
                        fixedHeader={true}
                        fixedHeaderScrollHeight="60vh"
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <DeleteModal
        loading={isLoadingDeleteProphet}
        show={showDeleteModal}
        onDeleteClick={() => onDeleteConfirm()}
        onCloseClick={() => {
          setShowDeleteModal(false);
        }}
      />
      <AddEditProphetModal
        id={editingItemId}
        show={showAddEditModal}
        onCloseClick={async () => {
          setShowAddEditModal(false);
          setEditingItemId(null);
        }}
      />
    </React.Fragment>
  );
};
