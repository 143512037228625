import { useFormik } from "formik";
import React, { ChangeEvent, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";

import { CreateBannerRequest } from "../../../interfaces";
import { ActivityButton } from "../../../components/Common";
import { IMAGE_ACCEPT } from "../../../constants";
import profile from "../../../assets/images/profile.png";
import {
  useAddBannerMutation,
  useLazyGetBannerQuery,
  useUpdateBannerMutation,
  useUploadBannerImageMutation,
} from "../../../services/banner.api.service";
import { ErrorHandlingTypeEnum } from "../../../enums";
import { getServerError } from "../../../services";
import { MediaDto } from "../../../interfaces/media";

type TProps = {
  id?: string | null;
  show: boolean;
  onCloseClick: (event: any) => void;
};
export const AddEditBannerModal: React.FC<TProps> = ({
  id,
  show,
  onCloseClick,
}) => {
  //#region hooks usage
  const { t } = useTranslation();
  //#endregion

  //#region api
  const [
    addBanner,
    {
      isError: isErrorAddBanner,
      isLoading: isLoadingAddBanner,
      error: errorAddBanner,
      isSuccess: isSuccessAddBanner,
      data: dataAddBanner,
    },
  ] = useAddBannerMutation();

  const [
    getBanner,
    {
      isError: isErrorGetBanner,
      isLoading: isLoadingGetBanner,
      error: errorGetBanner,
      data: dataGetBanner,
    },
  ] = useLazyGetBannerQuery();

  const [
    updateBanner,
    {
      isError: isErrorUpdateBanner,
      isLoading: isLoadingUpdateBanner,
      error: errorUpdateBanner,
      isSuccess: isSuccessUpdateBanner,
      data: dataUpdateBanner,
    },
  ] = useUpdateBannerMutation();

  const [
    uploadBannerImage,
    {
      isLoading: isLoadingUploadBannerImage,
      isSuccess: isSuccessUploadBannerImage,
      isError: isErrorUploadBannerImage,
      error: errorUploadBannerImage,
      data: dataUploadBannerImage,
    },
  ] = useUploadBannerImageMutation();

  //#endregion

  //#region form
  const form = useFormik<CreateBannerRequest>({
    enableReinitialize: true,
    initialValues: {
      title: {
        en: dataGetBanner?.title.en || "",
        ar: dataGetBanner?.title.ar || "",
      },
      description: {
        en: dataGetBanner?.description.en || "",
        ar: dataGetBanner?.description.ar || "",
      },
      imageId: dataGetBanner?.image?.id,
    },
    validationSchema: Yup.object({
      title: Yup.object({
        en: Yup.string()
          .max(
            100,
            t("maxLength", { maxLength: 100, displayName: "englishValue" })
          )
          .required(
            t("isNotEmpty", {
              displayName: "englishValue",
            })
          ),
        ar: Yup.string()
          .max(
            100,
            t("maxLength", { maxLength: 100, displayName: "arabicValue" })
          )
          .required(
            t("isNotEmpty", {
              displayName: "arabicValue",
            })
          ),
      }),
      description: Yup.object({
        en: Yup.string()
          .max(
            100,
            t("maxLength", { maxLength: 100, displayName: "englishValue" })
          )
          .required(
            t("isNotEmpty", {
              displayName: "englishValue",
            })
          ),
        ar: Yup.string()
          .max(
            100,
            t("maxLength", { maxLength: 100, displayName: "arabicValue" })
          )
          .required(
            t("isNotEmpty", {
              displayName: "arabicValue",
            })
          ),
      }),
    }),
    onSubmit: async (values) => {
      if (id) {
        await updateBanner({ ...values, id: id });
      } else {
        await addBanner(values);
      }
    },
  });
  //#endregion

  //#region effects
  useEffect(() => {
    if (
      (!isLoadingAddBanner && isSuccessAddBanner) ||
      (!isLoadingUpdateBanner && isSuccessUpdateBanner)
    ) {
      onClose();
    }
  }, [isLoadingAddBanner, isLoadingUpdateBanner]);

  useEffect(() => {
    if (id) {
      getBanner(id);
    }
  }, [id]);

  useEffect(() => {
    if (id && dataGetBanner) {
      form.setValues({
        title: dataGetBanner.title,
        description: dataGetBanner.description,
      });
    }
  }, [dataGetBanner]);

  useEffect(() => {
    if (!isLoadingGetBanner && isErrorGetBanner) {
      const _parsed = getServerError(errorGetBanner);
      if (_parsed.type === ErrorHandlingTypeEnum.ALERT) {
        toast.error(_parsed.error, {
          autoClose: 3000,
        });
      }
    }
  }, [isLoadingGetBanner]);

  useEffect(() => {
    if (!isLoadingAddBanner && isErrorAddBanner) {
      const _parsed = getServerError(errorAddBanner);
      if (_parsed.type === ErrorHandlingTypeEnum.FORM) {
        form.setErrors(_parsed.error);
      }
      if (_parsed.type === ErrorHandlingTypeEnum.ALERT) {
        toast.error(_parsed.error, {
          autoClose: 3000,
        });
      }
    }
    if (!isLoadingAddBanner && isSuccessAddBanner && dataAddBanner) {
      toast.success(t("savedSuccessfully"), {
        autoClose: 3000,
      });
    }
  }, [isLoadingAddBanner, isSuccessAddBanner]);

  useEffect(() => {
    if (!isLoadingUpdateBanner && isErrorUpdateBanner) {
      const _parsed = getServerError(errorUpdateBanner);
      if (_parsed.type === ErrorHandlingTypeEnum.FORM) {
        form.setErrors(_parsed.error);
      }
      if (_parsed.type === ErrorHandlingTypeEnum.ALERT) {
        toast.error(_parsed.error, {
          autoClose: 3000,
        });
      }
    }
    if (!isLoadingUpdateBanner && isSuccessUpdateBanner && dataUpdateBanner) {
      toast.success(t("savedSuccessfully"), {
        autoClose: 3000,
      });
    }
  }, [isLoadingUpdateBanner, isSuccessUpdateBanner]);

  useEffect(() => {
    if (!isLoadingUploadBannerImage && isErrorUploadBannerImage) {
      const _parsed = getServerError(errorUploadBannerImage);
      if (_parsed.type === ErrorHandlingTypeEnum.ALERT) {
        toast.error(_parsed.error, {
          autoClose: 3000,
        });
      }
    }
    if (
      !isLoadingUploadBannerImage &&
      isSuccessUploadBannerImage &&
      dataUploadBannerImage
    ) {
      form.setFieldValue("imageId", (dataUploadBannerImage as MediaDto).id);
    }
  }, [isLoadingUploadBannerImage, isSuccessUploadBannerImage]);

  //#endregion

  //#region handlers
  const onClose = () => {
    form.resetForm();
    onCloseClick(!show);
  };

  const onChangeBannerImage = async (event: ChangeEvent<HTMLInputElement>) => {
    const { target } = event;
    const { files } = target;
    if (files && files.length > 0) {
      const imageFile = files[0];
      if (!imageFile.name.match(/\.(jpg|jpeg|jfif|pjpeg|pjp|png|webp|gif)$/)) {
        toast.error(t("selectValidImage"), {
          autoClose: 3000,
        });
        return false;
      }
      await uploadBannerImage({ id: dataGetBanner?.id, media: imageFile });
    }
  };

  //#endregion

  return (
    <React.Fragment>
      <ToastContainer />
      <Modal
        id="addEdit"
        isOpen={show}
        toggle={() => {
          onClose();
        }}
        centered
        className="modal-md"
      >
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            form.handleSubmit();
            return false;
          }}
          action="#"
        >
          <ModalHeader
            className="p-3"
            toggle={() => {
              onClose();
            }}
          >
            {id
              ? t("editItem", { item: "image" })
              : t("addItem", { item: "image" })}
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col sm={12}>
                {/* Image */}
                <div className="col-auto text-center">
                  <div className="profile-user position-relative d-inline-block mx-auto mb-4">
                    <img
                      src={
                        dataUploadBannerImage?.path ||
                        dataGetBanner?.image?.path ||
                        profile
                      }
                      className="rounded-circle avatar-xl img-thumbnail user-profile-image"
                      alt="user-profile"
                    />
                    <div className="avatar-xl p-0 rounded-circle profile-photo-edit">
                      <Input
                        id="banner_image"
                        type="file"
                        className="profile-img-file-input"
                        accept={IMAGE_ACCEPT}
                        onChange={onChangeBannerImage}
                        disabled={isLoadingUploadBannerImage}
                      />
                      <Label
                        htmlFor="banner_image"
                        className="profile-photo-edit avatar-xs"
                      >
                        <span className="avatar-title rounded-circle bg-light text-body">
                          {isLoadingUploadBannerImage && <Spinner />}
                          {!isLoadingUploadBannerImage && (
                            <i className="ri-camera-fill"></i>
                          )}
                        </span>
                      </Label>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <div className="mb-3">
              <label htmlFor="title.en" className="form-label">
                {t("titleEn")}
                <span className="text-danger">*</span>
              </label>
              <Input
                autoComplete="off"
                auto
                id="title.en"
                name="title.en"
                value={form.values.title?.en}
                type="text"
                className="form-control"
                placeholder={t("enterInEnglish")}
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                invalid={
                  form.touched.title?.en && form.errors.title?.en ? true : false
                }
              />
              {form.touched.title?.en && form.errors.title?.en ? (
                <FormFeedback type="invalid">
                  {form.errors.title?.en as string}
                </FormFeedback>
              ) : null}
            </div>
            <div className="mb-3">
              <label htmlFor="title.ar" className="form-label">
                {t("nameAr")}
                <span className="text-danger">*</span>
              </label>
              <Input
                style={{ fontFamily: "aljannat", direction: "rtl" }}
                autoComplete="off"
                auto
                id="title.ar"
                name="title.ar"
                value={form.values.title?.ar}
                type="text"
                className="form-control"
                placeholder={t("enterInArabic")}
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                invalid={
                  form.touched.title?.ar && form.errors.title?.ar ? true : false
                }
              />
              {form.touched.title?.ar && form.errors.title?.ar ? (
                <FormFeedback type="invalid">
                  {form.errors.title?.ar as string}
                </FormFeedback>
              ) : null}
            </div>
            <div className="mb-3">
              <label htmlFor="description.en" className="form-label">
                {t("descriptionEn")}
                <span className="text-danger">*</span>
              </label>
              <Input
                autoComplete="off"
                auto
                id="description.en"
                name="description.en"
                value={form.values.description?.en}
                type="text"
                className="form-control"
                placeholder={t("enterInEnglish")}
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                invalid={
                  form.touched.description?.en && form.errors.description?.en
                    ? true
                    : false
                }
              />
              {form.touched.description?.en && form.errors.description?.en ? (
                <FormFeedback type="invalid">
                  {form.errors.description?.en as string}
                </FormFeedback>
              ) : null}
            </div>
            <div className="mb-3">
              <label htmlFor="description.ar" className="form-label">
                {t("descriptionAr")}
                <span className="text-danger">*</span>
              </label>
              <Input
                autoComplete="off"
                auto
                id="description.ar"
                name="description.ar"
                value={form.values.description?.ar}
                type="text"
                className="form-control"
                placeholder={t("enterInArabic")}
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                invalid={
                  form.touched.description?.ar && form.errors.description?.ar
                    ? true
                    : false
                }
              />
              {form.touched.description?.ar && form.errors.description?.ar ? (
                <FormFeedback type="invalid">
                  {form.errors.description?.ar as string}
                </FormFeedback>
              ) : null}
            </div>
          </ModalBody>
          <ModalFooter>
            <Link
              to={"#"}
              onClick={onClose}
              className="btn btn-link link-success fw-medium"
            >
              <i className="ri-close-line me-1 align-middle"></i> {t("close")}
            </Link>
            <ActivityButton
              title="save"
              inProgressTitle="saving"
              color="success"
              className="btn btn-success"
              type="submit"
              inProgress={isLoadingAddBanner || isLoadingUpdateBanner}
              disabled={
                isLoadingAddBanner ||
                isLoadingUpdateBanner ||
                isErrorAddBanner ||
                isLoadingUpdateBanner
              }
            ></ActivityButton>
          </ModalFooter>
        </Form>
      </Modal>
    </React.Fragment>
  );
};
