import { useTranslation } from "react-i18next";
import { LordIcon } from "../Common/LordIcon";

export const TableSpinner = () => {
  const { t } = useTranslation();

  return (
    <div className="py-4 mt-4 text-center">
      <LordIcon
        src="https://cdn.lordicon.com/msoeawqm.json"
        trigger="loop"
        colors="primary:#242643,secondary:#0ab39c"
        style={{ width: "72px", height: "72px" }}
      ></LordIcon>
      <h6 className="mt-4">{t("loadingData")}...</h6>
    </div>
  );
};
