import { createApi } from "@reduxjs/toolkit/query/react";

import { Paginated, SelectListDto } from "../models";
import { baseQueryWithInterceptor } from "./base.api";
import { convertObjectToQueryParams } from "./helper.service";
import {
  PaginateQuery,
  ContentDto,
  CreateContentRequest,
  UpdateContentRequest,
  UpdateMediaRequest,
  LocalizedProperty,
  TranslateRequest,
} from "../interfaces";
import { ContentTypeEnum } from "../enums";
import { MediaDto } from "../interfaces/media";

export const contentApi = createApi({
  reducerPath: "contentApi",
  baseQuery: baseQueryWithInterceptor,
  endpoints: (builder) => ({
    getPaginatedContent: builder.query<Paginated<ContentDto>, PaginateQuery>({
      query: (query) => ({
        url: `/content/paginate?${convertObjectToQueryParams(query)}`,
        method: "GET",
      }),
      providesTags: ["contents"],
    }),
    getContentById: builder.query<ContentDto, string>({
      query: (id) => ({
        url: `/content/${id}`,
        method: "GET",
      }),
      providesTags: ["content-by-id"],
    }),
    deleteContent: builder.mutation<void, string>({
      query: (id) => ({
        url: `/content/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["contents", "content-select-list"],
    }),
    addContent: builder.mutation<void, CreateContentRequest>({
      query: (body) => ({
        url: `/content/${body.type}`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["contents", "content-select-list"],
    }),
    updateContent: builder.mutation<void, UpdateContentRequest>({
      query: (body) => ({
        url: `/content/${body.id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["contents", "content-select-list"],
    }),
    getContentSelectList: builder.query<
      SelectListDto[],
      {
        type: ContentTypeEnum;
        parentId: string | null;
        prophetId: string | null;
      }
    >({
      query: ({ type, parentId, prophetId }) => ({
        url: parentId
          ? `/content/select-list/${type}?parentId=${parentId}`
          : prophetId
          ? `/content/select-list/${type}?prophetId=${prophetId}`
          : `/content/select-list/${type}`,
        method: "GET",
      }),
      providesTags: ["content-select-list"],
    }),
    toggleContentState: builder.mutation<void, string>({
      query: (id) => ({
        url: `/content/${id}/toggle-state`,
        method: "PATCH",
      }),
      invalidatesTags: ["contents"],
    }),
    uploadContentImage: builder.mutation<MediaDto, UpdateMediaRequest>({
      query: (body) => {
        var bodyFormData = new FormData();
        bodyFormData.append("file", body.media);
        let url = `/content/upload/image`;
        if (body.id) url += `?id=${body.id}`;
        return {
          url,
          method: "POST",
          body: bodyFormData,
          formData: true,
        };
      },
    }),
    generateTranslations: builder.mutation<LocalizedProperty, TranslateRequest>(
      {
        query: (body) => ({
          url: `/content/text/generate-translations`,
          method: "POST",
          body,
        }),
      }
    ),
  }),
  tagTypes: ["contents", "content-select-list", "content-by-id"],
});

export const {
  useLazyGetPaginatedContentQuery,
  useLazyGetContentByIdQuery,
  useDeleteContentMutation,
  useAddContentMutation,
  useUpdateContentMutation,
  useLazyGetContentSelectListQuery,
  useToggleContentStateMutation,
  useUploadContentImageMutation,
  useGenerateTranslationsMutation,
} = contentApi;
