import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Form,
  FormFeedback,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { InputType } from "reactstrap/types/lib/Input";
import { ToastContainer, toast } from "react-toastify";

import logo from "../../assets/images/logo.png";
import { setCredentials } from "../../store/auth";
import { ErrorHandlingTypeEnum } from "../../enums";
import { ParticlesAuth } from "../authInner";
import artboard from "../../assets/images/Artboard.png";
import { ActivityButton } from "../../components/Common";
import { getServerError, useSigninMutation } from "../../services";
import LanguageDropdown from "../../components/Common/LanguageDropdown";

export const LoginPage = () => {
  //#region hooks usage
  const { t } = useTranslation();
  const dispatch = useDispatch();
  //#endregion

  //#region others
  document.title = `${t("documentTitle", { pageName: "login" })}`;
  //#endregion

  //#region internal states
  const [passwordType, setPasswordType] = useState<InputType>("password");
  //#endregion

  //#region api
  const [
    signin,
    {
      isError: isErrorSignin,
      isLoading: isLoadingSignin,
      error: errorSignin,
      isSuccess: isSuccessSignin,
      data: dataSignin,
    },
  ] = useSigninMutation();

  //#endregion

  //#region form
  const form = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email(
          t("isEmail", {
            displayName: "email",
          })
        )
        .max(100, t("maxLength", { maxLength: 100, displayName: "email" }))
        .required(
          t("isNotEmpty", {
            displayName: "email",
          })
        ),
      password: Yup.string().required(
        t("isNotEmpty", { displayName: "password" })
      ),
    }),
    onSubmit: async (values) => {
      signin(values);
    },
  });
  //#endregion

  //#region handlers
  /**
   * Password show hide
   */
  const handleClickShowPassword = () => {
    if (passwordType === "text") setPasswordType("password");
    else setPasswordType("text");
  };

  //#endregion

  //#region effects

  useEffect(() => {
    if (!isLoadingSignin && isErrorSignin) {
      const _parsed = getServerError(errorSignin);
      if (_parsed.type === ErrorHandlingTypeEnum.FORM) {
        form.setErrors(_parsed.error);
      }
      if (_parsed.type === ErrorHandlingTypeEnum.ALERT) {
        toast.error(_parsed.error, {
          autoClose: 3000,
        });
      }
    }
    if (!isLoadingSignin && isSuccessSignin && dataSignin) {
      if (dataSignin.refreshToken && dataSignin.token) {
        dispatch(setCredentials(dataSignin));
      } else {
        if (dataSignin.emailVerificationRequired) {
          toast.info(t("checkEmailToSetPassword"), {
            autoClose: 3000,
          });
        }
      }
    }
  }, [isLoadingSignin]);

  //#endregion

  return (
    <React.Fragment>
      <ToastContainer newestOnTop={true} />
      <ParticlesAuth>
        <div className="auth-page-content">
          <Container>
            <Row className="justify-content-center ">
              <Col>
                <Card className="custom-radius">
                  <CardBody>
                    <Row>
                      <Col
                        md={6}
                        lg={6}
                        xl={6}
                        className="d-none d-md-block align-self-center "
                      >
                        <img src={artboard} alt="" width="100%" />
                      </Col>
                      <Col md={6} lg={6} xl={6} className="padding mt-3">
                        <div className="d-flex justify-content-center">
                          <img src={logo} height="100" width="100" />
                        </div>
                        <div className="p-2 mt-4">
                          <div className="justify-content-center align-items-center d-flex">
                            <LanguageDropdown />
                          </div>
                          <Form
                            onSubmit={(e) => {
                              e.preventDefault();
                              form.handleSubmit();
                              return false;
                            }}
                            action="#"
                          >
                            <div className="mb-3">
                              <Label htmlFor="email" className="form-label">
                                {t("email")}
                              </Label>
                              <span className="text-danger">*</span>
                              <Input
                                autoComplete="off"
                                id="email"
                                name="email"
                                className="form-control"
                                placeholder={t("enterEmail")}
                                type="text"
                                onBlur={form.handleBlur}
                                value={form.values.email || ""}
                                invalid={
                                  form.touched.email && form.errors.email
                                    ? true
                                    : false
                                }
                                onChange={form.handleChange}
                              />
                              {form.touched.email && form.errors.email ? (
                                <FormFeedback type="invalid">
                                  {form.errors.email as string}
                                </FormFeedback>
                              ) : null}
                            </div>

                            <div className="mb-3">
                              <div className="float-end">
                                <Link
                                  tabIndex={-1}
                                  to="/forget-password"
                                  className="text-muted"
                                >
                                  {t("forgetPassword?")}
                                </Link>
                              </div>
                              <Label
                                className="form-label"
                                htmlFor="password-input"
                              >
                                {t("password")}
                              </Label>
                              <span className="text-danger">*</span>
                              <div className="position-relative auth-pass-inputgroup mb-3">
                                <Input
                                  id="password-input"
                                  name="password"
                                  value={form.values.password || ""}
                                  type={passwordType}
                                  className="form-control"
                                  placeholder={t("enterPassword")}
                                  onChange={form.handleChange}
                                  onBlur={form.handleBlur}
                                  invalid={
                                    form.touched.password &&
                                    form.errors.password
                                      ? true
                                      : false
                                  }
                                  maxLength={25}
                                />
                                {form.touched.password &&
                                form.errors.password ? (
                                  <FormFeedback type="invalid">
                                    {form.errors.password as string}
                                  </FormFeedback>
                                ) : null}
                                <button
                                  className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                                  type="button"
                                  id="password-addon"
                                >
                                  <i
                                    className="ri-eye-fill align-middle"
                                    onClick={handleClickShowPassword}
                                  ></i>
                                </button>
                              </div>
                            </div>
                            <div className="mt-4">
                              <ActivityButton
                                title="login"
                                inProgressTitle="loggingIn"
                                color="success"
                                className="btn btn-success w-100"
                                type="submit"
                                inProgress={isLoadingSignin}
                                disabled={isLoadingSignin}
                              ></ActivityButton>
                            </div>
                          </Form>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </ParticlesAuth>
    </React.Fragment>
  );
};
