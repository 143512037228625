import i18n from "i18next";
import HttpApi from "i18next-http-backend";
import Backend from "i18next-chained-backend";
import { initReactI18next } from "react-i18next";
import detector from "i18next-browser-languagedetector";

const language = localStorage.getItem("i18nextLng");
if (!language) {
  localStorage.setItem("i18nextLng", "en");
}

const options =
  process.env.NODE_ENV === "development"
    ? {
        backends: [
          HttpApi, // primary
        ],
        backendOptions: [
          {
            /* options for primary backend */
            loadPath: `${process.env.REACT_APP_API_URL}/i18n/{lng}/{ns}.json`,
          },
        ],
      }
    : {
        backends: [
          // LocalStorageBackend, // primary
          HttpApi, // fallback backend
        ],
        backendOptions: [
          // {
          //   expirationTime: 7 * 24 * 60 * 60 * 1000,
          //   versions: { en: 1, ar: 1 },
          // },
          {
            /* options for secondary backend */
            loadPath: `${process.env.REACT_APP_API_URL}/i18n/{lng}/{ns}.json`,
          },
        ],
      };

i18n
  .use(detector)
  .use(Backend)
  .use(initReactI18next)
  .init({
    // resources,
    lng: localStorage.getItem("i18nextLng") || "en",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
      prefix: "{",
      suffix: "}",
    },
    backend: options,
  });

export default i18n;
