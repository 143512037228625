import {
  Accordion,
  AccordionItem,
  Col,
  Collapse,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import classnames from "classnames";

import { AppRichEditor } from "../Common";
import { Languages } from "../../constants/language.constant";
import { LocalizedProperty } from "../../interfaces";
import { InputLabelButton } from "./InputLabelButton";
import {
  getByKey,
  getServerError,
  useGenerateTranslationsMutation,
} from "../../services";
import { ErrorHandlingTypeEnum, LanguageEnum } from "../../enums";
import { toast } from "react-toastify";

type TProps = {
  name: string;
  form: any;
  initialValue: LocalizedProperty | undefined;
  required?: boolean;
  onClose?: () => void;
  onCompletedTranslation: (translation: LocalizedProperty) => void;
};
export const RichTextEditorLocalized: React.FC<TProps> = ({
  name,
  form,
  initialValue,
  required = true,
  onClose,
  onCompletedTranslation,
}) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);

  //#region api
  const [
    generateTranslations,
    {
      isLoading: isLoadingGenerateTranslations,
      isError: isErrorGenerateTranslations,
      isSuccess: isSuccessGenerateTranslations,
      error: errorGenerateTranslations,
      data: dataGenerateTranslations,
    },
  ] = useGenerateTranslationsMutation();

  //#endregion

  //#region effects
  useEffect(() => {
    if (!isLoadingGenerateTranslations && isErrorGenerateTranslations) {
      const _parsed = getServerError(errorGenerateTranslations);
      if (_parsed.type === ErrorHandlingTypeEnum.FORM) {
        form.setErrors(_parsed.error);
      }
      if (_parsed.type === ErrorHandlingTypeEnum.ALERT) {
        toast.error(_parsed.error, {
          autoClose: 3000,
        });
      }
    }
    if (
      !isLoadingGenerateTranslations &&
      isSuccessGenerateTranslations &&
      dataGenerateTranslations
    ) {
      toast.success(t("translatedSuccessfully"), {
        autoClose: 3000,
      });

      onCompletedTranslation(dataGenerateTranslations);
      Object.keys(dataGenerateTranslations).forEach((_key: string) => {
        form.setFieldTouched(`${name}.${_key}`, true, true);
        form.setFieldValue(
          `${name}.${_key}`,
          getByKey(dataGenerateTranslations, _key)
        );
      });
    }
  }, [
    isLoadingGenerateTranslations,
    isSuccessGenerateTranslations,
    isErrorGenerateTranslations,
  ]);
  //#endregion

  //#region handlers
  const onGenerateTranslation = async () => {
    await generateTranslations({
      text: form.values[name]["ar"],
      textLanguage: LanguageEnum.AR,
    });
  };
  //#endregion

  const [col, setCol] = useState<boolean[]>(
    Object.keys(Languages)
      .filter((key: string) => key !== "ar")
      .map((key: string, index: number) => (index === 0 ? true : false))
  );

  const toggleCol = (index: number, currentState: boolean) => {
    setCol(
      col.map((value: boolean, _index: number) =>
        _index === index
          ? !currentState
          : !currentState === true
          ? false
          : value
      )
    );
  };

  return (
    <React.Fragment>
      {Object.keys(Languages)
        .filter((key) => key === "ar")
        .map((key: string) => {
          return (
            <div className="mb-3" key={key}>
              <div className="d-flex justify-content-between align-items-end">
                <Label htmlFor={`${name}.${key}`} className="form-label">
                  {t(name)}
                  {required && <span className="text-danger">*</span>}
                </Label>
                <InputLabelButton
                  onClick={() => setShow(true)}
                  title={"addTranslation"}
                  riIconName={"ri-translate-2"}
                  disabled={false}
                  isInProgress={false}
                />
              </div>
              <AppRichEditor
                directionality={
                  Languages[key].writingDirection as "rtl" | "ltr"
                }
                initialValue={getByKey(initialValue, key)}
                onChange={(data: any) => {
                  form.setFieldValue(`${name}.${key}`, data);
                }}
                onKeyUp={(data: any) => {
                  if (data.trim().length == 0 && required === true) {
                    form.setFieldError(
                      `${name}.${key}`,
                      t("isNotEmpty", {
                        displayName: "value",
                      })
                    );
                  } else {
                    form.setFieldError(`${name}.${key}`, "");
                  }
                }}
                onFocus={() => {
                  form.setFieldTouched(`${name}.${key}`, true, true);
                }}
              />
              {form.touched &&
              form.touched[name] &&
              form.touched[name][key] &&
              form.errors &&
              form.errors[name] &&
              form.errors[name][key] ? (
                <Label className="invalid-feedback d-block">
                  {form.errors[name][key]}
                </Label>
              ) : null}
            </div>
          );
        })}
      <Modal
        id={name}
        isOpen={show}
        toggle={() => {
          setShow(false);
          onClose && onClose();
        }}
        centered
        className="modal-dialog modal-xl"
        scrollable={true}
      >
        <ModalHeader className="p-3">
          {t(name)} {t("localization")}
        </ModalHeader>
        <ModalBody>
          <div className="d-flex justify-content-end">
            <InputLabelButton
              onClick={() => onGenerateTranslation()}
              title={"generateTranslation"}
              riIconName={"ri-translate-2"}
              disabled={
                isLoadingGenerateTranslations ||
                (form.errors &&
                  form.errors[name] &&
                  form.errors[name]["ar"] &&
                  form.errors[name]["ar"])
              }
              isInProgress={isLoadingGenerateTranslations}
            />
          </div>
          <Accordion id="default-accordion-example" toggle={() => {}} open={""}>
            {Object.keys(Languages)
              .filter((key) => key !== "ar")
              .map((key: string, index: number) => {
                return (
                  <AccordionItem key={key}>
                    <h2 className="accordion-header" id="headingOne">
                      <button
                        className={classnames("accordion-button", {
                          collapsed: !col[index],
                        })}
                        type="button"
                        onClick={() => toggleCol(index, col[index])}
                        style={{ cursor: "pointer" }}
                      >
                        {Languages[key].nameInLanguage[key]}{" "}
                        {required && <span className="text-danger">*</span>}
                      </button>
                    </h2>
                    <Collapse
                      isOpen={col[index]}
                      className="accordion-collapse"
                      id={`collapse${index}`}
                    >
                      <div className="accordion-body">
                        <Col className="col-12">
                          <div className="mb-3">
                            <AppRichEditor
                              initialValue={getByKey(initialValue, key)}
                              onChange={(data: any) => {
                                form.setFieldValue(`${name}.${key}`, data);
                              }}
                              directionality={
                                Languages[key].writingDirection as "rtl" | "ltr"
                              }
                              onKeyUp={(data: any) => {
                                if (
                                  data.trim().length == 0 &&
                                  required === true
                                ) {
                                  form.setFieldError(
                                    `${name}.${key}`,
                                    t("isNotEmpty", {
                                      displayName: "value",
                                    })
                                  );
                                } else {
                                  form.setFieldError(`${name}.${key}`, "");
                                }
                              }}
                              onFocus={() => {
                                form.setFieldTouched(
                                  `${name}.${key}`,
                                  true,
                                  true
                                );
                              }}
                            />
                            {form.touched &&
                            form.touched[name] &&
                            form.touched[name][key] &&
                            form.errors &&
                            form.errors[name] &&
                            form.errors[name][key] ? (
                              <Label className="invalid-feedback d-block">
                                {form.errors[name][key]}
                              </Label>
                            ) : null}
                          </div>
                        </Col>
                      </div>
                    </Collapse>
                  </AccordionItem>
                );
              })}
          </Accordion>
        </ModalBody>
        <ModalFooter>
          <Link
            to={"#"}
            onClick={() => {
              setShow(false);
              onClose && onClose();
            }}
            className="btn btn-success fw-medium"
          >
            {t("close")}
          </Link>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};
