import { createApi } from "@reduxjs/toolkit/query/react";

import { Paginated } from "../models";
import {
  CalendarEventDto,
  CreateCalendarEventRequest,
  PaginateQuery,
  UpdateCalendarEventRequest,
} from "../interfaces";
import { baseQueryWithInterceptor } from "./base.api";

export const calendarApi = createApi({
  reducerPath: "calendarApi",
  baseQuery: baseQueryWithInterceptor,
  endpoints: (builder) => ({
    getCalendarEvents: builder.query<
      Paginated<CalendarEventDto>,
      PaginateQuery
    >({
      query: () => ({
        url: `/calendar`,
        method: "GET",
      }),
      providesTags: ["paginated_calendar_events"],
    }),
    getAllCalendarEvents: builder.query<CalendarEventDto[], null>({
      query: () => ({
        url: `/calendar/all`,
        method: "GET",
      }),
      providesTags: ["all_calendar_events"],
    }),
    getCalendarEvent: builder.query<CalendarEventDto, string>({
      query: (id) => ({
        url: `/calendar/${id}`,
        method: "GET",
      }),
    }),
    addCalendarEvent: builder.mutation<
      CalendarEventDto,
      CreateCalendarEventRequest
    >({
      query: (body) => ({
        url: `/calendar`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["paginated_calendar_events", "all_calendar_events"],
    }),
    updateCalendarEvent: builder.mutation<
      CalendarEventDto,
      UpdateCalendarEventRequest
    >({
      query: (body) => ({
        url: `/calendar/${body.id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["paginated_calendar_events", "all_calendar_events"],
    }),
    deleteCalendarEvent: builder.mutation<string, string>({
      query: (id) => ({
        url: `/calendar/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["paginated_calendar_events", "all_calendar_events"],
    }),
  }),
  tagTypes: ["paginated_calendar_events", "all_calendar_events"],
});

export const {
  useLazyGetAllCalendarEventsQuery,
  useLazyGetCalendarEventsQuery,
  useLazyGetCalendarEventQuery,
  useAddCalendarEventMutation,
  useUpdateCalendarEventMutation,
  useDeleteCalendarEventMutation,
} = calendarApi;
