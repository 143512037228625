import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { LocalizedProperty, ParsedError } from "../interfaces";
import { ErrorHandlingTypeEnum } from "../enums";
const _ = require("lodash");

export const formatFileSize = (bytes: number, decimalPoint?: number) => {
  if (bytes == 0) return "0 Bytes";
  var k = 1000,
    dm = decimalPoint || 2,
    sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
    i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

export const getShortenName = (name?: string) => {
  if (name && name.length > 0) {
    let shortName = name.split(" ")[0];
    if (shortName.length > 20) return shortName.substring(0, 20);
    else return shortName;
  } else {
    return "";
  }
};

export const getServerError = (
  error: FetchBaseQueryError | SerializedError | undefined
): ParsedError => {
  if ((error as FetchBaseQueryError).data) {
    const errorData: any = (error as FetchBaseQueryError).data;
    if (errorData.hasOwnProperty("errors")) {
      return { type: ErrorHandlingTypeEnum.FORM, error: errorData.errors };
    }
    if (errorData.hasOwnProperty("message")) {
      return { type: ErrorHandlingTypeEnum.ALERT, error: errorData.message };
    }
  }
  return { type: ErrorHandlingTypeEnum.UNKNOWN, error: "An error occurred" };
};

export const convertObjectToQueryParams = (obj: any) => {
  return Object.keys(obj)
    .map((key) => {
      if (typeof obj[key] === "object") {
        return Object.keys(obj[key])
          .map((subKey) => `${key}[${subKey}]=${obj[key][subKey]}`)
          .join("&");
      }
      return `${key}=${obj[key]}`;
    })
    .join("&");
};

export const getByKey = (
  value: LocalizedProperty | undefined,
  key: string
): string => {
  switch (key) {
    case "en":
      return value?.en || "";
    case "ar":
      return value?.ar || "";
    case "id":
      return value?.id || "";
    case "ur":
      return value?.ur || "";
    case "ru":
      return value?.ru || "";
    case "zh":
      return value?.zh || "";
    case "tr":
      return value?.tr || "";
    case "de":
      return value?.de || "";
    case "es":
      return value?.es || "";
    case "fr":
      return value?.fr || "";
    default:
      return "";
  }
};
