import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

import { useAuth, useUser } from "../hooks";
import { clearUser } from "../../store/account";
import { clearCredentials } from "../../store/auth";
import { getShortenName, useSignoutMutation } from "../../services";
import avatar from "../../assets/images/users/user-dummy-img.jpg";

export const ProfileDropdown = () => {
  //#region hooks usage
  const { user } = useUser();
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { auth: session } = useAuth();
  const dispatch = useDispatch();
  //#endregion

  //#region internal state
  const [isProfileDropdown, setIsProfileDropdown] = useState(false);
  //#endregion

  //#region api
  const [signout] = useSignoutMutation();
  //#endregion

  //#region handlers
  const onSignout = async () => {
    if (session && session.refreshToken) {
      await signout({ refreshToken: session?.refreshToken });
      dispatch(clearUser());
      dispatch(clearCredentials());
      history.push("/login");
    }
  };

  const toggleProfileDropdown = () => {
    setIsProfileDropdown(!isProfileDropdown);
  };

  //#endregion

  return (
    <React.Fragment>
      <Dropdown
        isOpen={isProfileDropdown}
        toggle={toggleProfileDropdown}
        className="ms-sm-3 header-item topbar-user"
      >
        <DropdownToggle tag="button" type="button" className="btn">
          <span className="d-flex align-items-center ">
            <img
              className="rounded-circle header-profile-user"
              src={user?.profilePhoto || avatar}
              alt="Header Avatar"
            />
            <span className="text-start ms-xl-2">
              <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">
                {i18n.language === "ar"
                  ? getShortenName(user?.name?.ar)
                  : getShortenName(user?.name?.en)}
              </span>
              <span className="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text">
                {i18n.language === "ar"
                  ? user?.role?.name?.ar
                  : user?.role?.name?.en}
              </span>
            </span>
          </span>
        </DropdownToggle>
        <DropdownMenu
          className="dropdown-menu-end"
          style={{ textAlign: "initial" }}
        >
          <h6 className="dropdown-header">
            {t("welcome")}{" "}
            {i18n.language === "ar"
              ? getShortenName(user?.name?.ar)
              : getShortenName(user?.name?.en)}
            !
          </h6>
          <Link to="/settings">
            <DropdownItem style={{ textAlign: "initial" }}>
              <i className="mdi mdi-cog-outline text-muted fs-16 align-middle me-1"></i>{" "}
              <span className="align-middle">{t("settings")}</span>
            </DropdownItem>
          </Link>
          <DropdownItem onClick={onSignout} style={{ textAlign: "initial" }}>
            <i className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>{" "}
            <span className="align-middle" data-key="t-logout">
              {t("signout")}
            </span>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};
