import React from "react";
import moment from "moment";
import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useState } from "react";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { Calendar, momentLocalizer, SlotInfo } from "react-big-calendar";

import { AddEditEventModal } from "./AddEditEventModal";
import { CalendarEventDto } from "../../../interfaces";
import { useLazyGetAllCalendarEventsQuery } from "../../../services/calendar.api.service";

require("globalize/lib/cultures/globalize.culture.en-GB");
require("globalize/lib/cultures/globalize.culture.es");
require("globalize/lib/cultures/globalize.culture.fr");
require("globalize/lib/cultures/globalize.culture.ar-AE");

const localizer = momentLocalizer(moment);

export const AcademicCalendarPage = () => {
  //#region hooks usage
  const { t, i18n } = useTranslation();
  //#endregion

  //#region others
  document.title = `${t("documentTitle", {
    pageName: "controlAppAcademicCalendar",
  })}`;
  //#endregion

  //#region internal state
  const [events, setEvents] = useState<any[]>([]);
  const [showAddEditEventModal, setShowAddEditEventModal] =
    useState<boolean>(false);
  const [selectedEvent, setSelectedEvent] = useState<any>();
  //#endregion

  //#region api
  const [getAllCalendarEvents, { data: dataGetAllCalendarEvents }] =
    useLazyGetAllCalendarEventsQuery();
  //#endregion

  //#region effects

  useEffect(() => {
    getAllCalendarEvents(null);
  }, []);

  useEffect(() => {
    if (dataGetAllCalendarEvents) {
      setEvents(
        dataGetAllCalendarEvents.map((e: CalendarEventDto): any => {
          return {
            id: e.id,
            title: i18n.language === "ar" ? e.title.ar : e.title.en,
            allDay: e.allDay,
            end: new Date(e.endAt),
            start: new Date(e.startAt),
          };
        })
      );
    }
  }, [dataGetAllCalendarEvents, i18n.language]);

  //#endregion

  //#region handlers
  const handleSelectSlot = useCallback(
    ({ start, end }: SlotInfo) => {
      setSelectedEvent({ startAt: start, endAt: end, allDay: false });
      setShowAddEditEventModal(true);
    },
    [setEvents]
  );

  const handleSelectEvent = (event: any) => {
    setShowAddEditEventModal(true);
    const eventToModify = dataGetAllCalendarEvents?.find(
      (i) => i.id === event.id
    );
    setSelectedEvent(eventToModify);
  };

  //#endregion

  return (
    <React.Fragment>
      <ToastContainer />
      <Row>
        <Col>
          <Card>
            <CardHeader>
              <h6 className="card-title mb-0">{t("academicCalendar")}</h6>
            </CardHeader>
            <CardBody>
              <div className="vh-100 mt-4">
                <Calendar
                  culture={i18n.language === "ar" ? "ar-AE" : "en"}
                  localizer={localizer}
                  events={events}
                  startAccessor="start"
                  endAccessor="end"
                  onSelectEvent={handleSelectEvent}
                  onSelectSlot={handleSelectSlot}
                  selectable
                  messages={
                    i18n.language === "ar"
                      ? {
                          week: t("week"),
                          work_week: t("workWeek"),
                          day: t("day"),
                          month: t("month"),
                          previous: t("previous"),
                          next: t("next"),
                          today: t("today"),
                          agenda: t("event"),
                          allDay: t("allDay"),
                          noEventsInRange: t("noEventsInRange"),
                          date: t("date"),
                          event: t("event"),
                          time: t("time"),
                          tomorrow: t("tomorrow"),
                          yesterday: t("yesterday"),
                          showMore: (total) => `+${total} ${t("total")}`,
                        }
                      : {}
                  }
                  rtl={i18n.language === "ar"}
                  popup
                />
              </div>
              <AddEditEventModal
                calendarEvent={selectedEvent}
                show={showAddEditEventModal}
                onCloseClick={() => {
                  setShowAddEditEventModal(false);
                  setSelectedEvent(null);
                }}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};
