import { createSlice } from "@reduxjs/toolkit";

import { RootState } from "..";
import { UserProfileDto } from "../../interfaces";

const INIT_STATE = {
  name: null,
  gender: null,
  phone: null,
  email: null,
  role: null,
  profilePhoto: null,
};

const slice = createSlice({
  name: "account",
  initialState: INIT_STATE as any,
  reducers: {
    setUser: (state, { payload }: UserPayload) => {
      state.id = payload.id as any;
      state.name = payload.name as any;
      state.email = payload.email as any;
      state.gender = payload.gender as any;
      state.phone = payload.phone as any;
      state.role = payload.role as any;
      state.profilePhoto = payload.profilePhoto as any;
    },
    clearUser: (state) => {
      state.id = null;
      state.name = null;
      state.email = null;
      state.gender = null;
      state.phone = null;
      state.role = null;
      state.profilePhoto = null;
    },
  },
});

export const { setUser, clearUser } = slice.actions;

export default slice.reducer;

export type UserState = UserProfileDto & {};

type UserPayload = {
  payload: Partial<UserState>;
};

export const selectCurrentUser = (state: RootState) => state.account;
