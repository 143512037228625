import { ChangeEvent, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import { Card, CardBody, Input, Label, Spinner } from "reactstrap";

import {
  getServerError,
  useLazyGetUserProfileQuery,
  useUploadUserProfilePhotoMutation,
} from "../../services";
import avatar1 from "../../assets/images/users/user-dummy-img.jpg";
import { ErrorHandlingTypeEnum } from "../../enums";

export const ProfilePicture = () => {
  //#region hooks
  const { t, i18n } = useTranslation();
  //#endregion

  //#region redux state
  const [getUserProfile, { data: dataGetUserProfile }] =
    useLazyGetUserProfileQuery();

  const [
    uploadProfilePhoto,
    {
      isLoading: isLoadingUploadProfilePhoto,
      isError: isErrorUploadProfilePhoto,
      isSuccess: isSuccessUploadProfilePhoto,
      error: errorUploadProfilePhoto,
      data: dataUploadProfilePhoto,
    },
  ] = useUploadUserProfilePhotoMutation();

  //#endregion

  //#region handlers
  const onChangeImage = async (event: ChangeEvent<HTMLInputElement>) => {
    const { target } = event;
    const { files } = target;
    if (files && files.length > 0) {
      const imageFile = files[0];
      if (!imageFile.name.match(/\.(jpg|jpeg|jfif|pjpeg|pjp|png|webp|gif)$/)) {
        toast.error(t("selectValidImage"), {
          autoClose: 3000,
        });
        return false;
      }
      await uploadProfilePhoto(imageFile);
    }
  };

  //#endregion

  //#region effects
  useEffect(() => {
    async function initialize() {
      await getUserProfile(null);
    }
    initialize();
  }, []);

  useEffect(() => {
    if (!isLoadingUploadProfilePhoto && isErrorUploadProfilePhoto) {
      const _parsed = getServerError(errorUploadProfilePhoto);
      if (_parsed.type === ErrorHandlingTypeEnum.ALERT) {
        toast.error(_parsed.error, {
          autoClose: 3000,
        });
      }
    }
    if (
      !isLoadingUploadProfilePhoto &&
      isSuccessUploadProfilePhoto &&
      dataUploadProfilePhoto
    ) {
      toast.success(t("savedSuccessfully"), {
        autoClose: 3000,
      });
    }
  }, [isLoadingUploadProfilePhoto]);
  //#endregion

  return (
    <Card>
      <ToastContainer />
      <CardBody className="p-4">
        <div className="text-center">
          <div className="profile-user position-relative d-inline-block mx-auto  mb-4">
            <img
              src={
                dataUploadProfilePhoto?.path ||
                dataGetUserProfile?.profilePhoto ||
                avatar1
              }
              className="rounded-circle avatar-xl img-thumbnail user-profile-image"
              alt="user-profile"
            />
            <div className="avatar-xs p-0 rounded-circle profile-photo-edit">
              <Input
                onChange={onChangeImage}
                id="profile-img-file-input"
                type="file"
                className="profile-img-file-input"
                disabled={isLoadingUploadProfilePhoto}
              />
              <Label
                htmlFor="profile-img-file-input"
                className="profile-photo-edit avatar-xs"
              >
                <span className="avatar-title rounded-circle bg-light text-body">
                  {isLoadingUploadProfilePhoto && <Spinner />}
                  {!isLoadingUploadProfilePhoto && (
                    <i className="ri-camera-fill"></i>
                  )}
                </span>
              </Label>
            </div>
          </div>
          <h5 className="fs-16 mb-1">
            {i18n.language === "ar"
              ? dataGetUserProfile?.name.ar
              : dataGetUserProfile?.name.en}
          </h5>
          <p className="text-muted mb-1">
            {" "}
            {i18n.language === "ar"
              ? dataGetUserProfile?.role?.name?.ar
              : dataGetUserProfile?.role?.name.en}
          </p>
          <p className="text-muted mb-0" dir="ltr">
            {dataGetUserProfile?.phone.countryCode &&
              dataGetUserProfile?.phone.phoneNumber &&
              `+${dataGetUserProfile?.phone.countryCode} 
            ${dataGetUserProfile?.phone.phoneNumber}`}
          </p>
        </div>
      </CardBody>
    </Card>
  );
};
