import { createApi } from "@reduxjs/toolkit/query/react";

import {
  CreateVerificationRequest,
  CreateVerificationResponse,
  ForgetPasswordRequest,
  ResetPasswordRequest,
  SigninRequest,
  SigninResponse,
  SignoutRequest,
  VerificationRequest,
  VerificationResponse,
} from "../interfaces";
import { baseQueryWithInterceptor } from "./base.api";

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: baseQueryWithInterceptor,
  endpoints: (builder) => ({
    signin: builder.mutation<SigninResponse, SigninRequest>({
      query: (body) => ({
        url: `/auth/signin`,
        method: "POST",
        body,
      }),
    }),
    signout: builder.mutation<null, SignoutRequest>({
      query: (body) => ({
        url: `/auth/signout`,
        method: "POST",
        body,
      }),
    }),
    forgetPassword: builder.mutation<
      VerificationResponse,
      ForgetPasswordRequest
    >({
      query: (body) => ({
        url: `/auth/forget-password`,
        method: "POST",
        body,
      }),
    }),
    resendOTP: builder.mutation<
      CreateVerificationResponse,
      CreateVerificationRequest
    >({
      query: (body) => ({
        url: `/verification/resend`,
        method: "POST",
        body,
      }),
    }),
    verify: builder.mutation<SigninResponse, VerificationRequest>({
      query: (body) => ({
        url: `/auth/verify`,
        method: "POST",
        body,
      }),
    }),
    resetPassword: builder.mutation<boolean, ResetPasswordRequest>({
      query: (body) => ({
        url: `/auth/forget-password/reset-password`,
        method: "POST",
        body,
      }),
    }),
  }),
  tagTypes: [],
});

export const {
  useSigninMutation,
  useSignoutMutation,
  useForgetPasswordMutation,
  useResetPasswordMutation,
  useResendOTPMutation,
  useVerifyMutation,
} = authApi;
