import * as Yup from "yup";
import { useFormik } from "formik";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import {
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";

import { ErrorHandlingTypeEnum } from "../../../enums";
import { ActivityButton } from "../../../components/Common";
import {
  ImageUpload,
  InputLocalized,
  RichTextEditorLocalized,
} from "../../../components";
import {
  LocalizedPropertyInitialErrors,
  LocalizedPropertyInitialTouched,
  LocalizedPropertyInitialValues,
  LocalizedPropertyYupSchema,
} from "../../../constants/language.constant";
import { CreateMenuRequest } from "../../../interfaces/menu/create-menu-request";
import { UpdateMenuRequest } from "../../../interfaces/menu/update-menu-request";
import {
  useUpdateMenuMutation,
  useLazyGetMenuByIdQuery,
  getServerError,
  useUploadMenuImageMutation,
} from "../../../services";
import { LocalizedProperty } from "../../../interfaces";

type TProps = {
  id?: string | null;
  show: boolean;
  onCloseClick: (event: any) => void;
};
export const AddEditMenuModal: React.FC<TProps> = ({
  id,
  show,
  onCloseClick,
}) => {
  //#region hooks
  const { t } = useTranslation();
  //#endregion

  //#region internal state
  const [descriptionInitialValue, setDescriptionInitialValue] =
    useState<LocalizedProperty>(LocalizedPropertyInitialValues);
  const [imagePath, setImagePath] = useState<string | null | undefined>();

  //#endregion

  //#region api
  const [
    updateMenu,
    {
      isLoading: isLoadingUpdateMenu,
      isError: isErrorUpdateMenu,
      isSuccess: isSuccessUpdateMenu,
      error: errorUpdateMenu,
    },
  ] = useUpdateMenuMutation();

  const [
    getMenuById,
    {
      isSuccess: isSuccessMenuById,
      isFetching: isFetchingMenuById,
      isError: isErrorMenuById,
      error: errorMenuById,
      data: dataMenuById,
    },
  ] = useLazyGetMenuByIdQuery();

  const [
    uploadMenuImage,
    {
      isLoading: isLoadingUploadMenuImage,
      isError: isErrorUploadMenuImage,
      isSuccess: isSuccessUploadMenuImage,
      data: dataUploadMenuImage,
      error: errorUploadMenuImage,
    },
  ] = useUploadMenuImageMutation();
  //#endregion

  //#region form
  const form = useFormik<CreateMenuRequest | UpdateMenuRequest>({
    enableReinitialize: true,
    initialErrors: {
      title: LocalizedPropertyInitialErrors,
      description: LocalizedPropertyInitialErrors,
    },
    initialTouched: {
      title: LocalizedPropertyInitialTouched,
      description: LocalizedPropertyInitialTouched,
    },
    initialValues: {
      id: null,
      sequence: 0,
      title: LocalizedPropertyInitialValues,
      description: LocalizedPropertyInitialValues,
    },
    validationSchema: Yup.object({
      id: Yup.string().nullable(),
      title: LocalizedPropertyYupSchema("title", 255),
      description: LocalizedPropertyYupSchema("description", null, false),
    }),
    onSubmit: async (values) => {
      if (id) {
        await updateMenu({
          ...values,
          id: id,
          sequence: Number(values.sequence),
        });
      }
    },
  });
  //#endregion

  //#region effects

  useEffect(() => {
    if (id && !isFetchingMenuById && isSuccessMenuById && dataMenuById) {
      form.setValues({
        id: dataMenuById.id,
        title: dataMenuById.title,
        sequence: dataMenuById.sequence,
        description: dataMenuById.description,
      });
    }
  }, [isFetchingMenuById]);

  useEffect(() => {
    if (!isLoadingUpdateMenu && isErrorUpdateMenu) {
      const _parsed = getServerError(errorUpdateMenu);
      if (_parsed.type === ErrorHandlingTypeEnum.FORM) {
        form.setErrors(_parsed.error);
      }
      if (_parsed.type === ErrorHandlingTypeEnum.ALERT) {
        toast.error(_parsed.error, {
          autoClose: 3000,
        });
      }
    }
    if (!isLoadingUpdateMenu && isSuccessUpdateMenu) {
      toast.success(t("savedSuccessfully"), {
        autoClose: 3000,
      });
      onClose();
    }
  }, [isLoadingUpdateMenu]);

  useEffect(() => {
    if (!isFetchingMenuById && isErrorMenuById) {
      const _parsed = getServerError(errorMenuById);
      if (_parsed.type === ErrorHandlingTypeEnum.FORM) {
        form.setErrors(_parsed.error);
      }
      if (_parsed.type === ErrorHandlingTypeEnum.ALERT) {
        toast.error(_parsed.error, {
          autoClose: 3000,
        });
      }
    }
    if (!isFetchingMenuById && isSuccessMenuById && dataMenuById) {
      setDescriptionInitialValue(dataMenuById.description);
      setImagePath(dataMenuById?.media1?.path);
    }
  }, [isFetchingMenuById]);

  useEffect(() => {
    async function initialize() {
      await getMenuById(id as string);
    }
    if (id) {
      initialize();
    }
  }, [id]);

  useEffect(() => {
    if (!isLoadingUploadMenuImage && isErrorUploadMenuImage) {
      const _parsed = getServerError(errorUploadMenuImage);
      if (_parsed.type === ErrorHandlingTypeEnum.FORM) {
        form.setErrors(_parsed.error);
      }
      if (_parsed.type === ErrorHandlingTypeEnum.ALERT) {
        toast.error(_parsed.error, {
          autoClose: 3000,
        });
      }
    }
    if (
      !isLoadingUploadMenuImage &&
      isSuccessUploadMenuImage &&
      dataUploadMenuImage
    ) {
      toast.success(t("savedSuccessfully"), {
        autoClose: 3000,
      });
      setImagePath(dataUploadMenuImage.path);
    }
  }, [isLoadingUploadMenuImage]);

  //#endregion

  //#region handlers
  const onClose = () => {
    form.resetForm();
    setDescriptionInitialValue(LocalizedPropertyInitialValues);
    setImagePath(null);
    onCloseClick(!show);
  };

  const onChangeFile = async (file: File) => {
    await uploadMenuImage({ media: file, id: id });
  };

  //#endregion

  return (
    <React.Fragment>
      <ToastContainer />
      <Modal
        id="addEdit"
        isOpen={show}
        toggle={() => {
          onClose();
        }}
        centered
        className="modal-dialog modal-lg"
      >
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            form.handleSubmit();
            return false;
          }}
          action="#"
        >
          <ModalHeader
            className="p-3"
            toggle={() => {
              onClose();
            }}
          >
            {id
              ? t("editItem", { item: "menu" })
              : t("addItem", { item: "menu" })}
          </ModalHeader>
          <ModalBody>
            <ImageUpload
              path={imagePath}
              inProgress={isLoadingUploadMenuImage}
              onChange={(file: File) => onChangeFile(file)}
            />
            <div className="mb-3">
              <Label htmlFor="sequence" className="form-label">
                {t("sequence")}
                <span className="text-danger">*</span>
              </Label>
              <Input
                tabIndex={1}
                type="text"
                className="form-control"
                id="sequence"
                name="sequence"
                placeholder={`${t("enterSequence")}`}
                minLength={1}
                maxLength={5}
                value={form.values.sequence || ""}
                onChange={(e) => {
                  e.preventDefault();
                  const { value } = e.target;
                  const regex =
                    /^(0*[0-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/;
                  if (regex.test(value.toString())) {
                    form.setFieldValue("sequence", value);
                  } else {
                    form.setFieldValue("sequence", "");
                  }
                }}
                onBlur={form.handleBlur}
                invalid={
                  form.touched.sequence && form.errors.sequence ? true : false
                }
              />
              {form.touched.sequence && form.errors.sequence ? (
                <FormFeedback type="invalid">
                  {form.errors.sequence as string}
                </FormFeedback>
              ) : null}
            </div>
            <InputLocalized
              tabIndex={2}
              name="title"
              form={form}
              type={"text"}
              label="name"
            />
            <Row>
              <Col className="col-12">
                <RichTextEditorLocalized
                  name="description"
                  form={form}
                  initialValue={descriptionInitialValue}
                  required={false}
                  onClose={() =>
                    setDescriptionInitialValue(form.values.description)
                  }
                  onCompletedTranslation={(translation: LocalizedProperty) => {
                    setDescriptionInitialValue(translation);
                  }}
                />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Link
              to={"#"}
              onClick={onClose}
              className="btn btn-link link-success fw-medium"
            >
              <i className="ri-close-line me-1 align-middle"></i> {t("close")}
            </Link>
            <ActivityButton
              title="save"
              inProgressTitle="saving"
              color="success"
              className="btn btn-success"
              type="submit"
              inProgress={isLoadingUpdateMenu}
              disabled={isLoadingUpdateMenu}
            ></ActivityButton>
          </ModalFooter>
        </Form>
      </Modal>
    </React.Fragment>
  );
};
