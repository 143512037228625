import { useTranslation } from "react-i18next";
import { LordIcon } from "./LordIcon";

type TProps = {
  message?: string;
};
export const NoResultFound: React.FC<TProps> = ({
  message = "sorryNoResult",
}) => {
  const { t } = useTranslation();
  return (
    <div className="py-4 mt-4 text-center" id="noresult">
      <i className=" ri-information-line fs-24"></i>
      <h6 className="mt-3">{t(message)}</h6>
    </div>
  );
};
