const cryptoJS = require("crypto-js");

const encrypt = (text: string) => {
  try {
    return cryptoJS.enc.Base64.stringify(cryptoJS.enc.Utf8.parse(text));
  } catch (error) {
    throw error;
  }
};

const decrypt = (data: string) => {
  try {
    return cryptoJS.enc.Base64.parse(data).toString(cryptoJS.enc.Utf8);
  } catch (error) {
    throw error;
  }
};

export const EncryptionService = { encrypt, decrypt };
