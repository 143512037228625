import React, { useEffect, useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import axios from "axios";
import { get } from "lodash";

import i18n from "../../i18n";
import { Languages } from "../../constants/language.constant";

const LanguageDropdown = () => {
  const [selectedLang, setSelectedLang] = useState("");

  useEffect(() => {
    configureLanguage(localStorage.getItem("i18nextLng") || "en");
  }, []);

  const configureLanguage = (lang: string) => {
    const html = window.document.documentElement;
    const langObj = Languages[lang];
    html.lang = lang;
    html.dir = langObj.writingDirection;
    i18n.changeLanguage(lang);
    setSelectedLang(lang);
  };

  const changeLanguageAction = (lang: string) => {
    i18n.changeLanguage(lang);
    localStorage.setItem("i18nextLng", lang);
    configureLanguage(lang);
    setSelectedLang(lang);
    axios.defaults.headers.common["Accept-Language"] = lang;
  };

  const [isLanguageDropdown, setIsLanguageDropdown] = useState(false);
  const toggleLanguageDropdown = () => {
    setIsLanguageDropdown(!isLanguageDropdown);
  };
  return (
    <React.Fragment>
      <Dropdown
        isOpen={isLanguageDropdown}
        toggle={toggleLanguageDropdown}
        className="ms-1 topbar-head-dropdown header-item"
      >
        <DropdownToggle
          className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
          tag="button"
        >
          <img
            src={get(Languages, `${selectedLang}.flag`)}
            alt="Header Language"
            height="20"
            className="rounded"
          />
        </DropdownToggle>
        <DropdownMenu className="notify-item language py-2">
          {Object.keys(Languages)
            .filter((key) => ["en", "ar"].includes(key))
            .map((key) => (
              <DropdownItem
                key={key}
                style={{ textAlign: "initial" }}
                onClick={() => changeLanguageAction(key)}
                className={`notify-item  ${
                  selectedLang === key ? "active" : "none"
                }`}
              >
                <img
                  src={get(Languages, `${key}.flag`)}
                  className="me-2 rounded"
                  height="18"
                />
                <span className="align-middle">
                  {get(Languages, `${key}.nameInLanguage.${key}`)}
                </span>
              </DropdownItem>
            ))}
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default LanguageDropdown;
