import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "..";

const INIT_STATE = {
  token: null,
  refreshToken: null,
};

const slice = createSlice({
  name: "auth",
  initialState: INIT_STATE,
  reducers: {
    setCredentials: (
      state,
      { payload: { token, refreshToken } }: SessionPayload
    ) => {
      state.token = token as any;
      state.refreshToken = refreshToken as any;
    },
    clearCredentials: (state) => {
      state.token = null as any;
      state.refreshToken = null as any;
    },
  },
});

export const { setCredentials, clearCredentials } = slice.actions;

export default slice.reducer;

export type SessionState = {
  token: string | null | undefined;
  refreshToken: string | null | undefined;
};

type SessionPayload = {
  payload: Partial<SessionState>;
};

export const selectCurrentUserSession = (state: RootState) => state.auth;
