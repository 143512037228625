import { useEffect, useState } from "react";
import { Row, Col, Card, CardBody, Container, Spinner } from "reactstrap";
import React from "react";
import { useTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";
import { Link, useParams } from "react-router-dom";

import logo from "../../assets/images/logo.png";
import { ParticlesAuth } from "../authInner";
import {
  EncryptionService,
  getServerError,
  useVerifyMutation,
} from "../../services";
import { VerificationMethodEnum, VerificationTypeEnum } from "../../enums";
import { ParsedError } from "../../interfaces";

export const EmailVerificationPage = () => {
  //#region hooks usage
  const { t } = useTranslation();
  //#endregion

  //#region internal state
  const { token: encToken } = useParams<any>();
  const [displayEmail, setDisplayPhone] = useState<string>();
  const [parsedError, setParsedError] = useState<ParsedError>();
  //#endregion

  //#region others
  document.title = `${t("documentTitle", { pageName: "verifyEmail" })}`;
  //#region

  //#region api
  const [
    verify,
    {
      isError: isErrorVerifyOTP,
      isLoading: isLoadingVerifyOTP,
      error: errorVerifyOTP,
      isSuccess: isSuccessVerifyOTP,
    },
  ] = useVerifyMutation();

  //#endregion

  //#region effects
  useEffect(() => {
    if (encToken) {
      try {
        const token: string = EncryptionService.decrypt(encToken);
        const tokenArray: string[] = token.split(":");
        if (tokenArray && tokenArray.length === 2) {
          setDisplayPhone(tokenArray[1]);
          verify({
            method: VerificationMethodEnum.EMAIL,
            type: VerificationTypeEnum.SIGNUP,
            email: tokenArray[1],
            token: tokenArray[0],
          })
            .then(() => {})
            .catch((e) => console.log(e));
        }
      } catch (error) {}
    }
  }, [encToken]);

  useEffect(() => {
    if (!isLoadingVerifyOTP && isErrorVerifyOTP) {
      const _parsed = getServerError(errorVerifyOTP);
      setParsedError(_parsed);
    }
  }, [isLoadingVerifyOTP]);

  //#endregion

  return (
    <React.Fragment>
      <ToastContainer newestOnTop={true} />
      <ParticlesAuth>
        <div className="auth-page-content">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="mt-4">
                  <CardBody className="p-4">
                    <div className="text-center mt-1">
                      <img src={logo} alt="" height="100" width="100" />
                      <h5 className="text-primary mt-2">
                        {t("emailVerification")}
                      </h5>
                      <p className="text-muted">
                        <p>{displayEmail}</p>
                      </p>
                    </div>
                    <div className="mt-4"></div>
                    <div className="d-flex justify-content-center align-items-center my-2">
                      {isLoadingVerifyOTP && (
                        <React.Fragment>
                          <Spinner />
                          <h5 className="text-primary my-5">
                            {t("verifyingYourEmail")}
                          </h5>
                          <p>{displayEmail}</p>
                        </React.Fragment>
                      )}
                      {!isLoadingVerifyOTP && isSuccessVerifyOTP && (
                        <div className="text-center">
                          <i className="ri-check-line align-bottom align-self-center"></i>
                          <p>{t("emailVerificationCompleted")}</p>
                          {/* <p className="mb-0">
                            <Link
                              to="/login"
                              className="fw-semibold text-decoration-underline"
                            >
                              {t("openApp")}
                            </Link>
                          </p> */}
                        </div>
                      )}
                      {!isLoadingVerifyOTP && isErrorVerifyOTP && (
                        <div className="text-center">
                          <p>{parsedError?.error}</p>
                          {/* <p className="mb-0">
                            <Link
                              to="/login"
                              className="fw-semibold text-decoration-underline"
                            >
                              {t("openApp")}
                            </Link>
                          </p> */}
                        </div>
                      )}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </ParticlesAuth>
    </React.Fragment>
  );
};
