import { useTranslation } from "react-i18next";

type TProps = {
  /**
   * translation key
   */
  title: string;
};
export const ColumnHeader: React.FC<TProps> = ({ title }) => {
  const { t } = useTranslation();
  return <span className="font-weight-bold fs-13">{t(title)}</span>;
};
