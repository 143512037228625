import { createApi } from "@reduxjs/toolkit/query/react";

import {
  AppSettingsDto,
  ContactUsRequest,
  DeleteAccountRequest,
  UpdateAppSettingsRequest,
} from "../interfaces";
import { baseQueryWithInterceptor } from "./base.api";
import { AppSettingsTypeEnum } from "../enums";

export const appSettingsApi = createApi({
  reducerPath: "appSettingsApi",
  baseQuery: baseQueryWithInterceptor,
  endpoints: (builder) => ({
    getAppSettings: builder.query<AppSettingsDto, AppSettingsTypeEnum>({
      query: (type) => ({
        url: `/app-settings/${type}`,
        method: "GET",
      }),
      providesTags: ["app_setting"],
    }),
    updateAppSettings: builder.mutation<
      AppSettingsDto,
      UpdateAppSettingsRequest
    >({
      query: (body) => ({
        url: `/app-settings/${body.type}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["app_setting"],
    }),
    getPrivacyPolicyPublic: builder.query<AppSettingsDto, null>({
      query: () => ({
        url: `/app-settings/public/privacy-policy`,
        method: "GET",
      }),
    }),
    deleteAccountRequest: builder.mutation<null, DeleteAccountRequest>({
      query: (body) => ({
        url: `/complaint/delete-account-request`,
        method: "POST",
        body,
      }),
    }),
    contactUs: builder.mutation<null, ContactUsRequest>({
      query: (body) => ({
        url: `/complaint/contact-us`,
        method: "POST",
        body,
      }),
    }),
  }),
  tagTypes: ["app_setting"],
});

export const {
  useGetAppSettingsQuery,
  useUpdateAppSettingsMutation,
  useGetPrivacyPolicyPublicQuery,
  useDeleteAccountRequestMutation,
  useContactUsMutation,
} = appSettingsApi;
