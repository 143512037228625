import React from "react";
import { useTranslation } from "react-i18next";

type TProps = {
  text: string | number;
  capitalize?: boolean;
};
export const TextColumn: React.FC<TProps> = ({ text, capitalize = true }) => {
  const { t } = useTranslation();
  return (
    <div className="d-flex gap-2 align-items-center">
      <div className={`flex-grow-1 ${capitalize ? "text-capitalize" : ""}`}>
        {t(text?.toString() || "-")}
      </div>
    </div>
  );
};
