import { ToastContainer, toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { DebounceInput } from "react-debounce-input";
import { TableColumn } from "react-data-table-component";
import { Container, Row, Col, Card, CardBody } from "reactstrap";

import { UserDto } from "../../../interfaces";
import { PaginateQuery } from "../../../models";
import { BreadCrumb } from "../../../components/Common";
import { ActionTypeEnum, ErrorHandlingTypeEnum } from "../../../enums";
import {
  ColumnHeader,
  TextColumn,
  DateColumn,
  ActionButton,
  AppTable,
  TableSpinner,
} from "../../../components";
import {
  useDeleteUserMutation,
  useLazyGetPaginatedAppUsersQuery,
  useToggleBlockMutation,
} from "../../../services/user.api.service";
import { getServerError } from "../../../services";
import DeleteModal from "../../../components/Common/DeleteModal";
import ConfirmationModal from "../../../components/Common/ConfirmationModal";

type TProps = {};
export const AppUsersPage: React.FC<TProps> = () => {
  //#region hooks usage
  const history = useHistory();
  const { t } = useTranslation();
  //#endregion

  //#region others
  document.title = `${t("documentTitle", { pageName: "appUsers" })}`;
  //#endregion

  //#region api
  const [
    getPaginatedAppUsers,
    {
      isError: isErrorGetPaginatedAppUsers,
      error: errorGetPaginatedAppUsers,
      isFetching: isFetchingGetPaginatedAppUsers,
      data: dataGetPaginatedAppUsers,
    },
  ] = useLazyGetPaginatedAppUsersQuery();

  const [
    deleteUser,
    {
      isLoading: isLoadingDeleteUser,
      isError: isErrorDeleteUser,
      isSuccess: isSuccessDeleteUser,
      error: errorDeleteUser,
    },
  ] = useDeleteUserMutation();

  const [
    toggleBlock,
    {
      isLoading: isLoadingToggleBlock,
      isError: isErrorToggleBlock,
      isSuccess: isSuccessToggleBlock,
      error: errorToggleBlock,
    },
  ] = useToggleBlockMutation();

  //#endregion

  //#region internal state
  const initState = new PaginateQuery();
  const [paginateQuery, setPaginateQuery] = useState<PaginateQuery>(initState);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deletingItemId, setDeletingItemId] = useState<string | null>(null);
  const [defaultSortAsc, setDefaultSortAsc] = useState(false);
  const [defaultPage, setDefaultPage] = useState(1);
  const [selectedUser, setSelectedUser] = useState<UserDto | null>(null);
  const [showBlockUnblockModal, setShowBlockUnblockModal] = useState(false);

  //#endregion

  //#region Table Columns
  const columns: TableColumn<UserDto>[] = [
    {
      name: <ColumnHeader title="name" />,
      cell: (row) => <TextColumn text={row.name["en"]} />,
      sortable: true,
    },
    {
      name: <ColumnHeader title="email" />,
      cell: (row) => (
        <>
          {row.email.email ? (
            <TextColumn capitalize={false} text={`${row.email.email}`} />
          ) : (
            "-"
          )}
        </>
      ),
      sortable: true,
    },
    {
      name: (
        <span className="font-weight-bold fs-13">{t("emailVerified")}</span>
      ),
      cell: (row) => {
        switch (row.email?.isEmailVerified) {
          case true:
            return <span className="badge badge-soft-success">{t("yes")}</span>;
          default:
            return <span className="badge badge-soft-danger">{t("no")}</span>;
        }
      },
      sortable: false,
    },
    {
      name: <ColumnHeader title="gender" />,
      cell: (row) => <TextColumn text={row.gender ? t(row.gender) : "-"} />,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">{t("blocked")}</span>,
      cell: (row) => {
        switch (row.blockingState?.isBlocked) {
          case true:
            return (
              <span className="badge badge-soft-danger"> {t("yes")} </span>
            );
          default:
            return (
              <span className="badge badge-soft-success"> {t("no")} </span>
            );
        }
      },
      sortable: false,
      width: "200px",
    },
    {
      name: <ColumnHeader title="createdAt" />,
      cell: (row) => <DateColumn date={row.createdAt} />,
      sortable: true,
    },
    {
      name: <ColumnHeader title="lastModifiedAt" />,
      cell: (row) => <DateColumn date={row.modifiedAt} />,
      sortable: true,
    },
    {
      name: <ColumnHeader title="action" />,
      sortable: false,
      width: "120px",
      cell: (row) => (
        <React.Fragment>
          <ActionButton
            onAction={() => onBlock(row)}
            actionType={ActionTypeEnum.BLOCK}
            state={row.blockingState?.isBlocked}
          />
          &nbsp;
          <ActionButton
            onAction={() => onDelete(row.id)}
            actionType={ActionTypeEnum.DELETE}
          />
        </React.Fragment>
      ),
    },
  ];

  //#endregion

  //#region effects

  useEffect(() => {
    const queryParams = new URLSearchParams(history.location.search);
    let query: PaginateQuery = {
      limit: queryParams.has("limit")
        ? Number(queryParams.get("limit"))
        : paginateQuery.limit,
      page: queryParams.has("page")
        ? Number(queryParams.get("page"))
        : paginateQuery.page,
      sortBy: queryParams.has("sortBy")
        ? (queryParams.get("sortBy") as string)
        : paginateQuery.sortBy,
      search: queryParams.has("search")
        ? (queryParams.get("search") as string)
        : paginateQuery.search,
      filter: queryParams.has("filter[subject]")
        ? {
            ["subject"]: queryParams.get("filter[subject]") || "",
          }
        : paginateQuery.filter,
    };
    if (
      queryParams.get("sortBy") &&
      queryParams.get("sortBy")?.split(":").length &&
      queryParams.get("sortBy")?.split(":").length == 2
    ) {
      setDefaultSortAsc(
        (queryParams.get("sortBy")?.split(":")[1] as string) === "ASC"
      );
      setDefaultSortFieldId(
        getSortByColumnName(queryParams.get("sortBy")?.split(":")[0] as string)
      );
    } else {
      setDefaultSortAsc(false);
      setDefaultSortFieldId(2);
    }
    setDefaultPage(query.page);
    setPaginateQuery(query);
  }, []);

  useEffect(() => {
    if (paginateQuery !== initState) {
      const { limit, page, search, sortBy, filter } = paginateQuery;
      let query;
      if (page) query = `?page=${page}`;
      if (limit) query += `&limit=${limit}`;
      if (search && search !== "") {
        history.replace({
          search: `&search=${search}`,
        });
      }
      if (sortBy) query += `&sortBy=${sortBy}`;
      if (search) query += `&search=${search}`;
      if (
        filter &&
        Object.keys(filter).length &&
        Object.keys(filter).length > 0
      ) {
        let filters: string = "";
        Object.keys(filter).map((key: string) => {
          if (filter[key]) filters += `&filter[${key}]=${filter[key]}`;
        });
        query += filters;
      }
      history.replace({ search: query });
      getPaginatedAppUsers(paginateQuery);
    }
  }, [paginateQuery]);

  useEffect(() => {
    if (!isFetchingGetPaginatedAppUsers && isErrorGetPaginatedAppUsers) {
      const _parsed = getServerError(errorGetPaginatedAppUsers);
      if (_parsed.type === ErrorHandlingTypeEnum.ALERT) {
        toast.error(_parsed.error, {
          autoClose: 3000,
        });
      }
    }
  }, [isFetchingGetPaginatedAppUsers, isErrorGetPaginatedAppUsers]);

  useEffect(() => {
    if (!isLoadingDeleteUser && isErrorDeleteUser) {
      const _parsed = getServerError(errorDeleteUser);
      if (_parsed.type === ErrorHandlingTypeEnum.ALERT) {
        toast.error(_parsed.error, {
          autoClose: 3000,
        });
      }
    }
    if (!isLoadingDeleteUser && isSuccessDeleteUser) {
      setShowDeleteModal(false);
      toast.info(t("deletedSuccessfully"), { autoClose: 3000 });
    }
  }, [isLoadingDeleteUser, isErrorDeleteUser, isSuccessDeleteUser]);

  useEffect(() => {
    if (!isLoadingToggleBlock && isErrorToggleBlock) {
      const _parsed = getServerError(errorToggleBlock);
      if (_parsed.type === ErrorHandlingTypeEnum.ALERT) {
        toast.error(_parsed.error, {
          autoClose: 3000,
        });
      }
    }
    if (!isLoadingToggleBlock && isSuccessToggleBlock) {
      setShowBlockUnblockModal(false);
      toast.info(t("savedSuccessfully"), { autoClose: 3000 });
    }
  }, [isLoadingToggleBlock, isErrorToggleBlock, isSuccessToggleBlock]);

  //#endregion

  //#region handlers

  const getSortByColumnName = (name: string) => {
    let sortBy;
    switch (name) {
      case "name.en":
        sortBy = 1;
        break;
      case "email.email":
        sortBy = 2;
        break;
      case "gender":
        sortBy = 4;
        break;
      case "created_at":
        sortBy = 6;
        break;
      case "modified_at":
        sortBy = 7;
        break;
      default:
        sortBy = 6;
        break;
    }
    return sortBy;
  };

  const onBlock = (user: UserDto) => {
    setSelectedUser(user);
    setShowBlockUnblockModal(true);
  };

  const onBlockUnblockConfirm = async () => {
    if (selectedUser) {
      await toggleBlock(selectedUser.id);
      setSelectedUser(null);
    }
  };

  const onDelete = (id: string) => {
    setDeletingItemId(id);
    setShowDeleteModal(true);
  };

  const onDeleteConfirm = async () => {
    await deleteUser(deletingItemId as string);
    setDeletingItemId(null);
  };

  const onSearch = (searchText: string) => {
    setPaginateQuery({ ...paginateQuery, search: searchText });
  };

  const getSortByColumnId = (columnId: number, sortDirection: string) => {
    let sortBy;
    switch (columnId) {
      case 1:
        sortBy = "name.en";
        break;
      case 2:
        sortBy = "email.email";
        break;
      case 4:
        sortBy = "gender";
        break;
      case 6:
        sortBy = "created_at";
        break;
      case 7:
        sortBy = "modified_at";
        break;
      default:
        sortBy = "created_at";
        break;
    }
    return `${sortBy}:${sortDirection.toUpperCase()}`;
  };

  const onChangePage = (page: number) => {
    setPaginateQuery({ ...paginateQuery, page: page });
  };

  const onChangeRowsPerPage = (
    currentRowsPerPage: number,
    currentPage: number
  ) => {
    setPaginateQuery({
      ...paginateQuery,
      limit: currentRowsPerPage,
      page: currentPage,
    });
  };

  const onSort = (column: TableColumn<UserDto>, sortDirection: string) => {
    const sortBy = getSortByColumnId(column.id as number, sortDirection);
    setPaginateQuery({
      ...paginateQuery,
      sortBy: sortBy as string,
    });
  };

  //#endregion

  const [defaultSortFieldId, setDefaultSortFieldId] = useState(
    getSortByColumnName("created_at:DESC")
  );

  return (
    <React.Fragment>
      <ToastContainer newestOnTop={true} />
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t("appUsers")} pageTitle={t("users")} />
          <Row>
            <Col xxl={12}>
              <Card>
                <CardBody>
                  <Row className="g-2 mb-3">
                    <Col sm={4} className="mb-2">
                      <div className="search-box">
                        <DebounceInput
                          className="form-control"
                          placeholder={`${t("searchPlaceholder")}...`}
                          minLength={2}
                          debounceTimeout={300}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => onSearch(event.target.value)}
                          value={paginateQuery.search}
                        />
                        <i className="ri-search-line search-icon"></i>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12}>
                      <AppTable
                        theme={"dark"}
                        columns={columns}
                        data={dataGetPaginatedAppUsers?.data || []}
                        progressPending={isFetchingGetPaginatedAppUsers}
                        progressComponent={<TableSpinner />}
                        pagination
                        paginationServer
                        sortServer
                        onSort={onSort}
                        onChangeRowsPerPage={onChangeRowsPerPage}
                        onChangePage={onChangePage}
                        defaultSortFieldId={defaultSortFieldId}
                        paginationDefaultPage={defaultPage}
                        defaultSortAsc={defaultSortAsc}
                        persistTableHead
                        paginationTotalRows={
                          dataGetPaginatedAppUsers?.meta?.totalItems
                        }
                        paginationComponentOptions={{
                          rowsPerPageText: t("rowsPerPage"),
                          rangeSeparatorText: t("of"),
                        }}
                        fixedHeader={true}
                        fixedHeaderScrollHeight="60vh"
                      />
                    </Col>
                  </Row>
                  <DeleteModal
                    loading={isLoadingDeleteUser}
                    show={showDeleteModal}
                    onDeleteClick={() => onDeleteConfirm()}
                    onCloseClick={() => {
                      setShowDeleteModal(false);
                    }}
                  />
                  <ConfirmationModal
                    confirmationButtonInProgressTitle={t("saving")}
                    confirmationButtonTitle={
                      (selectedUser?.blockingState?.isBlocked as boolean)
                        ? t("unblock")
                        : t("block")
                    }
                    description={
                      (selectedUser?.blockingState?.isBlocked as boolean)
                        ? t("unblockConfirmationMessage", {
                            username: selectedUser?.name.en,
                          })
                        : t("blockConfirmationMessage", {
                            username: selectedUser?.name.en,
                          })
                    }
                    icon={<></>}
                    title={t("areYouSure")}
                    loading={isLoadingToggleBlock}
                    show={showBlockUnblockModal}
                    onConfirmClick={() => onBlockUnblockConfirm()}
                    onCloseClick={() => {
                      setShowBlockUnblockModal(false);
                    }}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
