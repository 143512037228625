import { Button } from "reactstrap";
import { ActionTypeEnum } from "../../enums";
import { useTranslation } from "react-i18next";

type TProps = {
  actionType: ActionTypeEnum;
  state?: boolean;
  onAction: () => void;
  title?: string;
};
export const ActionButton: React.FC<TProps> = ({
  actionType,
  onAction,
  state = false,
}) => {
  const { t } = useTranslation();

  if (actionType === ActionTypeEnum.EDIT)
    return (
      <Button
        color="primary"
        className="btn-icon btn-sm"
        onClick={onAction}
        title={t("edit")}
      >
        <i className="ri-pencil-fill" />
      </Button>
    );

  if (actionType === ActionTypeEnum.DELETE)
    return (
      <Button
        color="danger"
        className="btn-icon btn-sm"
        onClick={onAction}
        title={t("delete")}
      >
        <i className="ri-delete-bin-5-line" />
      </Button>
    );

  if (actionType === ActionTypeEnum.BLOCK) {
    if (state === true) {
      return (
        <Button
          color="primary"
          className="btn-icon btn-sm"
          onClick={onAction}
          title={t("unblock")}
        >
          <i className="ri-checkbox-circle-line" />
        </Button>
      );
    } else if (state === false) {
      return (
        <Button
          color="danger"
          className="btn-icon btn-sm"
          onClick={onAction}
          title={t("block")}
        >
          <i className="ri-forbid-line" />
        </Button>
      );
    }
  }
  if (actionType === ActionTypeEnum.ACTIVE_INACTIVE) {
    if (state === true) {
      return (
        <Button
          color="primary"
          className="btn-icon btn-sm"
          onClick={onAction}
          title={t("activate")}
        >
          <i className="ri-toggle-line" />
        </Button>
      );
    } else if (state === false) {
      return (
        <Button
          color="danger"
          className="btn-icon btn-sm"
          onClick={onAction}
          title={t("inactivate")}
        >
          <i className="ri-toggle-fill" />
        </Button>
      );
    }
  }
};
