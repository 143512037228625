import { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import { Row, Col, Card, CardBody, Container, Form, Spinner } from "reactstrap";
import * as Yup from "yup";
import React from "react";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import { Link, useParams, useHistory } from "react-router-dom";

import logo from "../../assets/images/logo.png";
import { ParticlesAuth } from "../authInner";
import { ActivityButton } from "../../components/Common";
import {
  EncryptionService,
  getServerError,
  useResendOTPMutation,
  useVerifyMutation,
} from "../../services";
import {
  ErrorHandlingTypeEnum,
  VerificationMethodEnum,
  VerificationTypeEnum,
} from "../../enums";
import { VerificationRequest } from "../../interfaces";
import LanguageDropdown from "../../components/Common/LanguageDropdown";

export const OTPVerificationPage = () => {
  //#region hooks usage
  const { t } = useTranslation();
  const history = useHistory();
  //#endregion

  //#region internal state
  const [otp, setOtp] = useState("");
  const { phone: encPhone } = useParams<any>();
  const [displayPhone, setDisplayPhone] = useState<string>();
  const [verificationRequest, setVerificationRequest] =
    useState<VerificationRequest>({
      type: VerificationTypeEnum.RESET_PASSWORD,
      method: VerificationMethodEnum.PHONE,
    });
  //#endregion

  //#region others
  document.title = `${t("documentTitle", { pageName: "verifyPhone" })}`;
  //#region

  //#region api
  const [
    verify,
    {
      isError: isErrorVerifyOTP,
      isLoading: isLoadingVerifyOTP,
      error: errorVerifyOTP,
      isSuccess: isSuccessVerifyOTP,
      data: dataVerifyOTP,
    },
  ] = useVerifyMutation();

  const [
    resendOTP,
    {
      isError: isErrorResendOTP,
      isLoading: isLoadingResendOTP,
      error: errorResendOTP,
      isSuccess: isSuccessResendOTP,
      data: dataResendOTP,
    },
  ] = useResendOTPMutation();
  //#endregion

  //#region form
  const form = useFormik({
    enableReinitialize: true,
    initialValues: {
      countryCode: verificationRequest.countryCode,
      phoneNumber: verificationRequest.phoneNumber,
      type: verificationRequest.type,
      method: verificationRequest.method,
      code: verificationRequest.code,
    },
    validationSchema: Yup.object({
      code: Yup.string(),
    }),
    onSubmit: async (values) => {
      if (otp.length < 5) return;
      await verify({
        ...values,
        code: otp,
        countryCode: verificationRequest.countryCode,
        phoneNumber: verificationRequest.phoneNumber,
      });
    },
  });

  //#endregion

  //#region effects
  useEffect(() => {
    if (encPhone) {
      try {
        const decryPhone: string = EncryptionService.decrypt(encPhone);
        const phoneArray: string[] = decryPhone.split("-");
        if (phoneArray && phoneArray.length === 2) {
          setVerificationRequest({
            ...verificationRequest,
            countryCode: phoneArray[0],
            phoneNumber: phoneArray[1],
          });
          setDisplayPhone(`+${phoneArray[0]} ${phoneArray[1]}`);
        }
      } catch (error) {
        history.goBack();
      }
    }
  }, [encPhone]);

  useEffect(() => {
    if (!isLoadingVerifyOTP && isErrorVerifyOTP) {
      const _parsed = getServerError(errorVerifyOTP);
      if (_parsed.type === ErrorHandlingTypeEnum.FORM) {
        form.setErrors(_parsed.error);
      }
      if (_parsed.type === ErrorHandlingTypeEnum.ALERT) {
        toast.error(_parsed.error, {
          autoClose: 3000,
        });
      }
    }
    const encCode = EncryptionService.encrypt(otp);
    if (!isLoadingVerifyOTP && isSuccessVerifyOTP && dataVerifyOTP) {
      history.replace(`/reset-password/${encPhone}/${encCode}`);
    }
  }, [isLoadingVerifyOTP]);

  useEffect(() => {
    if (!isLoadingResendOTP && isErrorResendOTP) {
      const _parsed = getServerError(errorResendOTP);
      if (_parsed.type === ErrorHandlingTypeEnum.FORM) {
        form.setErrors(_parsed.error);
      }
      if (_parsed.type === ErrorHandlingTypeEnum.ALERT) {
        toast.error(_parsed.error, {
          autoClose: 3000,
        });
      }
    }
  }, [isLoadingResendOTP]);

  //#endregion

  //#region handlers
  const resendVerificationCode = async () => {
    await resendOTP({
      countryCode: verificationRequest.countryCode,
      phoneNumber: verificationRequest.phoneNumber,
      method: VerificationMethodEnum.PHONE,
      type: VerificationTypeEnum.RESET_PASSWORD,
    });
  };

  //#endregion

  return (
    <React.Fragment>
      <ToastContainer newestOnTop={true} />
      <ParticlesAuth>
        <div className="auth-page-content">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="mt-4">
                  <CardBody className="p-4">
                    <div className="text-center mt-1">
                      <img src={logo} alt="" height="100" width="100" />
                      <h5 className="text-primary mt-2">
                        {t("verifyYourPhone")}
                      </h5>
                      <p className="text-muted">
                        {t("enterVerificationCode")}
                        <span dir="ltr">{displayPhone}</span>
                      </p>
                    </div>
                    <div className="mt-4">
                      <div className="justify-content-center align-items-center d-flex">
                        <LanguageDropdown />
                      </div>
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          form.handleSubmit();
                          return false;
                        }}
                      >
                        <div className="mb-3">
                          <OtpInput
                            value={otp}
                            onChange={setOtp}
                            numInputs={5}
                            renderSeparator={
                              <span className="px-1 bold"></span>
                            }
                            renderInput={(props) => <input {...props} />}
                            inputType={"number"}
                            inputStyle={{
                              width: "20%",
                              height: "50px",
                              fontSize: "1.5rem",
                              borderColor: "#f3f6f9",
                              borderStyle: "solid",
                              borderRadius: "5px",
                              borderWidth: 0.2,
                              backgroundColor: "#f3f6f9",
                            }}
                          />
                        </div>
                        <div className="text-center mt-4">
                          <ActivityButton
                            title="verify"
                            inProgressTitle="verifying"
                            color="success"
                            className="btn btn-success w-100"
                            type="submit"
                            inProgress={isLoadingVerifyOTP}
                            disabled={isLoadingVerifyOTP}
                          ></ActivityButton>
                          <div className="mt-3">
                            {isLoadingResendOTP ? (
                              <Spinner
                                size="sm"
                                className="flex-shrink-0"
                                role="status"
                              >
                                {t("inProgress")}...
                              </Spinner>
                            ) : (
                              <Link
                                to="#"
                                onClick={resendVerificationCode}
                                className="text-muted"
                              >
                                {t("resendVerificationCode")}
                              </Link>
                            )}
                          </div>
                        </div>
                      </Form>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-4 text-center">
                  <p className="mb-0 text-white">
                    {t("backToLogin")}...{" "}
                    <Link
                      to="/login"
                      className="fw-semibold text-white text-decoration-underline"
                    >
                      {" "}
                      {t("clickHere")}{" "}
                    </Link>{" "}
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </ParticlesAuth>
    </React.Fragment>
  );
};
