import { createApi } from "@reduxjs/toolkit/query/react";

import { MenuDto, PaginateQuery, UpdateMediaRequest } from "../interfaces";
import { baseQueryWithInterceptor } from "./base.api";
import { Paginated } from "../models";
import { convertObjectToQueryParams } from "./helper.service";
import { UpdateMenuRequest } from "../interfaces/menu/update-menu-request";
import { MediaDto } from "../interfaces/media";

export const menuApi = createApi({
  reducerPath: "menuApi",
  baseQuery: baseQueryWithInterceptor,
  endpoints: (builder) => ({
    getPaginatedMenu: builder.query<Paginated<MenuDto>, PaginateQuery>({
      query: (query) => ({
        url: `/menu/paginate?${convertObjectToQueryParams(query)}`,
        method: "GET",
      }),
      providesTags: ["menu"],
    }),
    toggleMenuState: builder.mutation<void, string>({
      query: (id) => ({
        url: `/menu/${id}/toggle-state`,
        method: "PATCH",
      }),
      invalidatesTags: ["menu"],
    }),
    getMenuById: builder.query<MenuDto, string>({
      query: (id) => ({
        url: `/menu/${id}`,
        method: "GET",
      }),
    }),
    updateMenu: builder.mutation<void, UpdateMenuRequest>({
      query: (body) => ({
        url: `/menu/${body.id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["menu"],
    }),
    uploadMenuImage: builder.mutation<MediaDto, UpdateMediaRequest>({
      query: (body) => {
        var bodyFormData = new FormData();
        bodyFormData.append("file", body.media);
        let url = `/menu/upload/image`;
        if (body.id) url += `?id=${body.id}`;
        return {
          url,
          method: "POST",
          body: bodyFormData,
          formData: true,
        };
      },
    }),
  }),
  tagTypes: ["menu"],
});

export const {
  useLazyGetPaginatedMenuQuery,
  useToggleMenuStateMutation,
  useLazyGetMenuByIdQuery,
  useUpdateMenuMutation,
  useUploadMenuImageMutation,
} = menuApi;
