import { useSelector } from "react-redux";
import { TableSpinner } from "./TableSpinner";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import DataTable, { createTheme } from "react-data-table-component";

import { NoResultFound } from "../Common";

createTheme("dark", {
  text: {
    primary: "#fff",
    secondary: "#fff",
  },
  background: {
    default: "#1a1d21",
  },
  context: {
    background: "#cb4b16",
    text: "#FFFFFF",
  },
  divider: {
    default: "#32383e",
  },
  sortFocus: {
    default: "#32383e",
  },
});

export const AppTable: React.FC<any> = (props) => {
  const layoutModeType = useSelector(
    (state: any) => state.theme.layoutModeType
  );
  const [theme, setTheme] = useState<string>("light");
  const { t } = useTranslation();

  useEffect(() => {
    setTheme(layoutModeType === "white" ? "light" : "dark");
  }, [layoutModeType]);

  return (
    <DataTable
      {...props}
      theme={theme}
      paginationComponentOptions={{
        rowsPerPageText: t("rowsPerPage"),
        rangeSeparatorText: t("of"),
      }}
      persistTableHead
      progressComponent={<TableSpinner />}
      pagination
      paginationServer
      sortServer
      fixedHeader={true}
      fixedHeaderScrollHeight="60vh"
      noDataComponent={<NoResultFound message="noRecordsFound" />}
    />
  );
};
