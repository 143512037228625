import React from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import * as Yup from "yup";

import { UpdateAppSettingsRequest } from "../../interfaces/app-settings";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap";
import { useFormik } from "formik";

import {
  getServerError,
  useGetAppSettingsQuery,
  useUpdateAppSettingsMutation,
} from "../../services";
import { ActivityButton } from "../../components/Common";
import { AppSettingsTypeEnum, ErrorHandlingTypeEnum } from "../../enums";

export const AppVersionPage = () => {
  //#region hooks usage
  const { t } = useTranslation();
  //#endregion

  //#region others
  document.title = `${t("documentTitle", {
    pageName: "controlAppAppVersion",
  })}`;
  //#endregion

  //#region api
  const { isLoading: isLoadingAppSettings, data: dataAppSettings } =
    useGetAppSettingsQuery(AppSettingsTypeEnum.APPLICATION_VERSION);

  const [
    updateAppSettings,
    {
      isError: isErrorUpdateAppSettings,
      isLoading: isLoadingUpdateAppSettings,
      error: errorUpdateAppSettings,
      isSuccess: isSuccessUpdateAppSettings,
      data: dataUpdateAppSettings,
    },
  ] = useUpdateAppSettingsMutation();

  //#endregion

  //#region form
  const form = useFormik<UpdateAppSettingsRequest>({
    enableReinitialize: true,
    initialValues: {
      value: {
        en: dataAppSettings?.value.en || "",
        ar: dataAppSettings?.value.ar || "",
      },
      type: AppSettingsTypeEnum.APPLICATION_VERSION,
    },
    validationSchema: Yup.object({
      value: Yup.object({
        en: Yup.string()
          .max(
            10,
            t("maxLength", { maxLength: 10, displayName: "englishValue" })
          )
          .required(
            t("isNotEmpty", {
              displayName: "englishValue",
            })
          ),
        ar: Yup.string()
          .max(
            10,
            t("maxLength", { maxLength: 10, displayName: "englishValue" })
          )
          .required(
            t("isNotEmpty", {
              displayName: "arabicValue",
            })
          ),
      }),
    }),
    onSubmit: async (values) => {
      await updateAppSettings({
        ...values,
        type: AppSettingsTypeEnum.APPLICATION_VERSION,
      });
    },
  });
  //#endregion

  //#region effects
  useEffect(() => {
    if (dataAppSettings) {
      form.setValues({
        value: {
          en: dataAppSettings.value.en,
          ar: dataAppSettings.value.ar,
        },
        type: AppSettingsTypeEnum.APPLICATION_VERSION,
      });
    }
  }, [dataAppSettings]);

  useEffect(() => {
    if (!isLoadingUpdateAppSettings && isErrorUpdateAppSettings) {
      const _parsed = getServerError(errorUpdateAppSettings);
      if (_parsed.type === ErrorHandlingTypeEnum.FORM) {
        form.setErrors(_parsed.error);
      }
      if (_parsed.type === ErrorHandlingTypeEnum.ALERT) {
        toast.error(_parsed.error, {
          autoClose: 3000,
        });
      }
    }
    if (!isLoadingUpdateAppSettings && isSuccessUpdateAppSettings) {
      toast.success(t("savedSuccessfully"), {
        autoClose: 3000,
      });
    }
  }, [isLoadingUpdateAppSettings]);

  //#endregion

  return (
    <React.Fragment>
      <ToastContainer />
      <Row>
        <Col>
          <Card>
            <CardHeader>
              <h6 className="card-title mb-0">{t("applicationVersion")}</h6>
            </CardHeader>
            <CardBody>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  form.handleSubmit();
                  return false;
                }}
                action="#"
              >
                <Row>
                  <Col>
                    <div className="mb-3">
                      <label htmlFor="value.en" className="form-label">
                        {t("valueEn")}
                        <span className="text-danger">*</span>
                      </label>
                      <Input
                        autoComplete="off"
                        id="value.en"
                        name="value.en"
                        value={form.values?.value.en}
                        type="text"
                        className="form-control"
                        placeholder={t("enterInEnglish")}
                        onChange={form.handleChange}
                        onBlur={form.handleBlur}
                        invalid={
                          form.touched.value?.en && form.errors.value?.en
                            ? true
                            : false
                        }
                      />
                      {form.touched.value?.en && form.errors.value?.en ? (
                        <FormFeedback type="invalid">
                          {form.errors.value?.en as string}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col>
                    <div className="mb-3">
                      <Label htmlFor="value.ar" className="form-label">
                        {t("valueAr")}
                        <span className="text-danger">*</span>
                      </Label>
                      <Input
                        autoComplete="off"
                        id="value.ar"
                        name="value.ar"
                        value={form.values.value?.ar}
                        type="text"
                        className="form-control"
                        placeholder={t("enterInArabic")}
                        style={{
                          direction: "rtl",
                          fontFamily: "aljannat",
                        }}
                        onChange={form.handleChange}
                        onBlur={form.handleBlur}
                        invalid={
                          form.touched.value?.ar && form.errors.value?.ar
                            ? true
                            : false
                        }
                      />
                      {form.touched.value?.ar && form.errors.value?.ar ? (
                        <FormFeedback type="invalid">
                          {form.errors.value?.ar as string}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "center",
                    }}
                  >
                    <ActivityButton
                      title="save"
                      inProgressTitle="saving"
                      color="success"
                      className="btn btn-success"
                      type="submit"
                      inProgress={
                        isLoadingAppSettings || isLoadingUpdateAppSettings
                      }
                      disabled={
                        isLoadingAppSettings || isLoadingUpdateAppSettings
                      }
                    ></ActivityButton>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};
