import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";

import {
  ErrorHandlingTypeEnum,
  ItemTypeEnum,
  MeasuringUnitEnum,
} from "../../../enums";
import { ActivityButton } from "../../../components/Common";
import { CreateItemRequest } from "../../../interfaces";
import {
  getServerError,
  useAddItemMutation,
  useLazyGetItemByIdQuery,
  useUpdateItemMutation,
} from "../../../services";
import { UpdateItemRequest } from "../../../interfaces/item/update-item-request";
import { InputLocalized } from "../../../components";
import {
  LocalizedPropertyInitialErrors,
  LocalizedPropertyInitialTouched,
  LocalizedPropertyInitialValues,
  LocalizedPropertyYupSchema,
} from "../../../constants/language.constant";

type TProps = {
  id?: string | null;
  show: boolean;
  onCloseClick: (event: any) => void;
};
export const AddEditZakatModal: React.FC<TProps> = ({
  id,
  show,
  onCloseClick,
}) => {
  //#region hooks
  const { t } = useTranslation();
  //#endregion

  //#region api
  const [
    addItem,
    {
      isLoading: isLoadingAddItem,
      isError: isErrorAddItem,
      isSuccess: isSuccessAddItem,
      error: errorAddItem,
    },
  ] = useAddItemMutation();

  const [
    updateItem,
    {
      isLoading: isLoadingUpdateItem,
      isError: isErrorUpdateItem,
      isSuccess: isSuccessUpdateItem,
      error: errorUpdateItem,
    },
  ] = useUpdateItemMutation();

  const [
    getItemById,
    {
      isSuccess: isSuccessItemById,
      isFetching: isFetchingItemById,
      data: dataItemById,
    },
  ] = useLazyGetItemByIdQuery();
  //#endregion

  //#region form
  const form = useFormik<CreateItemRequest | UpdateItemRequest>({
    enableReinitialize: true,
    initialErrors: {
      title: LocalizedPropertyInitialErrors,
    },
    initialTouched: {
      title: LocalizedPropertyInitialTouched,
    },
    initialValues: {
      id: null,
      title: LocalizedPropertyInitialValues,
      percentage: 0,
      measuringUnit: MeasuringUnitEnum.Gram,
      types: [],
    },
    validationSchema: Yup.object({
      id: Yup.string().nullable(),
      title: LocalizedPropertyYupSchema("title", 255),
      percentage: Yup.number().required(
        t("isNotEmpty", {
          displayName: "percentage",
        })
      ),
      measuringUnit: Yup.mixed<MeasuringUnitEnum>().test((value) =>
        Object.values(MeasuringUnitEnum).includes(value as MeasuringUnitEnum)
      ),
      type: Yup.array(),
    }),
    onSubmit: async (values) => {
      if (id) {
        await updateItem({
          ...values,
          id: id,
          percentage: Number(values.percentage),
        });
      } else {
        await addItem({ ...values, percentage: Number(values.percentage) });
      }
    },
  });
  //#endregion

  //#region effects
  useEffect(() => {
    if (
      (!isLoadingAddItem && isSuccessAddItem) ||
      (!isLoadingUpdateItem && isSuccessUpdateItem)
    ) {
      onClose();
    }
  }, [
    isLoadingAddItem,
    isLoadingUpdateItem,
    isSuccessAddItem,
    isSuccessUpdateItem,
  ]);

  useEffect(() => {
    if (id && !isFetchingItemById && isSuccessItemById && dataItemById) {
      form.setValues({
        id: dataItemById.id,
        title: dataItemById.title,
        percentage: Number(dataItemById.percentage),
        measuringUnit: dataItemById.measuringUnit,
        types: dataItemById.types,
      });
    }
  }, [isFetchingItemById, isSuccessAddItem]);

  useEffect(() => {
    if (!isLoadingAddItem && isErrorAddItem) {
      const _parsed = getServerError(errorAddItem);
      if (_parsed.type === ErrorHandlingTypeEnum.FORM) {
        form.setErrors(_parsed.error);
      }
      if (_parsed.type === ErrorHandlingTypeEnum.ALERT) {
        toast.error(_parsed.error, {
          autoClose: 3000,
        });
      }
    }
    if (!isLoadingAddItem && isSuccessAddItem) {
      toast.success(t("savedSuccessfully"), {
        autoClose: 3000,
      });
    }
  }, [isLoadingAddItem]);

  useEffect(() => {
    if (!isLoadingUpdateItem && isErrorUpdateItem) {
      const _parsed = getServerError(errorUpdateItem);
      if (_parsed.type === ErrorHandlingTypeEnum.FORM) {
        form.setErrors(_parsed.error);
      }
      if (_parsed.type === ErrorHandlingTypeEnum.ALERT) {
        toast.error(_parsed.error, {
          autoClose: 3000,
        });
      }
    }
    if (!isLoadingUpdateItem && isSuccessUpdateItem) {
      toast.success(t("savedSuccessfully"), {
        autoClose: 3000,
      });
    }
  }, [isLoadingUpdateItem]);

  useEffect(() => {
    async function initialize() {
      await getItemById(id as string);
    }

    if (id) {
      initialize();
    }
  }, [id]);

  //#endregion

  //#region handlers
  const onClose = () => {
    form.resetForm();
    onCloseClick(!show);
  };

  //#endregion

  return (
    <React.Fragment>
      <ToastContainer />
      <Modal
        id="addEdit"
        isOpen={show}
        toggle={() => {
          onClose();
        }}
        centered
      >
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            form.handleSubmit();
            return false;
          }}
          action="#"
        >
          <ModalHeader
            className="p-3"
            toggle={() => {
              onClose();
            }}
          >
            {id
              ? t("editItem", { item: "item" })
              : t("addItem", { item: "item" })}
          </ModalHeader>
          <ModalBody>
            <InputLocalized
              tabIndex={1}
              name="title"
              form={form}
              type={"text"}
              label="name"
            />
            <div className="mb-3">
              <label htmlFor="percentage" className="form-label">
                {t("percentage")} (%){" "}
                <span className="text-info" style={{ fontSize: 10 }}>
                  ({t("notRequiredForFitrZakat")})
                </span>
              </label>
              <Input
                tabIndex={2}
                min={0}
                max={100}
                autoComplete="off"
                id="percentage"
                name="percentage"
                value={form.values?.percentage}
                className="form-control"
                placeholder={t("enterPercentage")}
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                invalid={
                  form.touched.percentage && form.errors.percentage
                    ? true
                    : false
                }
              />
              {form.touched.percentage && form.errors.percentage ? (
                <FormFeedback type="invalid">
                  {form.errors.percentage as string}
                </FormFeedback>
              ) : null}
            </div>
            <div className="mb-3">
              <Col lg={4} md={6}>
                <div className="form-check mb-2">
                  <Input
                    tabIndex={3}
                    className="form-check-input"
                    type="checkbox"
                    id="formCheck1"
                    onChange={(e) => {
                      const checked = e.target.checked;
                      if (checked) {
                        form.setFieldValue("types", [
                          ...form.values.types,
                          ItemTypeEnum.Annual_Zakat,
                        ]);
                      } else {
                        form.setFieldValue(
                          "types",
                          form.values.types.filter(
                            (v) => v !== ItemTypeEnum.Annual_Zakat
                          )
                        );
                      }
                    }}
                    checked={form.values.types.includes(
                      ItemTypeEnum.Annual_Zakat
                    )}
                  />
                  <Label className="form-check-label" for="formCheck1">
                    {t(ItemTypeEnum.Annual_Zakat)}
                  </Label>
                </div>
                <div className="form-check">
                  <Input
                    tabIndex={4}
                    className="form-check-input"
                    type="checkbox"
                    id="formCheck2"
                    onChange={(e) => {
                      const checked = e.target.checked;
                      if (checked) {
                        form.setFieldValue("types", [
                          ...form.values.types,
                          ItemTypeEnum.Fitr_Zakat,
                        ]);
                      } else {
                        form.setFieldValue(
                          "types",
                          form.values.types.filter(
                            (v) => v !== ItemTypeEnum.Fitr_Zakat
                          )
                        );
                      }
                    }}
                    checked={form.values.types.includes(
                      ItemTypeEnum.Fitr_Zakat
                    )}
                  />
                  <Label className="form-check-label" for="formCheck2">
                    {t(ItemTypeEnum.Fitr_Zakat)}
                  </Label>
                </div>
              </Col>
            </div>
            <div className="mb-3 ">
              <Label htmlFor="title" className="form-label">
                {t("measuringUnit")}
                <span className="text-danger">*</span>
              </Label>
              <Input
                tabIndex={5}
                type="select"
                id="measuringUnit"
                name="measuringUnit"
                className="form-select"
                aria-label={t("chooseMeasuringUnit")}
                placeholder={t("chooseMeasuringUnit")}
                value={form.values.measuringUnit?.toString()}
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                invalid={
                  form.touched.measuringUnit && form.errors.measuringUnit
                    ? true
                    : false
                }
              >
                {Object.keys(MeasuringUnitEnum).map((key) => (
                  <option
                    key={key}
                    id={MeasuringUnitEnum[key as any]}
                    value={MeasuringUnitEnum[key as any]}
                    className="text-capitalize"
                  >
                    {key}
                  </option>
                ))}
              </Input>
              {form.touched.measuringUnit && form.errors.measuringUnit ? (
                <FormFeedback type="invalid">
                  {form.errors.measuringUnit}
                </FormFeedback>
              ) : null}
            </div>
          </ModalBody>
          <ModalFooter>
            <Link
              to={"#"}
              onClick={onClose}
              className="btn btn-link link-success fw-medium"
            >
              <i className="ri-close-line me-1 align-middle"></i> {t("close")}
            </Link>
            <ActivityButton
              tabIndex={6}
              title="save"
              inProgressTitle="saving"
              color="success"
              className="btn btn-success"
              type="submit"
              inProgress={isLoadingAddItem || isLoadingUpdateItem}
              disabled={isLoadingAddItem || isLoadingUpdateItem}
            ></ActivityButton>
          </ModalFooter>
        </Form>
      </Modal>
    </React.Fragment>
  );
};
