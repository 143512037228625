import { createSlice } from "@reduxjs/toolkit";
import {
  layoutModeTypes,
  layoutPositionTypes,
  layoutTypes,
  layoutWidthTypes,
  leftSidebarImageTypes,
  leftSidebarTypes,
  leftSidebarViewTypes,
  leftsidbarSizeTypes,
  preloaderTypes,
  topbarThemeTypes,
} from "../../components/constants/layout";

const INIT_STATE = {
  layoutType: layoutTypes.VERTICAL,
  leftSidebarType: leftSidebarTypes.DARK,
  layoutModeType: layoutModeTypes.LIGHTMODE,
  layoutWidthType: layoutWidthTypes.FLUID,
  layoutPositionType: layoutPositionTypes.FIXED,
  topbarThemeType: topbarThemeTypes.LIGHT,
  leftsidbarSizeType: leftsidbarSizeTypes.DEFAULT,
  leftSidebarViewType: leftSidebarViewTypes.DEFAULT,
  leftSidebarImageType: leftSidebarImageTypes.NONE,
  preloader: preloaderTypes.DISABLE,
};

const slice = createSlice({
  name: "theme",
  initialState: INIT_STATE,
  reducers: {
    changeLayout: (state, { payload: { layoutType } }: ThemePayload) => {
      state.layoutType = layoutType || INIT_STATE.layoutType;
      try {
        if (layoutType === "twocolumn") {
          document.documentElement.removeAttribute("data-layout-width");
        } else if (layoutType === "horizontal") {
          document.documentElement.removeAttribute("data-sidebar-size");
        }
        changeHTMLAttribute("data-layout", layoutType);
      } catch (error) {
        // console.log(error);
      }
    },
    changeLayoutMode: (
      state,
      { payload: { layoutModeType } }: ThemePayload
    ) => {
      state.layoutModeType = layoutModeType || INIT_STATE.layoutModeType;
      changeHTMLAttribute("data-layout-mode", layoutModeType);
    },
    changeSidebarTheme: (
      state,
      { payload: { leftSidebarType } }: ThemePayload
    ) => {
      state.leftSidebarType = leftSidebarType || INIT_STATE.leftSidebarType;
      changeHTMLAttribute("data-sidebar", leftSidebarType);
    },
    changeLayoutWidth: (
      state,
      { payload: { layoutWidthType } }: ThemePayload
    ) => {
      state.layoutWidthType = layoutWidthType || INIT_STATE.layoutWidthType;
      try {
        if (layoutWidthType === "lg") {
          changeHTMLAttribute("data-layout-width", "fluid");
        } else {
          changeHTMLAttribute("data-layout-width", "boxed");
        }
      } catch (error) {}
    },
    changeLayoutPosition: (
      state,
      { payload: { layoutPositionType } }: ThemePayload
    ) => {
      state.layoutPositionType =
        layoutPositionType || INIT_STATE.layoutPositionType;
      changeHTMLAttribute("data-layout-position", layoutPositionType);
    },
    changeTopbarTheme: (
      state,
      { payload: { topbarThemeType } }: ThemePayload
    ) => {
      state.topbarThemeType = topbarThemeType || INIT_STATE.topbarThemeType;
      changeHTMLAttribute("data-topbar", topbarThemeType);
    },
    changeLeftsidebarSizeType: (
      state,
      { payload: { leftsidbarSizeType } }: ThemePayload
    ) => {
      state.leftsidbarSizeType =
        leftsidbarSizeType || INIT_STATE.leftsidbarSizeType;
      try {
        switch (leftsidbarSizeType) {
          case "lg":
            changeHTMLAttribute("data-sidebar-size", "lg");
            break;
          case "md":
            changeHTMLAttribute("data-sidebar-size", "md");
            break;
          case "sm":
            changeHTMLAttribute("data-sidebar-size", "sm");
            break;
          case "sm-hover":
            changeHTMLAttribute("data-sidebar-size", "sm-hover");
            break;
          default:
            changeHTMLAttribute("data-sidebar-size", "lg");
        }
      } catch (error) {
        // console.log(error);
      }
    },
    changeLeftsidebarViewType: (
      state,
      { payload: { leftSidebarViewType } }: ThemePayload
    ) => {
      state.leftSidebarViewType =
        leftSidebarViewType || INIT_STATE.leftSidebarViewType;
      changeHTMLAttribute("data-layout-style", leftSidebarViewType);
    },
    changeSidebarImageType: (
      state,
      { payload: { leftSidebarImageType } }: ThemePayload
    ) => {
      state.leftSidebarImageType =
        leftSidebarImageType || INIT_STATE.leftSidebarImageType;
      changeHTMLAttribute("data-sidebar-image", leftSidebarImageType);
    },
    resetValue: (state, { payload: {} }: ThemePayload) => {
      state = { ...INIT_STATE };
    },
    changePreLoader: (state, { payload: { preloader } }: ThemePayload) => {
      state.preloader = preloader || INIT_STATE.preloader;
      changeHTMLAttribute("data-preloader", preloader);
    },
  },
});

export const {
  changeLayout,
  changeLayoutMode,
  changeSidebarTheme,
  changeLayoutWidth,
  changeLayoutPosition,
  changeTopbarTheme,
  changeLeftsidebarSizeType,
  changeLeftsidebarViewType,
  changeSidebarImageType,
  changePreLoader,
} = slice.actions;

export default slice.reducer;

export type ThemeState = {
  layoutType: string;
  leftSidebarType: string;
  layoutModeType: string;
  layoutWidthType: string;
  layoutPositionType: string;
  topbarThemeType: string;
  leftsidbarSizeType: string;
  leftSidebarViewType: string;
  leftSidebarImageType: string;
  preloader: string;
};

type ThemePayload = {
  payload: Partial<ThemeState>;
};

function changeHTMLAttribute(attribute: any, value: any) {
  if (document.documentElement)
    document.documentElement.setAttribute(attribute, value);
  return true;
}
