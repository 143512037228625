import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  Form,
  FormFeedback,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";

import {
  useAddCalendarEventMutation,
  useDeleteCalendarEventMutation,
  useUpdateCalendarEventMutation,
} from "../../../services/calendar.api.service";
import { getServerError } from "../../../services";
import { ErrorHandlingTypeEnum } from "../../../enums";
import { ActivityButton } from "../../../components/Common";
import DeleteModal from "../../../components/Common/DeleteModal";
import {
  CreateCalendarEventRequest,
  CalendarEventDto,
} from "../../../interfaces";

type TProps = {
  calendarEvent: CalendarEventDto;
  show: boolean;
  onCloseClick: (event: any) => void;
};
export const AddEditEventModal: React.FC<TProps> = ({
  calendarEvent,
  show,
  onCloseClick,
}) => {
  //#region hooks usage
  const { t } = useTranslation();
  //#endregion

  //#region internal state
  const [request] = useState<CreateCalendarEventRequest>();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  //#endregion

  //#region api
  const [
    addEvent,
    {
      isError: isErrorAddEvent,
      isLoading: isLoadingAddEvent,
      error: errorAddEvent,
      isSuccess: isSuccessAddEvent,
    },
  ] = useAddCalendarEventMutation();

  const [
    updateEvent,
    {
      isError: isErrorUpdateEvent,
      isLoading: isLoadingUpdateEvent,
      error: errorUpdateEvent,
      isSuccess: isSuccessUpdateEvent,
    },
  ] = useUpdateCalendarEventMutation();

  const [
    deleteEvent,
    {
      isError: isErrorDeleteEvent,
      isLoading: isLoadingDeleteEvent,
      error: errorDeleteEvent,
      isSuccess: isSuccessDeleteEvent,
    },
  ] = useDeleteCalendarEventMutation();

  //#endregion

  //#region form
  const form = useFormik<CreateCalendarEventRequest>({
    enableReinitialize: true,
    initialValues: {
      title: {
        en: request?.title.en || "",
        ar: request?.title.ar || "",
      },
      allDay: calendarEvent?.allDay || false,
      startAt: calendarEvent?.startAt,
      endAt: calendarEvent?.endAt,
    },
    validationSchema: Yup.object({
      title: Yup.object({
        en: Yup.string()
          .max(
            100,
            t("maxLength", { maxLength: 100, displayName: "englishValue" })
          )
          .required(
            t("isNotEmpty", {
              displayName: "englishValue",
            })
          ),
        ar: Yup.string()
          .max(
            100,
            t("maxLength", { maxLength: 100, displayName: "englishValue" })
          )
          .required(
            t("isNotEmpty", {
              displayName: "arabicValue",
            })
          ),
      }),
      startAt: Yup.date().required(),
      endAt: Yup.date().required(),
      allDay: Yup.boolean().required(),
    }),
    onSubmit: async (values) => {
      if (calendarEvent.id) {
        await updateEvent({ ...values, id: calendarEvent.id });
      } else {
        await addEvent(values);
      }
    },
  });
  //#endregion

  //#region effects
  useEffect(() => {
    if (
      (!isLoadingAddEvent && isSuccessAddEvent) ||
      (!isLoadingUpdateEvent && isSuccessUpdateEvent) ||
      (!isLoadingDeleteEvent && isSuccessDeleteEvent)
    ) {
      onClose();
      setShowDeleteModal(false);
    }
  }, [isLoadingAddEvent, isLoadingUpdateEvent, isLoadingDeleteEvent]);

  useEffect(() => {
    form.setValues({
      title: calendarEvent?.title,
      startAt: calendarEvent?.startAt,
      endAt: calendarEvent?.endAt,
      allDay: calendarEvent?.allDay,
    });
  }, [calendarEvent]);

  useEffect(() => {
    if (!isLoadingAddEvent && isErrorAddEvent) {
      const _parsed = getServerError(errorAddEvent);
      if (_parsed.type === ErrorHandlingTypeEnum.FORM) {
        form.setErrors(_parsed.error);
      }
      if (_parsed.type === ErrorHandlingTypeEnum.ALERT) {
        toast.error(_parsed.error, {
          autoClose: 3000,
        });
      }
    }
  }, [isLoadingAddEvent]);

  useEffect(() => {
    if (!isLoadingUpdateEvent && isErrorUpdateEvent) {
      const _parsed = getServerError(errorUpdateEvent);
      if (_parsed.type === ErrorHandlingTypeEnum.FORM) {
        form.setErrors(_parsed.error);
      }
      if (_parsed.type === ErrorHandlingTypeEnum.ALERT) {
        toast.error(_parsed.error, {
          autoClose: 3000,
        });
      }
    }
  }, [isLoadingUpdateEvent]);

  useEffect(() => {
    if (!isLoadingDeleteEvent && isErrorDeleteEvent) {
      const _parsed = getServerError(errorDeleteEvent);
      if (_parsed.type === ErrorHandlingTypeEnum.FORM) {
        form.setErrors(_parsed.error);
      }
      if (_parsed.type === ErrorHandlingTypeEnum.ALERT) {
        toast.error(_parsed.error, {
          autoClose: 3000,
        });
      }
    }
  }, [isLoadingDeleteEvent]);

  //#endregion

  //#region handlers
  const onClose = () => {
    form.resetForm();
    onCloseClick(!show);
  };

  const onDelete = () => {
    setShowDeleteModal(true);
  };

  const onDeleteConfirm = async () => {
    await deleteEvent(calendarEvent?.id as string);
  };
  //#endregion

  return (
    <React.Fragment>
      <ToastContainer />
      <Modal
        id="addEdit"
        isOpen={show}
        toggle={() => {
          onClose();
        }}
        centered
      >
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            form.handleSubmit();
            return false;
          }}
          action="#"
        >
          <ModalHeader
            className="p-3"
            toggle={() => {
              onClose();
            }}
          >
            {calendarEvent && calendarEvent?.id
              ? t("editDeleteEvent")
              : t("addItem", { item: "calendarEvent" })}
          </ModalHeader>
          <ModalBody>
            <div className="mb-3">
              <label htmlFor="title.en" className="form-label">
                {t("titleEn")}
                <span className="text-danger">*</span>
              </label>
              <Input
                autoComplete="off"
                id="title.en"
                name="title.en"
                value={form.values?.title?.en}
                type="text"
                className="form-control"
                placeholder={t("enterInEnglish")}
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                invalid={
                  form.touched.title?.en && form.errors.title?.en ? true : false
                }
              />
              {form.touched.title?.en && form.errors.title?.en ? (
                <FormFeedback type="invalid">
                  {form.errors.title?.en as string}
                </FormFeedback>
              ) : null}
            </div>
            <div className="mb-3">
              <label htmlFor="title.ar" className="form-label">
                {t("titleAr")}
                <span className="text-danger">*</span>
              </label>
              <Input
                style={{ fontFamily: "aljannat", direction: "rtl" }}
                autoComplete="off"
                auto
                id="title.ar"
                name="title.ar"
                value={form.values.title?.ar}
                type="text"
                className="form-control"
                placeholder={t("enterInArabic")}
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                invalid={
                  form.touched.title?.ar && form.errors.title?.ar ? true : false
                }
              />
              {form.touched.title?.ar && form.errors.title?.ar ? (
                <FormFeedback type="invalid">
                  {form.errors.title?.ar as string}
                </FormFeedback>
              ) : null}
            </div>
          </ModalBody>
          <ModalFooter>
            <Link
              to={"#"}
              onClick={onClose}
              className="btn btn-link link-success fw-medium"
            >
              <i className="ri-close-line me-1 align-middle"></i> {t("close")}
            </Link>
            {calendarEvent && calendarEvent?.id && (
              <ActivityButton
                onClick={onDelete}
                title="deleteEvent"
                inProgressTitle="deleting"
                color="success"
                className="btn btn-danger"
                type="button"
                inProgress={isLoadingDeleteEvent}
                disabled={
                  isLoadingAddEvent ||
                  isLoadingUpdateEvent ||
                  isLoadingDeleteEvent
                }
              ></ActivityButton>
            )}
            <ActivityButton
              title="saveEvent"
              inProgressTitle="saving"
              color="success"
              className="btn btn-success"
              type="submit"
              inProgress={
                isLoadingAddEvent ||
                isLoadingUpdateEvent ||
                isLoadingDeleteEvent
              }
              disabled={
                isLoadingAddEvent ||
                isLoadingUpdateEvent ||
                isLoadingDeleteEvent
              }
            ></ActivityButton>
          </ModalFooter>
        </Form>
      </Modal>
      <DeleteModal
        loading={isLoadingDeleteEvent}
        show={showDeleteModal}
        onDeleteClick={() => onDeleteConfirm()}
        onCloseClick={() => {
          setShowDeleteModal(false);
        }}
        // TODO:
        error={""}
      />
    </React.Fragment>
  );
};
