import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  FormFeedback,
  Input,
  Label,
  Form,
  Spinner,
} from "reactstrap";
import React from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

import logo from "../../assets/images/logo.png";
import { ParticlesAuth } from "../authInner";
import { ActivityButton } from "../../components/Common";
import LanguageDropdown from "../../components/Common/LanguageDropdown";
import {
  EncryptionService,
  getServerError,
  useForgetPasswordMutation,
} from "../../services";
import { useEffect } from "react";
import { ErrorHandlingTypeEnum, VerificationMethodEnum } from "../../enums";

export const ForgetPasswordPage = () => {
  //#region hooks usage
  const { t } = useTranslation();
  const history = useHistory();
  //#endregion

  //#region others
  document.title = `${t("documentTitle", { pageName: "forgetPassword" })}`;
  //#endregion

  //#region form
  const form = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email(
          t("isEmail", {
            displayName: "email",
          })
        )
        .max(100, t("maxLength", { maxLength: 100, displayName: "email" }))
        .required(
          t("isNotEmpty", {
            displayName: "email",
          })
        ),
    }),

    onSubmit: async (values) => {
      await forgetPassword({ ...values, method: VerificationMethodEnum.EMAIL });
    },
  });
  //#endregion

  //#region api
  const [
    forgetPassword,
    {
      isError: isErrorForgetPassword,
      isLoading: isLoadingForgetPassword,
      error: errorForgetPassword,
      isSuccess: isSuccessForgetPassword,
      data: dataForgetPassword,
    },
  ] = useForgetPasswordMutation();

  //#endregion

  //#region effects

  useEffect(() => {
    if (!isLoadingForgetPassword && isErrorForgetPassword) {
      const _parsed = getServerError(errorForgetPassword);
      if (_parsed.type === ErrorHandlingTypeEnum.FORM) {
        form.setErrors(_parsed.error);
      }
      if (_parsed.type === ErrorHandlingTypeEnum.ALERT) {
        toast.error(_parsed.error, {
          autoClose: 3000,
        });
      }
    }
    if (!isLoadingForgetPassword && isSuccessForgetPassword) {
      toast.info(t("passwordResetEmailSent"), {
        autoClose: 5000,
      });
      setTimeout(() => {
        history.replace("/");
      }, 5000);
    }
  }, [isLoadingForgetPassword]);

  //#endregion

  return (
    <React.Fragment>
      <ToastContainer newestOnTop={true} />
      <ParticlesAuth>
        <div className="auth-page-content">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="mt-4">
                  <CardBody className="p-4">
                    <div className="text-center mt-1">
                      <img src={logo} alt="" height="100" width="100" />
                      <h5 className="text-primary mt-2">
                        {t("forgetPassword?")}
                      </h5>
                      <p className="text-muted">
                        {t("forgetPasswordSubHeading")}
                      </p>
                    </div>
                    <div className="mt-4">
                      <div className="justify-content-center align-items-center d-flex">
                        <LanguageDropdown />
                      </div>

                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          form.handleSubmit();
                          return false;
                        }}
                      >
                        <div className="mb-3">
                          <Label htmlFor="email" className="form-label">
                            {t("email")}
                          </Label>
                          <span className="text-danger">*</span>
                          <Input
                            disabled={
                              isLoadingForgetPassword || isSuccessForgetPassword
                            }
                            autoComplete="off"
                            id="email"
                            name="email"
                            className="form-control"
                            placeholder={t("enterEmail")}
                            type="text"
                            onBlur={form.handleBlur}
                            value={form.values.email || ""}
                            invalid={
                              form.touched.email && form.errors.email
                                ? true
                                : false
                            }
                            onChange={form.handleChange}
                          />
                          {form.touched.email && form.errors.email ? (
                            <FormFeedback type="invalid">
                              {form.errors.email as string}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="mt-4">
                          <ActivityButton
                            title="sendVerificationCode"
                            inProgressTitle="sending"
                            color="success"
                            className="btn btn-success w-100"
                            type="submit"
                            inProgress={isLoadingForgetPassword}
                            disabled={
                              isLoadingForgetPassword || isSuccessForgetPassword
                            }
                          ></ActivityButton>
                        </div>
                      </Form>
                    </div>
                  </CardBody>
                </Card>

                <div className="mt-4 text-center">
                  <p className="mb-0 text-white">
                    {t("waitIRememberMyPassword")}...{" "}
                    <Link
                      to="/login"
                      className="fw-semibold text-white text-decoration-underline"
                    >
                      {" "}
                      {t("clickHere")}{" "}
                    </Link>{" "}
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </ParticlesAuth>
    </React.Fragment>
  );
};
