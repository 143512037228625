import { createApi } from "@reduxjs/toolkit/query/react";

import {
  CreateAdminRequest,
  PaginateQuery,
  UpdateAdminRequest,
  UserDto,
} from "../interfaces";
import { baseQueryWithInterceptor } from "./base.api";
import { Paginated } from "../models";
import { convertObjectToQueryParams } from "./helper.service";

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: baseQueryWithInterceptor,
  endpoints: (builder) => ({
    getPaginatedAdminUsers: builder.query<Paginated<UserDto>, PaginateQuery>({
      query: (query) => ({
        url: `/user/admin/paginate?${convertObjectToQueryParams(query)}`,
        method: "GET",
      }),
      providesTags: ["admin-users"],
    }),
    getPaginatedAppUsers: builder.query<Paginated<UserDto>, PaginateQuery>({
      query: (query) => ({
        url: `/user/app/paginate?${convertObjectToQueryParams(query)}`,
        method: "GET",
      }),
      providesTags: ["app-users"],
    }),
    getUserById: builder.query<UserDto, string>({
      query: (id) => ({
        url: `/user/${id}`,
        method: "GET",
      }),
    }),
    deleteUser: builder.mutation<void, string>({
      query: (id) => ({
        url: `/user/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["admin-users"],
    }),
    toggleBlock: builder.mutation<void, string>({
      query: (id) => ({
        url: `/user/toggle-block/${id}`,
        method: "PATCH",
      }),
      invalidatesTags: ["app-users"],
    }),
    addAdminUser: builder.mutation<void, CreateAdminRequest>({
      query: (body) => ({
        url: `/user/admin`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["admin-users"],
    }),
    updateAdminUser: builder.mutation<void, UpdateAdminRequest>({
      query: (body) => ({
        url: `/user/admin/${body.id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["admin-users"],
    }),
  }),
  tagTypes: ["admin-users", "app-users"],
});

export const {
  useLazyGetPaginatedAdminUsersQuery,
  useLazyGetUserByIdQuery,
  useDeleteUserMutation,
  useToggleBlockMutation,
  useAddAdminUserMutation,
  useUpdateAdminUserMutation,
  useLazyGetPaginatedAppUsersQuery,
} = userApi;
