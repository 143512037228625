import * as Yup from "yup";
import React from "react";
import { useFormik } from "formik";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import { Form, Row, Col, Input, FormFeedback, Label } from "reactstrap";

import { ActivityButton } from "../../components/Common";
import { ChangePasswordRequest } from "../../interfaces";
import {
  getServerError,
  useChangePasswordMutation,
  useLazyGetUserProfileQuery,
} from "../../services";
import { ErrorHandlingTypeEnum } from "../../enums";

export const ChangePassword = () => {
  //#region hooks
  const { t } = useTranslation();
  //#endregion

  //#region api
  const [
    changePassword,
    {
      isError: isErrorChangePassword,
      isLoading: isLoadingChangePassword,
      error: errorChangePassword,
      isSuccess: isSuccessChangePassword,
      data: dataChangePassword,
    },
  ] = useChangePasswordMutation();

  const [getUserProfile] = useLazyGetUserProfileQuery();
  //#endregion

  //#region form
  const form = useFormik<ChangePasswordRequest>({
    enableReinitialize: true,
    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmNewPassword: "",
    },
    validationSchema: Yup.object({
      oldPassword: Yup.string()
        .min(8, t("minLength", { displayName: "oldPassword", minLength: 8 }))
        .max(25, t("maxLength", { displayName: "oldPassword", maxLength: 25 }))
        .required(
          t("isNotEmpty", {
            displayName: "oldPassword",
          })
        ),
      newPassword: Yup.string()
        .min(8, t("minLength", { displayName: "newPassword", minLength: 8 }))
        .max(25, t("maxLength", { displayName: "newPassword", maxLength: 25 }))
        .required(
          t("isNotEmpty", {
            displayName: "newPassword",
          })
        ),
      confirmNewPassword: Yup.string()
        .min(
          8,
          t("minLength", { displayName: "confirmNewPassword", minLength: 8 })
        )
        .max(
          25,
          t("maxLength", { displayName: "confirmNewPassword", maxLength: 25 })
        )
        .oneOf([Yup.ref("newPassword")], t("passwordConfirmPasswordMustMatch"))
        .required(
          t("isNotEmpty", {
            displayName: "confirmNewPassword",
          })
        ),
    }),
    onSubmit: async (values) => {
      await changePassword(values);
    },
  });
  //#endregion

  //#region effects

  useEffect(() => {
    async function initialize() {
      await getUserProfile(null);
    }
    initialize();
  }, []);

  useEffect(() => {
    if (!isLoadingChangePassword && isErrorChangePassword) {
      const _parsed = getServerError(errorChangePassword);
      if (_parsed.type === ErrorHandlingTypeEnum.FORM) {
        form.setErrors(_parsed.error);
      }
      if (_parsed.type === ErrorHandlingTypeEnum.ALERT) {
        toast.error(_parsed.error, {
          autoClose: 3000,
        });
      }
    }
    if (!isLoadingChangePassword && isSuccessChangePassword) {
      toast.success(t("savedSuccessfully"), {
        autoClose: 3000,
      });
    }
  }, [isLoadingChangePassword]);

  //#endregion

  return (
    <React.Fragment>
      <ToastContainer />
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          form.handleSubmit();
          return false;
        }}
        action="#"
      >
        <Row>
          <Col lg={6}>
            <div className="mb-3">
              <Label className="form-label" htmlFor="oldPassword">
                {t("oldPassword")}
              </Label>
              <span className="text-danger">*</span>
              <div className="position-relative auth-pass-inputgroup mb-3">
                <Input
                  autoComplete="off"
                  id="oldPassword"
                  name="oldPassword"
                  type="password"
                  value={form.values.oldPassword || ""}
                  className="form-control"
                  placeholder={t("enterOldPassword")}
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                  invalid={
                    form.touched.oldPassword && form.errors.oldPassword
                      ? true
                      : false
                  }
                />
                {form.touched.oldPassword && form.errors.oldPassword ? (
                  <FormFeedback type="invalid">
                    {form.errors.oldPassword as string}
                  </FormFeedback>
                ) : null}
              </div>
            </div>
          </Col>
          <Col lg={6}>
            <div className="mb-3">
              <Label className="form-label" htmlFor="newPassword">
                {t("newPassword")}
              </Label>
              <span className="text-danger">*</span>
              <Input
                autoComplete="off"
                id="newPassword"
                name="newPassword"
                type="password"
                value={form.values.newPassword || ""}
                className="form-control"
                placeholder={t("enterNewPassword")}
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                invalid={
                  form.touched.newPassword && form.errors.newPassword
                    ? true
                    : false
                }
              />
              {form.touched.newPassword && form.errors.newPassword ? (
                <FormFeedback type="invalid">
                  {form.errors.newPassword as string}
                </FormFeedback>
              ) : null}
            </div>
          </Col>
          <Col lg={6}>
            <div className="mb-3">
              <Label className="form-label" htmlFor="confirmNewPassword">
                {t("confirmNewPassword")}
              </Label>
              <span className="text-danger">*</span>
              <div className="position-relative auth-pass-inputgroup mb-3">
                <Input
                  autoComplete="off"
                  id="confirmNewPassword"
                  name="confirmNewPassword"
                  type="password"
                  value={form.values.confirmNewPassword || ""}
                  className="form-control"
                  placeholder={t("enterConfirmNewPassword")}
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                  invalid={
                    form.touched.confirmNewPassword &&
                    form.errors.confirmNewPassword
                      ? true
                      : false
                  }
                />
                {form.touched.confirmNewPassword &&
                form.errors.confirmNewPassword ? (
                  <FormFeedback type="invalid">
                    {form.errors.confirmNewPassword as string}
                  </FormFeedback>
                ) : null}
              </div>
            </div>
          </Col>
          <Col lg={12}>
            <div className="hstack gap-2 justify-content-end">
              <ActivityButton
                title="changePassword"
                inProgressTitle="saving"
                color="success"
                className="btn btn-success"
                type="submit"
                inProgress={isLoadingChangePassword}
                disabled={isLoadingChangePassword}
              ></ActivityButton>
            </div>
          </Col>
        </Row>
      </Form>
    </React.Fragment>
  );
};
