import React, { useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";

import { ActivityButton } from "../../components/Common";
import { UpdateAppSettingsRequest } from "../../interfaces/app-settings";
import { Card, CardBody, CardHeader, Col, Form, Row } from "reactstrap";
import { AppSettingsTypeEnum, ErrorHandlingTypeEnum } from "../../enums";
import {
  getServerError,
  useGetAppSettingsQuery,
  useUpdateAppSettingsMutation,
} from "../../services";
import { RichTextEditorLocalized } from "../../components";
import {
  LocalizedPropertyInitialErrors,
  LocalizedPropertyInitialTouched,
  LocalizedPropertyInitialValues,
  LocalizedPropertyYupSchema,
} from "../../constants/language.constant";
import { LocalizedProperty } from "../../interfaces";

export const PrivacyPolicyPage = () => {
  //#region hooks usage
  const { t } = useTranslation();
  //#endregion

  //#region internal state
  const [initialValue, setInitialValue] = useState<LocalizedProperty>(
    LocalizedPropertyInitialValues
  );
  //#endregion

  //#region others
  document.title = `${t("documentTitle", {
    pageName: "controlAppPrivacyPolicy",
  })}`;
  //#endregion

  //#region api
  const { isLoading: isLoadingAppSettings, data: dataAppSettings } =
    useGetAppSettingsQuery(AppSettingsTypeEnum.PRIVACY_POLICY);

  const [
    updateAppSettings,
    {
      isError: isErrorUpdateAppSettings,
      isLoading: isLoadingUpdateAppSettings,
      error: errorUpdateAppSettings,
      isSuccess: isSuccessUpdateAppSettings,
    },
  ] = useUpdateAppSettingsMutation();

  //#endregion

  //#region form
  const form = useFormik<UpdateAppSettingsRequest>({
    enableReinitialize: true,
    initialValues: {
      value: initialValue,
      type: AppSettingsTypeEnum.PRIVACY_POLICY,
    },
    initialTouched: {
      value: LocalizedPropertyInitialTouched,
    },
    initialErrors: {
      value: LocalizedPropertyInitialErrors,
    },
    validationSchema: Yup.object({
      value: LocalizedPropertyYupSchema("value"),
    }),
    onSubmit: async (values) => {
      await updateAppSettings({
        ...values,
        type: AppSettingsTypeEnum.PRIVACY_POLICY,
      });
    },
  });
  //#endregion

  //#region effects
  useEffect(() => {
    if (dataAppSettings) {
      form.setValues({
        value: {
          en: dataAppSettings.value.en,
          ar: dataAppSettings.value.ar,
          id: dataAppSettings.value.id,
          ur: dataAppSettings.value.ur,
          ru: dataAppSettings.value.ru,
          zh: dataAppSettings.value.zh,
          tr: dataAppSettings.value.tr,
          de: dataAppSettings.value.de,
          es: dataAppSettings.value.es,
          fr: dataAppSettings.value.fr,
        },
        type: AppSettingsTypeEnum.PRIVACY_POLICY,
      });
      setInitialValue(dataAppSettings?.value);
    }
  }, [dataAppSettings]);

  useEffect(() => {
    if (!isLoadingUpdateAppSettings && isErrorUpdateAppSettings) {
      const _parsed = getServerError(errorUpdateAppSettings);
      if (_parsed.type === ErrorHandlingTypeEnum.FORM) {
        form.setErrors(_parsed.error);
      }
      if (_parsed.type === ErrorHandlingTypeEnum.ALERT) {
        toast.error(_parsed.error, {
          autoClose: 3000,
        });
      }
    }
    if (!isLoadingUpdateAppSettings && isSuccessUpdateAppSettings) {
      toast.success(t("savedSuccessfully"), {
        autoClose: 3000,
      });
    }
  }, [isLoadingUpdateAppSettings]);

  //#endregion

  return (
    <React.Fragment>
      <ToastContainer />
      <Row>
        <Col>
          <Card>
            <CardHeader>
              <h6 className="card-title mb-0">{t("privacyPolicy")}</h6>
            </CardHeader>
            <CardBody>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  form.handleSubmit();
                  return false;
                }}
                action="#"
              >
                <Row>
                  <Col className="col-12">
                    <RichTextEditorLocalized
                      name="value"
                      form={form}
                      initialValue={initialValue}
                      onCompletedTranslation={function (
                        translation: LocalizedProperty
                      ): void {
                        setInitialValue(translation);
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "center",
                    }}
                  >
                    <ActivityButton
                      title="save"
                      inProgressTitle="saving"
                      color="success"
                      className="btn btn-success"
                      type="submit"
                      inProgress={
                        isLoadingAppSettings || isLoadingUpdateAppSettings
                      }
                      disabled={
                        isLoadingAppSettings || isLoadingUpdateAppSettings
                      }
                    ></ActivityButton>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};
