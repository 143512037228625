import React, { useEffect } from "react";
import {
  Container,
  Row,
  Card,
  CardBody,
  Col,
  Label,
  FormFeedback,
  Form,
} from "reactstrap";
import * as Yup from "yup";
import Select from "react-select";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";

import { ErrorHandlingTypeEnum, RoleEnum } from "../../../enums";
import { ActivityButton, BreadCrumb } from "../../../components/Common";
import { CreateNotificationRequest } from "../../../interfaces";
import {
  getServerError,
  useCreateNotificationMutation,
} from "../../../services";
import {
  LocalizedPropertyInitialErrors,
  LocalizedPropertyInitialTouched,
  LocalizedPropertyInitialValues,
  LocalizedPropertyYupSchema,
} from "../../../constants/language.constant";
import { InputLocalized } from "../../../components";

export const NotifyUsersPage = () => {
  //#region hooks usage
  const { t } = useTranslation();

  //#region others
  document.title = `${t("documentTitle", { pageName: "notifyUsers" })}`;
  //#endregion

  //#region api
  const [
    createNotification,
    {
      isError: isErrorCreateNotification,
      isLoading: isLoadingCreateNotification,
      error: errorCreateNotification,
      isSuccess: isSuccessCreateNotification,
    },
  ] = useCreateNotificationMutation();

  //#endregion

  /**
   * Formik handling
   */
  const notificationForm = useFormik<CreateNotificationRequest>({
    initialErrors: {
      title: LocalizedPropertyInitialErrors,
      body: LocalizedPropertyInitialErrors,
    },
    initialTouched: {
      title: LocalizedPropertyInitialTouched,
      body: LocalizedPropertyInitialTouched,
    },
    initialValues: {
      audience: [],
      title: LocalizedPropertyInitialValues,
      body: LocalizedPropertyInitialValues,
    },
    validationSchema: Yup.object<CreateNotificationRequest>({
      audience: Yup.array()
        .min(
          1,
          t("minLengthArray", { minLengthArray: 1, displayName: "audience" })
        )
        .required(
          t("isNotEmpty", {
            displayName: "audience",
          })
        ),
      title: LocalizedPropertyYupSchema("title", 100),
      body: LocalizedPropertyYupSchema("body", 100),
    }),
    onSubmit: async (values) => {
      const _audience = values.audience.map((v: any) => v.value);
      if (!_audience || _audience.length === 0) return;
      await createNotification({ ...values, audience: _audience });
    },
  });
  //#endregion

  //#region effects

  useEffect(() => {
    if (!isLoadingCreateNotification && isErrorCreateNotification) {
      const _parsed = getServerError(errorCreateNotification);
      if (_parsed.type === ErrorHandlingTypeEnum.FORM) {
        notificationForm.setErrors(_parsed.error);
      }
      if (_parsed.type === ErrorHandlingTypeEnum.ALERT) {
        toast.error(_parsed.error, {
          autoClose: 3000,
        });
      }
    }
    if (!isLoadingCreateNotification && isSuccessCreateNotification) {
      toast.success(t("notificationSentSuccessfully"), {
        autoClose: 3000,
      });
      notificationForm.resetForm();
    }
  }, [isLoadingCreateNotification]);

  //#endregion

  return (
    <React.Fragment>
      <ToastContainer newestOnTop={true} />
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t("notifications")} pageTitle={t("")} />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      notificationForm.handleSubmit();
                      return false;
                    }}
                    action="#"
                  >
                    <Row className="justify-content-center mb-3">
                      <div className="mb-3 col-lg-6">
                        <Label htmlFor="title" className="form-label">
                          {t("audience")}
                          <span className="text-danger">*</span>
                        </Label>
                        <Select
                          tabIndex={1}
                          id="audience"
                          placeholder={t("chooseAudience")}
                          closeMenuOnSelect={false}
                          value={notificationForm.values.audience}
                          onChange={(changedValue) =>
                            notificationForm.setFieldValue(
                              "audience",
                              changedValue
                            )
                          }
                          isMulti={true}
                          options={
                            [
                              {
                                label: t("appUser"),
                                value: RoleEnum.APP_USER,
                              },
                            ] as any
                          }
                        />
                        {notificationForm.errors.audience ? (
                          <FormFeedback
                            type="invalid"
                            style={{ display: "block" }}
                          >
                            {notificationForm.errors.audience}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="clearfix"></div>
                      <div className="col-lg-6">
                        <InputLocalized
                          tabIndex={2}
                          name="title"
                          form={notificationForm}
                          type={"text"}
                          label="title"
                        />
                      </div>
                      <div className="clearfix"></div>

                      <div className="col-lg-6">
                        <InputLocalized
                          tabIndex={3}
                          name="body"
                          form={notificationForm}
                          type={"textarea"}
                          label="body"
                        />
                      </div>
                      <div className="clearfix"></div>
                      <div className="col-lg-6">
                        <div className="hstack gap-2 justify-content-end">
                          <div className="mt-4">
                            <ActivityButton
                              tabIndex={4}
                              title="send"
                              inProgressTitle="sending"
                              color="success"
                              className="btn btn-success w-100"
                              type="submit"
                              inProgress={isLoadingCreateNotification}
                              disabled={isLoadingCreateNotification}
                            ></ActivityButton>
                          </div>
                        </div>
                      </div>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
