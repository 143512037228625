import {
  BaseQueryFn,
  FetchArgs,
  createApi,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import { Mutex } from "async-mutex";

import { RootState } from "../store";
import { clearCredentials, setCredentials } from "../store/auth";
import { clearUser } from "../store/account";

const mutex = new Mutex();

const baseQuery = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_API_URL}/api`,
  prepareHeaders: (headers, { getState }) => {
    const language = localStorage.getItem("i18nextLng") || "en";
    if (language) {
      headers.set("Accept-Language", language);
    }
    const token = (getState() as RootState).auth?.token;
    if (token) headers.set("Authorization", `Bearer ${token}`);
    return headers;
  },
});

export const baseQueryWithInterceptor: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args: any, api, extraOptions) => {
  await mutex.waitForUnlock();
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      // try to get a new token
      try {
        const refreshResult = await baseQuery(
          {
            method: "POST",
            url: "/auth/refresh-token",
            body: {
              refreshToken: (api.getState() as RootState).auth.refreshToken,
            },
          },
          api,
          extraOptions
        );

        if (refreshResult.data) {
          // store the new token in the store or wherever you keep it
          api.dispatch(setCredentials(refreshResult.data));
          // retry the initial query
          result = await baseQuery(args, api, extraOptions);
        } else {
          api.dispatch(clearCredentials());
          api.dispatch(clearUser());
        }
      } finally {
        release();
      }
    } else {
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }
  if (result.error && result.error.status === 409) {
    api.dispatch(clearCredentials());
    api.dispatch(clearUser());
  }
  return result;
};
