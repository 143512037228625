import React from "react";
import { Button, Col, Row } from "reactstrap";
import { PaginationButton } from "./PaginationButton";
import { useTranslation } from "react-i18next";

type TProps = {
  itemsPerPage: number;
  totalItems: number;
  currentPage: number;
  totalPages: number;
  itemsOnCurrentPage: number;
  onChangePage: (page: number) => void;
};
export const Paginator: React.FC<TProps> = ({
  itemsPerPage,
  totalItems,
  currentPage,
  totalPages,
  onChangePage,
  itemsOnCurrentPage,
}) => {
  const { t } = useTranslation();

  const startingEntry = () => {
    return itemsPerPage * currentPage - itemsPerPage + 1;
  };

  const endingEntry = () => {
    return startingEntry() + (itemsOnCurrentPage - 1);
  };

  return (
    <Row className="g-0 text-center text-sm-start align-items-center mb-4">
      <Col sm={6}>
        <div>
          <p className="mb-sm-0 text-muted">
            {t("showing")}{" "}
            <span className="fw-semibold">{startingEntry()}</span> {t("to")}{" "}
            <span className="fw-semibold">{endingEntry()}</span> {t("of")}{" "}
            <span className="fw-semibold text-decoration-underline">
              {totalItems}
            </span>{" "}
            {t("entries")}
          </p>
        </div>
      </Col>

      <Col sm={6}>
        <ul className="pagination pagination-separated justify-content-center justify-content-sm-end mb-sm-0 ">
          <li className={`page-item ${currentPage == 1 ? "disabled" : ""}`}>
            <Button
              onClick={() => onChangePage(currentPage - 1)}
              className={`page-link btn-success ${
                currentPage == 1 ? "disabled" : ""
              }`}
            >
              {t("previous")}
            </Button>
          </li>
          {totalPages && totalPages > 0 && (
            <PaginationButton
              currentPage={currentPage}
              forPage={1}
              onClick={() => onChangePage(1)}
            />
          )}
          {totalPages && totalPages > 1 && (
            <PaginationButton
              currentPage={currentPage}
              forPage={2}
              onClick={() => onChangePage(2)}
            />
          )}
          {totalPages && totalPages > 2 && (
            <PaginationButton
              currentPage={currentPage}
              forPage={3}
              onClick={() => onChangePage(3)}
            />
          )}
          {totalPages && totalPages > 3 && (
            <PaginationButton
              currentPage={currentPage}
              forPage={4}
              onClick={() => onChangePage(4)}
            />
          )}
          {totalPages && totalPages > 4 && (
            <PaginationButton
              currentPage={currentPage}
              forPage={5}
              onClick={() => onChangePage(5)}
            />
          )}
          <li className="page-item">
            <Button
              onClick={() => onChangePage(currentPage + 1)}
              className={`page-link btn-success ${
                totalPages == currentPage ? "disabled" : ""
              }`}
            >
              {t("next")}
            </Button>
          </li>
        </ul>
      </Col>
    </Row>
  );
};
