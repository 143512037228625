import React from "react";
import { Content } from "../content";
import { ContentTypeEnum } from "../../../enums";

type TProps = {};
export const ProphetEventContentPage: React.FC<TProps> = () => (
  <Content
    title={"prophetEventContent"}
    type={ContentTypeEnum.Prophet_Event_Content}
  />
);
