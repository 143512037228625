import React from "react";
import { useTranslation } from "react-i18next";
import { Alert, Modal, ModalBody } from "reactstrap";

import { ActivityButton } from ".";

type TProps = {
  title: string;
  description: string;
  confirmationButtonTitle: string;
  confirmationButtonInProgressTitle: string;
  onCloseClick: (event: any) => void;
  onConfirmClick: (event: any) => void;
  show: boolean;
  loading: boolean;
  error?: string | null;
  icon: any;
};

const ConfirmationModal: React.FC<TProps> = ({
  title,
  description,
  confirmationButtonTitle,
  confirmationButtonInProgressTitle,
  show,
  onConfirmClick,
  onCloseClick,
  loading,
  error,
  icon,
}) => {
  const { t } = useTranslation();
  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <ModalBody className="py-3 px-5">
        <div className="mt-2 text-center">
          {icon}
          {typeof error === "string" && (
            <Alert color="danger" className="rounded-0 mb-0">
              <p className="mb-0">{error}</p>
            </Alert>
          )}
          <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
            <h4>{title}</h4>
            <p className="text-muted mx-4 mb-0">{description}</p>
          </div>
        </div>
        <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
          <button
            type="button"
            className="btn w-sm btn-light"
            data-bs-dismiss="modal"
            onClick={onCloseClick}
          >
            {t("close")}
          </button>
          <ActivityButton
            onClick={onConfirmClick}
            title={confirmationButtonTitle}
            inProgressTitle={confirmationButtonInProgressTitle}
            className="btn w-sm btn-danger"
            inProgress={loading}
            disabled={loading}
          ></ActivityButton>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ConfirmationModal;
