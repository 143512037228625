import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";

import { ErrorHandlingTypeEnum } from "../../../enums";
import { ActivityButton } from "../../../components/Common";
import {
  CreateProphetRequest,
  LocalizedProperty,
  UpdateProphetRequest,
} from "../../../interfaces";
import {
  getServerError,
  useAddProphetMutation,
  useLazyGetProphetByIdQuery,
  useUpdateProphetMutation,
} from "../../../services";
import { InputLocalized, RichTextEditorLocalized } from "../../../components";
import {
  LocalizedPropertyInitialErrors,
  LocalizedPropertyInitialTouched,
  LocalizedPropertyInitialValues,
  LocalizedPropertyYupSchema,
} from "../../../constants/language.constant";

type TProps = {
  id?: string | null;
  show: boolean;
  onCloseClick: (event: any) => void;
};
export const AddEditProphetModal: React.FC<TProps> = ({
  id,
  show,
  onCloseClick,
}) => {
  //#region hooks
  const { t } = useTranslation();
  //#endregion

  //#region api
  const [
    addProphet,
    {
      isLoading: isLoadingAddProphet,
      isError: isErrorAddProphet,
      isSuccess: isSuccessAddProphet,
      error: errorAddProphet,
    },
  ] = useAddProphetMutation();

  const [
    updateProphet,
    {
      isLoading: isLoadingUpdateProphet,
      isError: isErrorUpdateProphet,
      isSuccess: isSuccessUpdateProphet,
      error: errorUpdateProphet,
    },
  ] = useUpdateProphetMutation();

  const [
    getProphetById,
    {
      isSuccess: isSuccessProphetById,
      isFetching: isFetchingProphetById,
      isError: isErrorProphetById,
      error: errorProphetById,
      data: dataProphetById,
    },
  ] = useLazyGetProphetByIdQuery();
  //#endregion

  //#region form
  const form = useFormik<CreateProphetRequest | UpdateProphetRequest>({
    enableReinitialize: true,
    initialErrors: {
      name: LocalizedPropertyInitialErrors,
    },
    initialTouched: {
      name: LocalizedPropertyInitialTouched,
    },
    initialValues: {
      id: null,
      name: LocalizedPropertyInitialValues,
      sequence: 0,
    },
    validationSchema: Yup.object({
      id: Yup.string().nullable(),
      name: LocalizedPropertyYupSchema("name", 255),
    }),
    onSubmit: async (values) => {
      if (id) {
        await updateProphet({
          ...values,
          id: id,
          sequence: Number(values.sequence),
        });
      } else {
        await addProphet({ ...values, sequence: Number(values.sequence) });
      }
    },
  });
  //#endregion

  //#region effects
  useEffect(() => {
    if (
      (!isLoadingAddProphet && isSuccessAddProphet) ||
      (!isLoadingUpdateProphet && isSuccessUpdateProphet)
    ) {
      onClose();
    }
  }, [
    isLoadingAddProphet,
    isLoadingUpdateProphet,
    isSuccessAddProphet,
    isSuccessUpdateProphet,
  ]);

  useEffect(() => {
    if (
      id &&
      !isFetchingProphetById &&
      isSuccessProphetById &&
      dataProphetById
    ) {
      form.setValues({
        id: dataProphetById.id,
        name: dataProphetById.name,
        sequence: dataProphetById.sequence,
      });
    }
  }, [isFetchingProphetById, isSuccessAddProphet]);

  useEffect(() => {
    if (!isLoadingAddProphet && isErrorAddProphet) {
      const _parsed = getServerError(errorAddProphet);
      if (_parsed.type === ErrorHandlingTypeEnum.FORM) {
        form.setErrors(_parsed.error);
      }
      if (_parsed.type === ErrorHandlingTypeEnum.ALERT) {
        toast.error(_parsed.error, {
          autoClose: 3000,
        });
      }
    }
    if (!isLoadingAddProphet && isSuccessAddProphet) {
      toast.success(t("savedSuccessfully"), {
        autoClose: 3000,
      });
    }
  }, [isLoadingAddProphet]);

  useEffect(() => {
    if (!isLoadingUpdateProphet && isErrorUpdateProphet) {
      const _parsed = getServerError(errorUpdateProphet);
      if (_parsed.type === ErrorHandlingTypeEnum.FORM) {
        form.setErrors(_parsed.error);
      }
      if (_parsed.type === ErrorHandlingTypeEnum.ALERT) {
        toast.error(_parsed.error, {
          autoClose: 3000,
        });
      }
    }
    if (!isLoadingUpdateProphet && isSuccessUpdateProphet) {
      toast.success(t("savedSuccessfully"), {
        autoClose: 3000,
      });
    }
  }, [isLoadingUpdateProphet]);

  useEffect(() => {
    if (!isFetchingProphetById && isErrorProphetById) {
      const _parsed = getServerError(errorProphetById);
      if (_parsed.type === ErrorHandlingTypeEnum.FORM) {
        form.setErrors(_parsed.error);
      }
      if (_parsed.type === ErrorHandlingTypeEnum.ALERT) {
        toast.error(_parsed.error, {
          autoClose: 3000,
        });
      }
    }
  }, [isFetchingProphetById]);

  useEffect(() => {
    async function initialize() {
      await getProphetById(id as string);
    }

    if (id) {
      initialize();
    }
  }, [id]);

  //#endregion

  //#region handlers
  const onClose = () => {
    form.resetForm();
    onCloseClick(!show);
  };

  //#endregion

  return (
    <React.Fragment>
      <ToastContainer />
      <Modal
        id="addEdit"
        isOpen={show}
        toggle={() => {
          onClose();
        }}
        centered
        className="modal-dialog modal-xl"
      >
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            form.handleSubmit();
            return false;
          }}
          action="#"
        >
          <ModalHeader
            className="p-3"
            toggle={() => {
              onClose();
            }}
          >
            {id
              ? t("editItem", { item: "prophet" })
              : t("addItem", { item: "prophet" })}
          </ModalHeader>
          <ModalBody>
            <div className="mb-3">
              <Label htmlFor="sequence" className="form-label">
                {t("sequence")}
                <span className="text-danger">*</span>
              </Label>
              <Input
                tabIndex={1}
                type="text"
                className="form-control"
                id="sequence"
                name="sequence"
                placeholder={`${t("enterSequence")}`}
                minLength={1}
                maxLength={5}
                value={form.values.sequence || ""}
                onChange={(e) => {
                  e.preventDefault();
                  const { value } = e.target;
                  const regex =
                    /^(0*[0-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/;
                  if (regex.test(value.toString())) {
                    form.setFieldValue("sequence", value);
                  } else {
                    form.setFieldValue("sequence", "");
                  }
                }}
                onBlur={form.handleBlur}
                invalid={
                  form.touched.sequence && form.errors.sequence ? true : false
                }
              />
              {form.touched.sequence && form.errors.sequence ? (
                <FormFeedback type="invalid">
                  {form.errors.sequence as string}
                </FormFeedback>
              ) : null}
            </div>
            <InputLocalized
              tabIndex={2}
              name="name"
              form={form}
              type={"text"}
              label="name"
            />
          </ModalBody>
          <ModalFooter>
            <Link
              to={"#"}
              onClick={onClose}
              className="btn btn-link link-success fw-medium"
            >
              <i className="ri-close-line me-1 align-middle"></i> {t("close")}
            </Link>
            <ActivityButton
              tabIndex={4}
              title="save"
              inProgressTitle="saving"
              color="success"
              className="btn btn-success"
              type="submit"
              inProgress={isLoadingAddProphet || isLoadingUpdateProphet}
              disabled={isLoadingAddProphet || isLoadingUpdateProphet}
            ></ActivityButton>
          </ModalFooter>
        </Form>
      </Modal>
    </React.Fragment>
  );
};
