import { createApi } from "@reduxjs/toolkit/query/react";

import {
  BannerDto,
  CreateBannerRequest,
  UpdateBannerRequest,
  UpdateMediaRequest,
} from "../interfaces";
import { baseQueryWithInterceptor } from "./base.api";
import { PaginateQuery, Paginated } from "../models";
import { MediaDto } from "../interfaces/media";
import { convertObjectToQueryParams } from "./helper.service";

export const bannerApi = createApi({
  reducerPath: "bannerApi",
  baseQuery: baseQueryWithInterceptor,
  endpoints: (builder) => ({
    getBanners: builder.query<Paginated<BannerDto>, PaginateQuery>({
      query: (query) => ({
        url: `/banner?${convertObjectToQueryParams(query)}`,
        method: "GET",
      }),
      providesTags: ["banners"],
    }),
    getBanner: builder.query<BannerDto, string>({
      query: (id) => ({
        url: `/banner/${id}`,
        method: "GET",
      }),
    }),
    addBanner: builder.mutation<null, CreateBannerRequest>({
      query: (body) => ({
        url: `/banner`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["banners"],
    }),
    updateBanner: builder.mutation<null, UpdateBannerRequest>({
      query: (body) => ({
        url: `/banner/${body.id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["banners"],
    }),
    deleteBanner: builder.mutation<null, string>({
      query: (id) => ({
        url: `/banner/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["banners"],
    }),
    uploadBannerImage: builder.mutation<MediaDto, UpdateMediaRequest>({
      query: (body) => {
        let url = "";
        if (body.id) url = `/banner/upload/image?id=${body.id}`;
        else url = `/banner/upload/image`;
        var bodyFormData = new FormData();
        bodyFormData.append("file", body.media);
        return {
          url: url,
          method: "POST",
          body: bodyFormData,
          formData: true,
        };
      },
      invalidatesTags: ["banners"],
    }),
  }),
  tagTypes: ["banners"],
});

export const {
  useLazyGetBannersQuery,
  useLazyGetBannerQuery,
  useAddBannerMutation,
  useUpdateBannerMutation,
  useDeleteBannerMutation,
  useUploadBannerImageMutation,
} = bannerApi;
